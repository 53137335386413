import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';

import { Button } from '@agendaedu/ae-web-components';

export const NewButton = styled(Button)`
  ${({ theme: { breakpoints, font } }: ThemeProps) => css`
    @media (max-width: ${breakpoints.mobileSM}) {
      font-size: ${font.size.sm} !important;
    }
  `}
`;
