import React from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import tabifyForWizard from 'core/hoc/tabifyForWizard';
import withFormContext from 'core/hoc/withFormContext';
import FormFieldset from 'components/Form/Fieldset';
import validations from './validations';
import WalletConfiguration from 'components/Payments/Shop/FormSections/WalletConfiguration';
import Field from 'components/Form/Field';
import LabelWithTooltip from 'components/Payments/Shop/OfferForm/LabelWithTooltip';
import { NewOfferFormInformationTabProps } from 'components/Payments/Shop/OfferForm/types';
import * as S from './styles';

const tooltipText =
  'A imagem será exibida na oferta do produto no aplicativo dos responsáveis.';
const limitPerBuyTooltipText =
  'O responsável poderá selecionar no máximo essa quantidade na compra.';

const OfferFormInformationTab = ({
  formContext: {
    form: { max_quantity_per_order, size, status },
  },
}: NewOfferFormInformationTabProps) => {
  const {
    policies: { can_display_options },
  } = useSelector((state: RootStateOrAny) => state.root);

  return (
    <S.InfoWrapper>
      <FormFieldset>
        <WalletConfiguration />
        <div className="row">
          <Field
            label="Nome*"
            placeholder="ex. Confraternização de Natal"
            attributeName="title"
          />
        </div>
        <div className="row">
          <Field
            type="image"
            label={
              <LabelWithTooltip
                label="Capa da oferta*"
                tooltipText={tooltipText}
              />
            }
            attributeName="image"
          />
        </div>
        <div className="row">
          <Field
            type="textArea"
            label="Descrição*"
            placeholder=""
            attributeName="description"
          />
        </div>
        <div className="row">
          <Field
            type="number"
            size="small"
            value={max_quantity_per_order}
            label={
              <LabelWithTooltip
                label="Limite de itens por compra"
                tooltipText={limitPerBuyTooltipText}
              />
            }
            attributeName="max_quantity_per_order"
          />

          {can_display_options && (
            <Field
              type="text"
              size="small"
              label="Tamanho (separar as opções por vírgula)"
              value={size}
              attributeName="size"
              disabled={status === 'active'}
              placeholder={status === 'active' ? '' : 'P, M, G'}
            />
          )}
        </div>
        <S.Paragraph>*Campos de preenchimento obrigatório</S.Paragraph>
      </FormFieldset>
    </S.InfoWrapper>
  );
};

export default tabifyForWizard({
  title: '1. Item',
  subTitle: 'Informações gerais',
  validations,
})(withFormContext(OfferFormInformationTab));
