/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// We have to register React component here so we can access them in Rails
// views.
import '@babel/polyfill';
import 'config';

import 'scss/application.scss';

import ReactOnRails from 'react-on-rails';
// COMPONENTS
import OfferDetailsContainer from 'components/Payments/Shop/OfferDetailsContainer';
import SchoolPaymentsContainer from 'components/Payments/PaymentsContainer/SchoolPaymentsContainer';
import UnreadChatModal from 'components/Messages/OmniChannel/Modals/UnreadChatModal';

// ROUTES
import DailySummariesRoutes from 'routes/DailySummaries';
import HandoutRoutes from 'routes/Handouts';

import MessageGroupRoutes from 'routes/Messages';
import OmniChannelRoutes from 'routes/Messages/OmniChannelRoutes';
import SchoolOrganizationRoutes from 'routes/Organization';
import SummarydayClassroomsRoutes from 'routes/SummarydayClassrooms';
import HubstoreRoutes from 'routes/Hubstore';
import QuickAccessRoutes from 'routes/QuickAccess';
import IntegrationsMonitorRoutes from 'routes/IntegrationsMonitor';
import AlbumsRoutes from 'routes/Albums';
import StorageRoutes from 'routes/Storage';

// PAGES
import HomeScreen from 'pages/home';
import RegisterAccountResponsibles from 'pages/RegisterAccount/Responsibles';
import RegisterAccountTeam from 'pages/RegisterAccount/Team';
import RegisterAccountStudents from 'pages/RegisterAccount/Students';
import HubstoreDetails from 'pages/hubstore/Details';
import SubscriptionForm from 'pages/hubstore/SubscriptionForm';

// SCREENS
import DailySummariesScreen from 'screens/DailySummaries';
import EventsMainScreen from 'screens/Events/MainScreen';
import IntegrationsMainScreen from 'screens/Employees/Integrations/MainScreen';
import MessagesOtherUsersMainScreen from 'screens/Messages/OtherUsersMainScreen';
import MultimediaConsumptionScreen from 'screens/MultimediaConsumption';
import PermissionsMainScreen from 'screens/Permissions/MainScreen';
import ReportsScreen from 'screens/Reports';
import SchoolTermsMainScreen from 'screens/SchoolTerms/MainScreen';
import SchoolBillsScreen from 'screens/SchoolBills';
import SophiaIntegrationsMainScreen from 'screens/Employees/SophiaIntegrations/MainScreen';
import SponteIntegrationsMainScreen from 'screens/Employees/SponteIntegrations/MainScreen';
import StudentsSchoolMainScreen from 'screens/Students/SchoolMainScreen';
import SurveysRoutes from 'routes/Surveys';
import PanelScreen from 'screens/Panel';

import EditDynamicModulesRoutes from 'routes/EditDynamicModules';
import DynamicModuleRoutes from 'routes/DynamicApps';
import InviteScreen from 'screens/Invite/MainScreen';
import InviteModalScreen from 'screens/Invite/ModalScreen';
import HelpBalloonScreen from 'screens/Help/BalloonScreen';
import ClassroomEmptyStateScreen from 'screens/EmptyStates/Classrooms';
import DisciplinesEmptyStateScreen from 'screens/EmptyStates/Disciplines';
import ResponsiblesEmptyStateScreen from 'screens/EmptyStates/Responsibles';
import StudentsEmptyStateScreen from 'screens/EmptyStates/Students';
import SwitchUserSso from 'pages/SwitchUserSso';
import BillingPanel from 'pages/BillingPanel';
import MoodTrackersScreen from 'screens/MoodTrackers';
import InviteResponsiblesModalScreen from '../../screens/Invite/ResponsiblesModal';
import InviteStudentsModalScreen from 'screens/Invite/StudentsModal';

ReactOnRails.register({
  SchoolPaymentsContainer,
  MessagesOtherUsersMainScreen,
  MessageGroupRoutes,
  OmniChannelRoutes,
  SchoolTermsMainScreen,
  StudentsSchoolMainScreen,
  PermissionsMainScreen,
  IntegrationsMainScreen,
  SophiaIntegrationsMainScreen,
  SponteIntegrationsMainScreen,
  EventsMainScreen,
  SurveysRoutes,
  MultimediaConsumptionScreen,
  SchoolBillsScreen,
  HomeScreen,
  SummarydayClassroomsRoutes,
  ReportsScreen,
  DailySummariesScreen,
  DailySummariesRoutes,
  OfferDetailsContainer,
  SchoolOrganizationRoutes,
  EditDynamicModulesRoutes,
  DynamicModuleRoutes,
  InviteScreen,
  InviteModalScreen,
  HelpBalloonScreen,
  ClassroomEmptyStateScreen,
  DisciplinesEmptyStateScreen,
  ResponsiblesEmptyStateScreen,
  StudentsEmptyStateScreen,
  SwitchUserSso,
  BillingPanel,
  MoodTrackersScreen,
  HandoutRoutes,
  AlbumsRoutes,
  StorageRoutes,
  InviteResponsiblesModalScreen,
  InviteStudentsModalScreen,
  RegisterAccountResponsibles,
  RegisterAccountTeam,
  RegisterAccountStudents,
  HubstoreRoutes,
  HubstoreDetails,
  PanelScreen,
  QuickAccessRoutes,
  SubscriptionForm,
  IntegrationsMonitorRoutes,
  UnreadChatModal,
});
