import { FetchTicketsParams } from 'store/messages/omniChannel/types';

export const queryUrlFetchTickets = ({
  status,
  requesterName,
  requesterType,
}: FetchTicketsParams) => {
  const filterStatus = status ? `&[filter]status=${status}` : '';

  const allTicketTabs = ['waiting', 'in_attendance', 'done'];

  const requestSearchStatus = allTicketTabs
    .map((currentTab) => `&[filter]status[]=${currentTab}`)
    .join('');

  const filterRequesterName =
    requesterName && requesterType
      ? `&[filter][requester]name=${requesterName}&[filter][requester]type=${requesterType}${requestSearchStatus}`
      : '';

  const queryUrlTickets = `${filterStatus}${filterRequesterName}`;

  return queryUrlTickets;
};
