import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './styles';

import { Alert, Text } from '@agendaedu/ae-web-components';
import { Props } from './types';
import validateTextAreaPresence from 'core/lib/FormValidator/validators/textAreaPresence';
import FormContainer from 'components/Form/FormContainer';

export const ObservationCard = ({
  observation,
  onChange,
  hasInitialValue,
}: Props): React.ReactElement => {
  const { t } = useTranslation(['diary_sections']);

  return (
    <S.ObservationWrapper data-testid="observation-card">
      <Text variant="title-bold-16" color="neutral.black" marginBottom="0">
        {t('sections.card.observation.title')}
      </Text>

      {hasInitialValue && (
        <Alert variant="informative">
          <Text
            variant="subtitle-medium-14"
            color="context.info.default"
            marginBottom="0"
          >
            {t('sections.card.observation.info')}
          </Text>
        </Alert>
      )}

      <FormContainer>
        <S.ObservationField
          quillProps={{
            onChange: (value: string) =>
              observation !== value &&
              onChange(validateTextAreaPresence(value) ? value : ''),
            value: observation,
          }}
          type="textArea"
          useImage
        />
      </FormContainer>
    </S.ObservationWrapper>
  );
};
