import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GroupHeaderProps } from './types';
import * as S from './styles';
import withAppContext from 'core/hoc/withAppContext';

const GroupHeader = ({
  title,
  appContext: { dataArea, policies },
}: GroupHeaderProps) => {
  const { t } = useTranslation(['messages']);

  return (
    <S.GroupHeaderContainer>
      <S.GroupHeaderTitle>{title}</S.GroupHeaderTitle>
      {policies?.can_create_group && (
        <Link
          to={`/${dataArea}/messages/groups/new`}
          data-testid="button-wrapper"
        >
          <S.GroupHeaderButton icon="user-group">
            {t('groups.create_group_button_text')}
          </S.GroupHeaderButton>
        </Link>
      )}
    </S.GroupHeaderContainer>
  );
};

export default withAppContext(GroupHeader);
