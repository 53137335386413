import createModuleReducer from 'store/storeConfig/createModuleReducer';
import { StorageState } from './types';
import actions from './actions';

export const INITIAL_STATE: StorageState = {
  storageForm: {
    isSubmitting: false,
    recipients: {
      formMeta: {
        canEditClassrooms: true,
        hideRadio: false,
        hideSendTo: false,
        classrooms: [],
        select_options: {
          classroom_id: [],
        },
      },
    },
  },
};

export const fetchRecipientsClassroomsSuccess = (
  state: StorageState,
  action
) => {
  const { data } = action;
  const classrooms = data.map((classroom) => ({
    ...classroom,
    options: classroom.options.map((option) => ({
      ...option,
      label: option.attributes.name,
      value: option.id,
    })),
  }));

  const newState = structuredClone(state);

  newState.storageForm.recipients.formMeta.classrooms = classrooms;
  newState.storageForm.recipients.formMeta.select_options.classroom_id =
    classrooms;

  return newState;
};

export const saveFolderRequest = (state: StorageState) => {
  const newState = structuredClone(state);

  newState.storageForm.isSubmitting = true;

  return newState;
};

export const saveFolderSuccess = (state: StorageState) => {
  const newState = structuredClone(state);

  newState.storageForm.isSubmitting = false;

  return newState;
};

export const saveFolderError = (state: StorageState) => {
  const newState = structuredClone(state);

  newState.storageForm.isSubmitting = false;

  return newState;
};

const HANDLERS = {
  [actions.FETCH_RECIPIENTS_CLASSROOMS_SUCCESS]:
    fetchRecipientsClassroomsSuccess,
  [actions.SAVE_FOLDER_REQUEST]: saveFolderRequest,
  [actions.SAVE_FOLDER_SUCCESS]: saveFolderSuccess,
  [actions.SAVE_FOLDER_ERROR]: saveFolderError,
};

const storage = createModuleReducer(INITIAL_STATE, HANDLERS);

export default storage;
