import React, { useCallback, useContext, useMemo } from 'react';

import {
  Box,
  Icon,
  Select,
  Text,
  TextField,
} from '@agendaedu/ae-web-components';
import { useTranslation } from 'react-i18next';
import { DiarySectionForm } from 'store/dailySummaries/types';
import { DiarySectionContext } from 'core/contexts/DiarySections';
import { Props } from './types';
import { EnhancedErrors } from 'core/contexts/DiarySections/types';

const SectionWrapper = ({ question, index }: Props) => {
  const { t } = useTranslation(['diary_sections', 'common']);
  const {
    form: { values, setFieldValue, errors, setFieldTouched, touched },
  } = useContext(DiarySectionContext);
  const questionsAttributes = structuredClone(values.questionsAttributes);
  const currentQuestion = questionsAttributes[index];
  const currentQuestionErrors = (errors as EnhancedErrors)
    ?.questionsAttributes?.[index];

  const updateQuestionAttributes = useCallback(
    () => setFieldValue('questionsAttributes', questionsAttributes),
    [questionsAttributes, setFieldValue]
  );

  const tBase = useCallback(
    (key: string, params?: Record<string, unknown>) =>
      t(`sections.form.info_tab.${key}`, params),
    [t]
  );

  const options = [
    {
      label: tBase('open_answer'),
      value: 'text',
    },
    {
      label: tBase('unique_answer'),
      value: 'select',
    },
  ];

  const selectIcon = {
    text: 'align-left',
    select: 'check',
  }[question.kind];

  const selectActions = {
    changeSelect: (value: 'text' | 'select') => {
      const defaultOptions = [
        {
          title: '',
        },
        {
          title: '',
        },
      ];

      currentQuestion.kind = value;

      if (
        value === 'select' &&
        !currentQuestion?.answerOptionsAttributes?.length
      ) {
        currentQuestion.answerOptionsAttributes = defaultOptions;
      } else {
        delete currentQuestion.answerOptionsAttributes;
      }

      updateQuestionAttributes();
    },
    setTitle: (title: string) => {
      currentQuestion.title = title;

      updateQuestionAttributes();
    },
  };

  const selectOptionActions = useMemo(
    () => ({
      add: () => {
        currentQuestion.answerOptionsAttributes.push({
          title: '',
        });

        updateQuestionAttributes();
      },
      setTitle: (title: string, indexOption: number) => {
        currentQuestion.answerOptionsAttributes[indexOption].title = title;

        updateQuestionAttributes();
      },
      delete: (indexOption: number) => {
        currentQuestion.answerOptionsAttributes.splice(indexOption, 1);

        updateQuestionAttributes();
      },
    }),
    [currentQuestion.answerOptionsAttributes, updateQuestionAttributes]
  );

  const renderSelectOptions = useMemo(() => {
    const getOptionError = (optionIndex: number) =>
      touched?.questionsAttributes &&
      question.answerOptionsAttributes.some((input) => !!input.title) &&
      currentQuestionErrors?.answerOptionsAttributes?.[optionIndex]?.title;

    return question?.answerOptionsAttributes?.map(
      (option, optionIndex, arraySource) => (
        <Box key={`option-${index}-${optionIndex}`} display="flex" mt="md">
          <TextField
            data-testid="option-question-input"
            fullWidth
            onChange={(e) =>
              selectOptionActions.setTitle(e.target.value, optionIndex)
            }
            value={option.title}
            error={!!getOptionError(optionIndex)}
            errorMessage={getOptionError(optionIndex)}
            onBlur={() => setFieldTouched('questionsAttributes')}
          />

          {arraySource.length > 2 && (
            <Box
              cursor="pointer"
              mt="xs"
              ml="xs"
              onClick={() => selectOptionActions.delete(optionIndex)}
            >
              <Icon name="multiply" size="sm" />
            </Box>
          )}
        </Box>
      )
    );
  }, [
    currentQuestionErrors?.answerOptionsAttributes,
    index,
    question?.answerOptionsAttributes,
    selectOptionActions,
    setFieldTouched,
    touched?.questionsAttributes,
  ]);

  return (
    <Box>
      <Box>
        <Select
          fullWidth
          label={tBase('select_response_field_label')}
          value={question.kind}
          icon={selectIcon}
          options={options}
          disabled={!!question.id}
          onChange={(event) => {
            selectActions.changeSelect(
              event.value as DiarySectionForm['questionAttributes']['kind']
            );
          }}
        />

        <TextField
          data-testid="title-question-input"
          id={`title-question-input-${index}`}
          fullWidth
          mt="md"
          label={tBase('title_question_label')}
          onChange={(e) => selectActions.setTitle(e.target.value)}
          value={question.title}
          onBlur={() => setFieldTouched('questionsAttributes')}
          error={!!currentQuestionErrors?.title}
          errorMessage={currentQuestionErrors?.title}
        />
      </Box>

      {question.kind === 'select' && (
        <Box mt="md">
          <Text variant="label-regular-14" color="neutral.gray1">
            {tBase('options_label')}
          </Text>

          <Box>{renderSelectOptions}</Box>

          <Box cursor="pointer" onClick={selectOptionActions.add}>
            <Text
              mt="sm"
              variant="label-regular-14"
              color="brand.primary.default"
            >
              {tBase('add_option')}
            </Text>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SectionWrapper;
