import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './styles';

import { Button, Text } from '@agendaedu/ae-web-components';
import { Props } from './types';

export const DiaperCard = ({ diaper, onChange }: Props): React.ReactElement => {
  const { t } = useTranslation(['diary_sections']);
  const [quantity, setQuantity] = useState(diaper);

  useEffect(() => {
    onChange(quantity);
  }, [quantity]);

  useEffect(() => {
    setQuantity(diaper);
  }, [diaper]);

  return (
    <S.DiaperWrapper data-testid="diaper-card">
      <Text variant="title-bold-16" color="neutral.black" marginBottom="0">
        {t('sections.card.diaper.title')}
      </Text>

      <Text variant="label-regular-16" marginBottom="0">
        {t('sections.card.diaper.label')}
      </Text>

      <S.ButtonWrapper>
        <Button
          testId="minus-button"
          isOnlyIcon
          icon="minus"
          variant="secondary"
          disabled={quantity <= 0}
          onClick={() => setQuantity(quantity - 1)}
        />

        <Text
          variant="body-medium-16"
          color="neutral.gray2"
          marginBottom="0"
          width="32px"
          textAlign="center"
        >
          {quantity}
        </Text>

        <Button
          testId="plus-button"
          isOnlyIcon
          icon="plus"
          variant="secondary"
          onClick={() => setQuantity(quantity + 1)}
        />
      </S.ButtonWrapper>
    </S.DiaperWrapper>
  );
};
