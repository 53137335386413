import styled, { css } from 'styled-components';

import Button from 'components/Button';

import { ThemeProps } from 'config/themes/types';

export const GroupChatWrapper = styled.div`
  ${({ theme: { colors } }: ThemeProps) => css`
    height: 100%;
    width: 100%;

    background: ${colors.neutral.white};

    display: flex;
    flex-direction: column;
  `};
`;

export const ChatHeaderWrapper = styled.div`
  ${({ theme: { colors } }: ThemeProps) => css`
    width: 100%;
    padding: 16px 20px;
    background: ${colors.neutral.white};

    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.14);

    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  `};
`;

export const ChatHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
`;

export const BackButton = styled(Button)`
  ${({ theme: { border, colors, font } }: ThemeProps) => css`
    &.Button {
      padding: 0px;
      width: 40px;
      height: 40px;
      background: none;
      border: ${border.width.none};

      display: flex;
      justify-content: center;
      align-items: center;

      .AgendaIcon {
        font-size: ${font.size.xl};
        color: ${colors.neutral.black};
      }

      &:active:hover {
        background: ${colors.neutral.gray4};
      }

      @media screen and (min-width: 992px) {
        display: none;
      }
    }
  `}
`;

export const ChatHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const LoaderWrapper = styled.div`
  margin: 20px 0px;
`;

export const ChatHeaderTitle = styled.p`
  ${({ theme: { colors, typography } }: ThemeProps) => css`
    margin: 0px;
    ${typography.subtitle.Medium12};
    color: ${colors.neutral.gray3};
  `}
`;

export const ChatHeaderSubtitle = styled.h5`
  ${({ theme: { colors, typography } }: ThemeProps) => css`
    margin: 0px;
    ${typography.title.Bold14};
    color: ${colors.neutral.black};
  `}
`;

export const DropdownButtonWrapper = styled.div`
  ${({ theme: { colors, font } }: ThemeProps) => css`
    .DropdownButton {
      height: 40px;
      width: 40px;

      .btn {
        height: inherit;

        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          background: ${colors.neutral.gray4};
        }
      }

      .btn-white {
        border-color: ${colors.neutral.white};
      }

      .dropdown-menu {
        padding: 8px;
        min-height: 40px;
        min-width: 146px;

        border: 0px;
        border-radius: 6px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.14);

        gap: 10px;

        li {
          .dropdown-item {
            margin: 0px;
            padding: 14px 16px;

            font-size: ${font.size.md};
            line-height: 20px;

            display: flex;
            justify-content: flex-start;
            align-items: center;

            &:hover {
              background-color: ${colors.brand.primary.op10};
            }
          }
        }
      }
    }
  `}
`;

export const ChatContentWrapper = styled.div`
  ${({ theme: { border, colors, gray4 } }: ThemeProps) => css`
    height: 100%;
    padding: 20px 14px 0px 20px;
    margin: 0px;

    display: flex;
    flex-direction: column-reverse;
    overflow: auto;

    &::-webkit-scrollbar {
      background-color: transparent;
      width: 6px;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    &::-webkit-scrollbar-track {
      background-color: ${colors.neutral.gray5};
    }

    &::-webkit-scrollbar-thumb {
      border-radius: ${border.radius.sm};
      border: ${border.width.sm} ${border.style.solid} ${gray4};
    }

    .infinite-scroll-component {
      display: flex;
      flex-direction: column-reverse;
    }
  `}
`;

export const EmptyWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  > div.EmptyState {
    width: 256px;
  }
`;

export const ChatFooterWrapper = styled.div`
  ${({ theme: { border, colors, typography } }: ThemeProps) => css`
    .MessagesChatInput {
      border: ${border.width.none};
      border-radius: ${border.radius.lg} ${border.radius.lg}
        ${border.radius.none} ${border.radius.none};
      filter: drop-shadow(0px -2px 10px rgba(115, 61, 190, 0.078));
      min-height: 56px;

      .input-content {
        margin: 8px 20px;
        align-items: center;
        gap: 16px;

        .emoji-picker {
          padding: 0px;
          margin: 0px;
        }

        .input-area {
          max-height: 56px;
          padding: 8px 16px;
          margin: 0px;

          border-radius: 12px;

          textarea {
            ${typography.body.Regular16};

            &::placeholder {
              ${typography.body.Regular16};

              color: ${colors.neutral.gray2};
            }
          }
        }

        .input-actions {
          padding: 0px;
          margin: 0px;

          button {
            margin: 0px;
          }
        }
      }
    }
  `}
`;
