import { MessageActionProps } from './types';

const actions: MessageActionProps = {
  ERROR: 'ERROR/messages',
  CLEAR_CURRENT_GROUP: 'CLEAR_CURRENT_GROUP/messages',
  CLOSE_EDIT_MESSAGE: 'CLOSE_EDIT_MESSAGE/messages',
  CREATE_NEW_GROUP_REQUEST: 'CREATE_NEW_GROUP_REQUEST/messages',
  CREATE_NEW_GROUP_SUCCESS: 'CREATE_NEW_GROUP_SUCCESS/messages',
  CREATE_NEW_MESSAGE_REQUEST: 'CREATE_NEW_MESSAGE_REQUEST/messages',
  CREATE_NEW_MESSAGE_SUCCESS: 'CREATE_NEW_MESSAGE_SUCCESS/messages',
  FETCH_GROUPS_REQUEST: 'FETCH_GROUPS_REQUEST/messages',
  FETCH_GROUPS_SUCCESS: 'FETCH_GROUPS_SUCCESS/messages',
  FETCH_MESSAGES_REQUEST: 'FETCH_MESSAGES_REQUEST/messages',
  FETCH_MESSAGES_SUCCESS: 'FETCH_MESSAGES_SUCCESS/messages',
  FETCH_LAST_MESSAGE_REQUEST: 'FETCH_LAST_MESSAGE_REQUEST/messages',
  FETCH_LAST_MESSAGE_SUCCESS: 'FETCH_LAST_MESSAGE_SUCCESS/messages',
  FETCH_REAL_TIME_MESSAGE_REQUEST: 'FETCH_REAL_TIME_MESSAGE_REQUEST/messages',
  FETCH_REAL_TIME_MESSAGE_SUCCESS: 'FETCH_REAL_TIME_MESSAGE_SUCCESS/messages',
  FETCH_SCHOOL_USERS_REQUEST: 'FETCH_SCHOOL_USERS_REQUEST/messages',
  FETCH_SCHOOL_USERS_SUCCESS: 'FETCH_SCHOOL_USERS_SUCCESS/messages',
  SET_ARCHIVE_GROUP_REQUEST: 'SET_ARCHIVE_GROUP_REQUEST/messages',
  SET_ARCHIVE_GROUP_SUCCESS: 'SET_ARCHIVE_GROUP_SUCCESS/messages',
  SET_CURRENT_GROUP: 'SET_CURRENT_GROUP/messages',
  SET_EDIT_MESSAGE: 'SET_EDIT_MESSAGE/messages',
  SET_CURRENT_GROUP_REQUEST: 'SET_CURRENT_GROUP_REQUEST/messages',
  SET_CURRENT_GROUP_SUCCESS: 'SET_CURRENT_GROUP_SUCCESS/messages',
  SET_READ_CURRENT_MESSAGES_REQUEST:
    'SET_READ_CURRENT_MESSAGES_REQUEST/messages',
  SET_READ_CURRENT_MESSAGES_SUCCESS:
    'SET_READ_CURRENT_MESSAGES_SUCCESS/messages',
  SET_MUTE_AND_TURN_ON_NOTIFICATION_REQUEST:
    'SET_MUTE_AND_TURN_ON_NOTIFICATION_REQUEST/messages',
  SET_MUTE_AND_TURN_ON_NOTIFICATION_SUCCESS:
    'SET_MUTE_AND_TURN_ON_NOTIFICATION_SUCCESS/messages',
  TOGGLE_DETAIL_MODAL: 'TOGGLE_DETAIL_MODAL/messages',
  TOGGLE_MODAL: 'TOGGLE_MODAL/messages',
  TOGGLE_NOTIFICATION_MODAL: 'TOGGLE_NOTIFICATION_MODAL/messages',
  UPDATE_MESSAGE_REQUEST: 'UPDATE_MESSAGE_REQUEST/messages',
  UPDATE_MESSAGE_SUCCESS: 'UPDATE_MESSAGE_SUCCESS/messages',

  clearCurrentGroup: () => ({
    type: actions.CLEAR_CURRENT_GROUP,
  }),

  closeEditMessage: () => ({
    type: actions.CLOSE_EDIT_MESSAGE,
  }),

  createNewGroupRequest: (params) => ({
    type: actions.CREATE_NEW_GROUP_REQUEST,
    params,
  }),

  createNewMessageRequest: (params) => ({
    type: actions.CREATE_NEW_MESSAGE_REQUEST,
    params,
  }),

  fetchSchoolUsersRequest: () => ({
    type: actions.FETCH_SCHOOL_USERS_REQUEST,
  }),

  fetchGroupsRequest: (userId) => ({
    type: actions.FETCH_GROUPS_REQUEST,
    userId,
  }),

  fetchMessagesRequest: (params) => ({
    type: actions.FETCH_MESSAGES_REQUEST,
    params,
  }),

  fetchLastMessageRequest: (params) => ({
    type: actions.FETCH_LAST_MESSAGE_REQUEST,
    params,
  }),

  fetchRealTimeMessageRequest: (params) => ({
    type: actions.FETCH_REAL_TIME_MESSAGE_REQUEST,
    params,
  }),

  setArchiveGroupRequest: (currentGroup) => ({
    type: actions.SET_ARCHIVE_GROUP_REQUEST,
    currentGroup,
  }),

  setCurrentGroup: (groupId) => ({
    type: actions.SET_CURRENT_GROUP,
    groupId,
  }),

  setCurrentGroupRequest: (group) => ({
    type: actions.SET_CURRENT_GROUP_REQUEST,
    group,
  }),

  setEditMessage: (message) => ({
    type: actions.SET_EDIT_MESSAGE,
    message,
  }),

  setReadCurrentMessagesRequest: (currentGroup) => ({
    type: actions.SET_READ_CURRENT_MESSAGES_REQUEST,
    currentGroup,
  }),

  setMuteAndTurnOnNotificationRequest: (currentGroup) => ({
    type: actions.SET_MUTE_AND_TURN_ON_NOTIFICATION_REQUEST,
    currentGroup,
  }),

  toggleModal: () => ({ type: actions.TOGGLE_MODAL }),

  toggleDetailModal: () => ({ type: actions.TOGGLE_DETAIL_MODAL }),

  toggleNotificationModal: () => ({ type: actions.TOGGLE_NOTIFICATION_MODAL }),

  updateMessageRequest: (updateMessage) => ({
    type: actions.UPDATE_MESSAGE_REQUEST,
    updateMessage,
  }),
};

export default actions;
