import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { NEW_OFFER_MAX_INSTALLMENTS } from 'core/constants';
import { INSTALLMENTS_SCHOOLPRODUCT } from 'core/constants/index';
import { extractStudents, makeClassroomIds } from 'core/utils/edupay/functions';
import shopActions from 'store/edupay/shop/actions';
import { ShopStoreProps } from 'store/edupay/shop/types';
import normalizeParams from '../NewOffer/normalizeParams';

import AlertModal from 'components/AlertModal';
import { FormMeta } from 'components/Payments/Shop/OfferForm/types';
import Loader from 'components/Loader';
import Toast from 'components/Toast';
import OfferForm from 'components/Payments/Shop/OfferForm';

const TEXT_CONFIRMATION_MODAL =
  'A oferta será atualizada no aplicativo com as novas condições de venda. Os pedidos gerados antes da edição não serão impactados.';

const EditOffer = ({
  match: {
    params: { id },
  },
  location,
}: any) => {
  const dispatch = useDispatch();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [latestOffer, setLatestOffer] = useState({});

  const {
    setClassrooms,
    editOfferRequest,
    fetchClassroomsSelectOptions,
    fetchClassroomsStudents,
    fetchOfferEdit,
  } = shopActions;

  const { billetTax, creditCardTax, pixTax } = useSelector(
    (state: any) => state.root
  );
  const {
    classrooms,
    current,
    isLoading,
    isLoadingClassrooms,
    sending,
    classroomsSelectOptions,
    classroomsStudents,
    meta,
  } = useSelector((state: ShopStoreProps) => state.shop);

  useEffect(() => {
    getOfferData();
    getClassroomsStudents();
    getClassrooms();
    getClassroomsSelectOptions();
  }, []);

  const getOfferData = useCallback(() => {
    dispatch(fetchOfferEdit(id));
  }, [dispatch, fetchOfferEdit, id]);

  const getClassrooms = useCallback(() => {
    dispatch(setClassrooms());
  }, [dispatch, setClassrooms]);

  const getClassroomsStudents = useCallback(() => {
    dispatch(fetchClassroomsStudents(id));
  }, [dispatch, fetchClassroomsStudents, id]);

  const getClassroomsSelectOptions = useCallback(() => {
    dispatch(fetchClassroomsSelectOptions());
  }, [dispatch, fetchClassroomsSelectOptions]);

  const getForm = () => ({
    status: current?.data.attributes.status,
    sent_kind: meta.sent_kind,
    classroom_with_student_profile_ids: makeClassroomIds(classroomsStudents),
    recipient_wallet_id: parseInt(
      current?.data.attributes.product.data.relationships.recipient_wallet.data
        .id
    ),
    title: current?.data.attributes.product.data.attributes.title,
    image:
      current?.data.attributes.product.data.attributes.image.data.attributes,
    description: current?.data.attributes.product.data.attributes.description,
    max_quantity_per_order: current?.data.attributes.max_quantity_per_order,
    size: current?.data.attributes.product?.data?.attributes?.options[0]?.values.join(
      ', '
    ),

    original_price: current?.data.attributes.original_price_cents / 100,
    sell_period: {
      startDate: moment(
        current?.data.attributes.starts_at,
        'DD/MM/YYYY'
      ).format('YYYY-MM-DD'),
      endDate: moment(current?.data.attributes.expires_at, 'DD/MM/YYYY').format(
        'YYYY-MM-DD'
      ),
    },
    allowed_payment_method: current?.data.attributes.allowed_payment_methods,
    enabled_notification_types:
      current?.data.attributes.enabled_notification_types,
    max_installments: current?.data.attributes.max_installments,
    days_to_expire_payment: current?.data.attributes.days_to_expire_payment,
    absorb_tax: current?.data.attributes.settings?.absorb_tax,

    classroom_ids: current?.data.relationships.classrooms.data.map(
      (classroom: { id: string; type: string }) => classroom.id
    ),
  });

  const getFormMeta = (): FormMeta => ({
    status: current?.data.attributes.status,
    billetTax,
    creditCardTax,
    pixTax,
    meta_max_installments: NEW_OFFER_MAX_INSTALLMENTS,
    classrooms: classrooms,
    select_options: {
      ...classroomsSelectOptions,
      max_installments: INSTALLMENTS_SCHOOLPRODUCT,
    },
    student_profiles: extractStudents(classroomsStudents),
  });

  const handleOnSubmit = (formContext) => {
    const offer = normalizeParams({ form: formContext.form, action: 'edit' });
    setLatestOffer(offer);
    setIsConfirmModalOpen(true);
  };

  const handleSave = () => {
    setIsConfirmModalOpen(false);
    dispatch(editOfferRequest(latestOffer, id));
  };

  const chooseBackToUrl = () =>
    location?.state?.referrer || `/schools/marketplaces/offers/${id}`;

  if (
    isLoading ||
    !classroomsSelectOptions ||
    !classroomsStudents ||
    !meta ||
    !current ||
    isLoadingClassrooms ||
    !classrooms
  )
    return <Loader />;

  return (
    <div>
      <Loader isLoading={isLoading}>
        <OfferForm
          action="edit"
          form={getForm()}
          formMeta={getFormMeta()}
          isSubmitting={sending}
          onSubmit={handleOnSubmit}
          backTo={chooseBackToUrl()}
          titlePage="Editar oferta do Shop"
          titleModal="Descartar preenchimento"
          contentModal="O cadastro da oferta será cancelado e as informações preenchidas serão descartadas. Tem certeza que deseja descartar?"
        />
        <Toast />
        <AlertModal
          type="success"
          title="Editar oferta no shop"
          isOpen={isConfirmModalOpen}
          showBackButton
          confirmButtonText="Atualizar oferta"
          cancelButtonText="Cancelar"
          onConfirm={handleSave}
          closeAlert={() => setIsConfirmModalOpen(false)}
        >
          {TEXT_CONFIRMATION_MODAL}
        </AlertModal>
      </Loader>
    </div>
  );
};

export default EditOffer;
