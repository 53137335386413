import React from 'react';
import ContentLoader from 'react-content-loader';

import { SkeletonProps } from './types';

const GroupCardSkeleton = ({ amount }: SkeletonProps) => {
  return (
    <>
      {Array.from(Array(amount).keys()).map((n) => (
        <ContentLoader
          key={`skeleton-item-${n}`}
          speed={2}
          width={366}
          height={98}
          viewBox="0 0 366 98"
          ariaLabel="Carregando..."
          data-testid="content-loader"
        >
          <rect x="16" y="16" rx="4" ry="4" width="130" height="20" />
          <rect x="300" y="16" rx="4" ry="4" width="50" height="20" />
          <rect x="16" y="48" rx="4" ry="4" width="334" height="48" />
        </ContentLoader>
      ))}
    </>
  );
};

export default GroupCardSkeleton;
