import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';

import Field from 'components/Form/Field';
import GroupSelector from 'components/Form/GroupSelector';
import AgendaIcon from 'components/AgendaIcon';

import withFormContext from 'core/hoc/withFormContext';

import './style.scss';

class SchoolTermProgressionForm extends Component {
  static propTypes = {
    formContext: PropTypes.shape({
      updateAttribute: PropTypes.func,
      changeMeta: PropTypes.func,
      formMeta: PropTypes.object,
      form: PropTypes.object,
      addErrorOnAttribute: PropTypes.func,
      removeErrorOnAttribute: PropTypes.func,
    }),
  };

  state = {
    isSearching: false,
  };

  @autobind
  groupComponent(optionGroup, onOptionGroupSelect) {
    return (
      <span
        className="opt-group"
        onClick={() => onOptionGroupSelect(optionGroup)}
      >
        <strong>{optionGroup.label}</strong>
      </span>
    );
  }

  @autobind
  optionComponent(option) {
    return <span>{option.attributes.name}</span>;
  }

  @autobind
  updateSelectorColumns(selectedValues) {
    const { updateAttribute, changeMeta } = this.props.formContext;
    const nextStepDisabled = selectedValues.length ? false : true;

    changeMeta('nextStepDisabled', nextStepDisabled);
    updateAttribute('classroom_ids', selectedValues);
    changeMeta('changedClassrooms', true);
  }

  @autobind
  searchOptions({ options, searchTerm }) {
    let filteredOptions = [];

    options.forEach((classroomGroup) => {
      const matches = classroomGroup.options.filter((opt) =>
        opt.attributes.name.toLowerCase().includes(searchTerm.toLowerCase())
      );

      if (matches.length) {
        filteredOptions.push({
          label: classroomGroup.label,
          options: matches,
        });
      }
    });

    return filteredOptions;
  }

  @autobind
  emptyStateComponent() {
    return (
      <div className="empty">
        <AgendaIcon name="user-group" size="large" />
        <div className="empty-message">Selecione uma turma no campo acima</div>
      </div>
    );
  }

  @autobind
  onNameChange({ name }) {
    const { addErrorOnAttribute, removeErrorOnAttribute, updateAttribute } =
      this.props.formContext;
    const regexValidation = /[0-9.]+/.exec(name);

    if (regexValidation) {
      const input = regexValidation[0];
      if (input.length > 25) {
        addErrorOnAttribute('name', 'Limite máximo de 25 caracteres');
      } else {
        updateAttribute('name', input);
        removeErrorOnAttribute('name');
      }
    } else {
      updateAttribute('name', '');
    }
  }

  render() {
    const {
      formContext: {
        formMeta: { classrooms },
        form: { classroom_ids },
      },
      actions,
    } = this.props;

    const year = new Date().getFullYear();

    return (
      <div className="ProgressionForm">
        <Field
          attributeName="name"
          label="Nome do período"
          type="text"
          placeholder={`Ex. ${year}, ${year}.1 ou ${year}.2`} // 2019, 2019.1 ou 2019.2
          onChange={this.onNameChange}
        />
        <GroupSelector
          options={classrooms}
          selectedValues={classroom_ids}
          onChange={this.updateSelectorColumns}
          optionComponent={this.optionComponent}
          groupComponent={this.groupComponent}
          searchPlaceholder="Buscar"
          optionAccessor={(option) => option.id}
          groupAccessor={(group) => group.label}
          counterLabel={(length) => (length === 1 ? 'turma' : 'turmas')}
          search={this.searchOptions}
          lockSelected={true}
          emptyState={this.emptyStateComponent()}
        />
      </div>
    );
  }
}

export default withFormContext(SchoolTermProgressionForm);
