import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  fetchApi,
  postApi,
  handleError,
  deleteApi,
  patchApi,
} from 'core/utils/api';
import {
  getCurrentGroupWithMembers,
  getGroupListWithMembers,
} from 'core/helper/message';
import { reverseArray } from 'core/utils/reverseArray';
import {
  buildToast,
  toastCss,
  ToastTypes,
} from 'store/middlewares/toast/actions';

import actions from './actions';

import {
  CreateNewGroupSagaProps,
  CreateNewGroupMessageSagaProps,
  fetchGroupsSagaProps,
  fetchLastMessageSagaProps,
  fetchMessagesSagaProps,
  setArchiveGroupRequestSagaProps,
  setCurrentGroupRequestSagaProps,
  setMuteAndTurnOnNotificationRequestSagaProps,
  UpdateMessageSagaProps,
} from './types';

export function* createNewGroupRequestSaga({
  params,
}: CreateNewGroupSagaProps) {
  try {
    const { name, description, member_ids } = params;

    const { dataArea } = yield select((state) => state.root);

    const { data, included } = yield call(
      postApi,
      `/${dataArea}/messages/groups`,
      {
        name,
        description,
        member_ids,
      }
    );

    const newGroupCreated = getCurrentGroupWithMembers(data, included);

    yield put(push(`/${dataArea}/messages/groups`));

    yield put({
      type: actions.CREATE_NEW_GROUP_SUCCESS,
      newGroupCreated,
      toast: buildToast(
        `O grupo "${name}" foi criado com sucesso.`,
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        handleError(error),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* createNewMessageRequestSaga({
  params,
}: CreateNewGroupMessageSagaProps) {
  try {
    const { groupId, content } = params;

    const { dataArea } = yield select((state) => state.root);

    const { data: newGroupMessageCreated } = yield call(
      postApi,
      `/${dataArea}/messages/groups/${groupId}/messages`,
      { content }
    );

    yield put({
      type: actions.CREATE_NEW_MESSAGE_SUCCESS,
      newGroupMessageCreated,
      groupId,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        handleError(error),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchSchoolUsersRequestSaga() {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data: schoolUsers } = yield call(
      fetchApi,
      `/${dataArea}/messages/groups/search_new_members.json?without_master=true`
    );

    yield put({
      type: actions.FETCH_SCHOOL_USERS_SUCCESS,
      schoolUsers,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        handleError(error),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchGroupsRequestSaga({ userId }: fetchGroupsSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data, included } = yield call(
      fetchApi,
      `/${dataArea}/messages/groups?school_user_id=${userId}`
    );

    const groups =
      data && reverseArray(getGroupListWithMembers(data, included));

    yield put({
      type: actions.FETCH_GROUPS_SUCCESS,
      groups,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        handleError(error),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchMessagesRequestSaga({ params }: fetchMessagesSagaProps) {
  const { groupId, page } = params;

  try {
    const { dataArea } = yield select((state) => state.root);

    const { data } = yield call(
      fetchApi,
      `/${dataArea}/messages/groups/${groupId}/messages?page=${page}`
    );

    const messages = data && reverseArray(data);

    yield put({
      type: actions.FETCH_MESSAGES_SUCCESS,
      messages,
      pagination: {
        page,
      },
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        handleError(error),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchLastMessageRequestSaga({
  params,
}: fetchLastMessageSagaProps) {
  const { groupId, messageId } = params;

  try {
    const { dataArea } = yield select((state) => state.root);

    const { data: message } = yield call(
      fetchApi,
      `/${dataArea}/messages/groups/${groupId}/messages/${messageId}`
    );

    yield put({
      type: actions.FETCH_LAST_MESSAGE_SUCCESS,
      groupId,
      message,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        handleError(error),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchRealTimeMessageRequestSaga({
  params,
}: UpdateMessageSagaProps) {
  const { groupId, messageId } = params;

  try {
    const { dataArea } = yield select((state) => state.root);

    const { data: updatedMessage } = yield call(
      fetchApi,
      `/${dataArea}/messages/groups/${groupId}/messages/${messageId}`
    );

    yield put({
      type: actions.FETCH_REAL_TIME_MESSAGE_SUCCESS,
      groupId,
      messageId,
      message: updatedMessage,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

export function* setArchiveGroupRequestSaga({
  currentGroup,
}: setArchiveGroupRequestSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    yield call(deleteApi, `/${dataArea}/messages/groups/${currentGroup.id}`);

    yield put({
      type: actions.SET_ARCHIVE_GROUP_SUCCESS,
      currentGroup,
      toast: buildToast(
        `O grupo "${currentGroup.attributes.name}" foi arquivado com sucesso.`,
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        `Ops, houve um erro ao tentar arquivar o grupo "${currentGroup.attributes.name}". Tente novamente!`,
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* setMuteAndTurnOnNotificationRequestSaga({
  currentGroup,
}: setMuteAndTurnOnNotificationRequestSagaProps) {
  const enableSound = !currentGroup.attributes.enable_sound;
  const currentMemberId = currentGroup.attributes.current_member_logged_id;

  try {
    const { dataArea } = yield select((state) => state.root);

    yield call(
      patchApi,
      `/${dataArea}/messages/groups/${currentGroup.id}/members/${currentMemberId}?enable_sound=${enableSound}`
    );

    yield put({
      type: actions.SET_MUTE_AND_TURN_ON_NOTIFICATION_SUCCESS,
      currentGroup,
      enableSound,
      toast: buildToast(
        enableSound
          ? `As notificações do grupo "${currentGroup.attributes.name}" foram ativadas com sucesso. Você irá receber notificações.`
          : `O grupo "${currentGroup.attributes.name}" foi silenciado com sucesso. Você não receberá mais notificações.`,
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        enableSound
          ? `Ops, houve um erro ao ativar as notificações deste grupo. Tente novamente!`
          : `Ops, houve um erro ao tentar silenciar este grupo. Tente novamente!`,
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  }
}

export function* setCurrentGroupRequestSaga({
  group,
}: setCurrentGroupRequestSagaProps) {
  const {
    id,
    attributes: { current_member_logged_id: memberId },
  } = group;

  try {
    const { dataArea } = yield select((state) => state.root);

    yield call(
      patchApi,
      `/${dataArea}/messages/groups/${id}/members/${memberId}?unread_message_count=0`
    );

    yield put({
      type: actions.SET_CURRENT_GROUP_SUCCESS,
      group,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

export function* setReadCurrentMessagesRequestSaga({
  currentGroup,
}: setCurrentGroupRequestSagaProps) {
  const {
    id,
    attributes: { current_member_logged_id: memberId },
  } = currentGroup;

  try {
    const { dataArea } = yield select((state) => state.root);

    yield call(
      patchApi,
      `/${dataArea}/messages/groups/${id}/members/${memberId}?unread_message_count=0`
    );

    yield put({ type: actions.SET_READ_CURRENT_MESSAGES_SUCCESS });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

export function* updateMessageRequestSaga({
  updateMessage,
}: UpdateMessageSagaProps) {
  const { groupId, messageId, updatedContent } = updateMessage;

  try {
    const { dataArea } = yield select((state) => state.root);

    const { data: updatedMessage } = yield call(
      patchApi,
      `/${dataArea}/messages/groups/${groupId}/messages/${messageId}`,
      {
        content: updatedContent,
      }
    );

    yield put({
      type: actions.UPDATE_MESSAGE_SUCCESS,
      groupId,
      message: updatedMessage,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

function* messagesSagas() {
  yield all([
    takeLatest(actions.CREATE_NEW_GROUP_REQUEST, createNewGroupRequestSaga),
    takeLatest(actions.CREATE_NEW_MESSAGE_REQUEST, createNewMessageRequestSaga),
    takeLatest(actions.FETCH_SCHOOL_USERS_REQUEST, fetchSchoolUsersRequestSaga),
    takeLatest(actions.FETCH_GROUPS_REQUEST, fetchGroupsRequestSaga),
    takeLatest(actions.FETCH_MESSAGES_REQUEST, fetchMessagesRequestSaga),
    takeLatest(actions.FETCH_LAST_MESSAGE_REQUEST, fetchLastMessageRequestSaga),
    takeLatest(
      actions.FETCH_REAL_TIME_MESSAGE_REQUEST,
      fetchRealTimeMessageRequestSaga
    ),
    takeLatest(actions.SET_ARCHIVE_GROUP_REQUEST, setArchiveGroupRequestSaga),
    takeLatest(
      actions.SET_MUTE_AND_TURN_ON_NOTIFICATION_REQUEST,
      setMuteAndTurnOnNotificationRequestSaga
    ),
    takeLatest(actions.SET_CURRENT_GROUP_REQUEST, setCurrentGroupRequestSaga),
    takeLatest(
      actions.SET_READ_CURRENT_MESSAGES_REQUEST,
      setReadCurrentMessagesRequestSaga
    ),
    takeLatest(actions.UPDATE_MESSAGE_REQUEST, updateMessageRequestSaga),
  ]);
}

export default messagesSagas;
