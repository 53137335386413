import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import {
  Box,
  Card,
  DefaultThemeProps,
  SubtleIconButton,
  Text,
  Tag,
  RecipientTag,
} from '@agendaedu/ae-web-components';
import { useTheme } from 'styled-components';
import * as S from './styles';
import formatDatetime from 'core/utils/formatDatetime';
import { DeleteAlbumModal } from '../DeleteAlbumModal';
import { AlbumUserPermissionIncluded, RootState } from 'store/albums/types';
import { EMPTY_STATES_URL } from 'core/constants/index';
import { Props } from './types';
import { Recipient } from '@agendaedu/ae-web-components/dist/components/display/RecipientTag/types';

const AlbumCard = ({ album, history, id: idElement }: Props) => {
  const { t } = useTranslation(['albums']);
  const { border } = useTheme() as DefaultThemeProps;
  const dataArea = useSelector(
    (state: { root: { dataArea: string } }) => state.root.dataArea
  );
  const { albumsIncluded } = useSelector((state: RootState) => state.albums);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const {
    attributes: { photoCoverUrl },
    relationships: {
      userPermission: {
        data: { id: userPermissionId },
      },
    },
  } = album;

  const userPermission = albumsIncluded.find(
    (include) =>
      include.type === 'userPermission' && include.id === userPermissionId
  ) as AlbumUserPermissionIncluded;

  const actions =
    [
      userPermission.attributes.canUpdate && !album.attributes.deleting &&
      {
        as: 'button',
        title: t('list.album_card.edit_album'),
        onClick: (e) => {
          e.stopPropagation();

          history.push(`/${dataArea}/albums/${album.id}/edit`);
        },
      },
      userPermission.attributes.canDestroy && !album.attributes.deleting &&
      {
        as: 'button',
        title: t('list.album_card.delete_album'),
        onClick: (e) => {
          e.stopPropagation();
          setShowDeleteModal(true);
        },
      },
    ].filter(Boolean);

  const onClickAlbum = () => {
    if (!album.attributes?.deleting) {
      window.location.assign(`/${dataArea}/albums/${album.id}`);
    }
  };

  const recipientTag = useMemo(() => {
    const recipientType =
      album?.attributes.category === 'student' ? 'student' : 'classroom';

    const recipients: Recipient[] = album?.relationships.recipients.data.reduce(
      (prev, current) => {
        const relatedItem = albumsIncluded.find(
          (include) =>
            include.id === current.id &&
            include.type ===
              (recipientType === 'student' ? 'studentProfile' : 'classroom')
        );

        return relatedItem ? [...prev, relatedItem.attributes] : prev;
      },
      []
    );

    if (!recipients?.length)
      return {
        type: t(`album_details.without_recipients`),
        recipients: [{ name: t(`album_details.without_recipients`) }],
      };

    return {
      type: t(`album_details.${recipientType}`),
      recipients,
    };
  }, [
    album?.attributes.category,
    album?.relationships.recipients.data,
    albumsIncluded,
    t,
  ]);

  return (
    <Card
      id={idElement}
      cursor={album.attributes.deleting ? 'not-allowed' : 'pointer'}
      data-testid="album-card"
      width="100%"
      p={0}
      onClick={onClickAlbum}
    >
      <S.AlbumWrapper>
        <Box display="flex" position="relative" width="100%" height="100%">
          <S.CoverWrapper>
            <S.CoverImage
              src={photoCoverUrl || EMPTY_STATES_URL.photoPlaceholder}
              alt={album.attributes.name}
            />
          </S.CoverWrapper>

          <S.InfoContainer>
            <Box position="absolute" top={0} left={0} right={0} m="sm">
              <Box
                display="flex"
                justifyContent="space-between"
                onClick={(e) => e.stopPropagation()}
              >
                <RecipientTag
                  variant="neutral"
                  recipientType={recipientTag.type}
                  recipients={recipientTag.recipients}
                  modalTitle={recipientTag.type}
                  mr="sm"
                  size="small"
                  enableEllipsis
                />
                {!!actions.length && (
                  <Box
                    display="flex"
                    alignItems="center"
                    backgroundColor="white"
                    borderRadius={border.radius.sm}
                  >
                    <SubtleIconButton
                      data-testid="actions-card"
                      size="xs"
                      align="right"
                      actions={actions}
                    />
                  </Box>
                )}
              </Box>

              <Box
                width="fit-content"
                backgroundColor="white"
                borderRadius={border.radius.sm}
                mt="xs2"
              >
                {!!album.attributes.totalNumberOfPendingPhotos &&
                  !album.attributes.deleting && (
                    <Tag
                      size="small"
                      variant="warning"
                      name={t('list.album_card.photos_pending_approval', {
                        count: album.attributes.totalNumberOfPendingPhotos,
                      })}
                    />
                  )}
                {album.attributes.deleting && (
                  <Tag
                    size="small"
                    variant="negative"
                    name={t('list.album_card.deleting_album')}
                  />
                )}
              </Box>
            </Box>

            <Box
              position="absolute"
              left={0}
              right={0}
              bottom={0}
              m="sm"
              mb={0}
            >
              <Text
                variant="subtitle-medium-12"
                color="neutral.white"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
              >
                {album.attributes.name}
              </Text>
              <Text variant="subtitle-medium-12" color="neutral.white">
                {t('list.album_card.photo_count', {
                  count: album.attributes.totalNumberOfPhotos,
                })}
              </Text>
              <Text variant="subtitle-medium-12" color="neutral.white">
                {formatDatetime(
                  album.attributes.createdAt,
                  t('list.album_card.album_date')
                )}
              </Text>
            </Box>
          </S.InfoContainer>
        </Box>
      </S.AlbumWrapper>

      <DeleteAlbumModal
        album={album}
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
      />
    </Card>
  );
};

export default withRouter(AlbumCard);
