import React, { createContext, ChangeEvent, useState } from 'react';
import GooglePicker from 'components/GooglePicker';
import { Box } from '@agendaedu/ae-web-components';
import useGoogleDrive from 'core/hooks/useGoogleDrive';
import DirectUpload from 'core/services/Uploader/DirectUpload';

import {
  UploadFilesContextProps,
  GDriveFile,
  FileType,
  UploadFileInfo,
  Props,
} from './types';
import axios from 'axios';
import AcceptedContentTypes from './acceptedContentTypes';

const googleClientId = process.env.GOOGLE_CLIENT_ID;

export const UploadFilesContext = createContext<UploadFilesContextProps>(
  undefined!
);

const generateFileID = (fileName: string) =>
  `${Math.random().toString(36).substring(3)}-${fileName}`;

const normalizeLocalFiles = (
  files: ChangeEvent<HTMLInputElement>['target']['files']
) =>
  Array.from(files).map(
    (file) =>
      Object.assign(file, {
        fileId: generateFileID(file.name),
        origin: 'local',
      }) as FileType
  );

const normalizeGoogleDriveFiles = (oauthToken: string, files: GDriveFile[]) =>
  files.map(
    (file) =>
      Object.assign(file, {
        fileId: generateFileID(file.name),
        origin: 'google_drive',
        size: file.sizeBytes,
        type: file.mimeType,
        oauthToken,
      }) as FileType
  );

const UploadFilesProvider: React.FC<Props> = ({
  children,
  shouldConcatFiles,
  providerId = '',
}) => {
  const directUpload = new DirectUpload('schools');

  useGoogleDrive(
    googleClientId,
    'https://www.googleapis.com/auth/drive.readonly'
  );

  const [selectedFiles, setSelectedFiles] = useState<
    UploadFilesContextProps['selectedFiles']
  >([]);

  const onLocalChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    const normalizedFiles = normalizeLocalFiles(files);

    setSelectedFiles((prevFiles) =>
      shouldConcatFiles ? [...prevFiles, ...normalizedFiles] : normalizedFiles
    );
  };

  const onDriveChange = (oauthToken: string, files: GDriveFile[]) => {
    const normalizedFiles = normalizeGoogleDriveFiles(oauthToken, files);

    setSelectedFiles((prevFiles) =>
      shouldConcatFiles ? [...prevFiles, ...normalizedFiles] : normalizedFiles
    );
  };

  const googleDriveWrapper = (content: JSX.Element) => (
    <GooglePicker
      clientId={googleClientId}
      scope={['https://www.googleapis.com/auth/drive.readonly']}
      onChange={onDriveChange}
      multiselect
    >
      {content}
    </GooglePicker>
  );

  const openSelectLocalFiles: UploadFilesContextProps['openSelectLocalFiles'] =
    (acceptedTypes) => {
      const input: HTMLInputElement = document.querySelector(
        `#select-media-${providerId}`
      );
      input.value = '';
      input.accept = (acceptedTypes || AcceptedContentTypes).join(',');
      input.click();
    };

  const updateFileInfos = (uploadInfos: UploadFileInfo) => {
    setSelectedFiles((prev) =>
      prev.map((fileInfo) => {
        if (fileInfo.fileId === uploadInfos.fileId) {
          Object.assign(fileInfo, { ...uploadInfos });
        }
        return fileInfo;
      })
    );
  };

  const uploadFile: UploadFilesContextProps['uploadFile'] = async ({
    file,
    progressCallback,
    onSuccess,
    onFailed,
  }) => {
    try {
      const cancelSource = axios.CancelToken.source();

      const newFile = Object.assign(file, { cancelSource });

      const uploadAction = {
        local: async () => await directUpload.upload(newFile, progressCallback),
        google_drive: async () =>
          await directUpload.googleDriveUpload(file.oauthToken, file),
      }[file.origin];

      const { signed_id: signedId, url: fileUrl } = await uploadAction();

      const uploadInfos = {
        fileId: newFile.fileId,
        signedId,
        fileUrl,
        status: 'success',
      } as UploadFileInfo;

      updateFileInfos(uploadInfos);

      onSuccess?.(uploadInfos);
    } catch {
      const uploadInfos = {
        fileId: file.fileId,
        status: 'failed',
      } as UploadFileInfo;

      updateFileInfos(uploadInfos);

      onFailed?.();
    }
  };

  const destroyFile: UploadFilesContextProps['destroyFile'] = async ({
    fileId,
    signedId,
    onSuccess,
    onFailed,
  }) => {
    try {
      if (signedId) {
        await directUpload.destroyFile(signedId);
      }

      setSelectedFiles((prev) => prev.filter((file) => file.fileId !== fileId));

      onSuccess?.();
    } catch (error) {
      onFailed?.();
    }
  };

  const destroyMultipleFiles: UploadFilesContextProps['destroyMultipleFiles'] =
    async ({ filesToDestroyInfos, onSuccess, onFailed }) => {
      try {
        const signedIdsToDestroy = filesToDestroyInfos.reduce(
          (prev, { signedId }) => (signedId ? [...prev, signedId] : prev),
          []
        );

        const fileIds = new Set(filesToDestroyInfos.map((file) => file.fileId));

        directUpload.destroyFileBatch(signedIdsToDestroy);

        setSelectedFiles((prev) =>
          prev.filter((file) => !fileIds.has(file.fileId))
        );
        onSuccess?.();
      } catch (error) {
        onFailed?.();
      }
    };

  return (
    <UploadFilesContext.Provider
      value={{
        selectedFiles,
        googleDriveWrapper,
        openSelectLocalFiles,
        uploadFile,
        destroyFile,
        destroyMultipleFiles,
        setSelectedFiles,
      }}
    >
      {children}
      <Box display="none">
        <input
          id={`select-media-${providerId}`}
          data-testid="select-media"
          type="file"
          multiple
          onChange={onLocalChange}
        />
      </Box>
    </UploadFilesContext.Provider>
  );
};

export default UploadFilesProvider;
