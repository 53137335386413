import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import autobind from 'autobind-decorator';

import appContextProvider from 'core/hoc/appContextProvider';
import toastMessageProvider from 'core/hoc/toastMessageProvider';
import withAppContext from 'core/hoc/withAppContext';
import withErrorBoundary from 'core/hoc/withErrorBoundary';
import SchoolTermService from 'core/services/SchoolTerm';
import composeFunctions from 'core/utils/composeFunctions';

import SchoolTermsList from 'components/SchoolTerms/List';
import SchoolTermsNew from 'components/SchoolTerms/New';
import SchoolTermsEdit from 'components/SchoolTerms/Edit';

class SchoolTermsMainScreen extends Component {
  static propTypes = {
    appContext: PropTypes.shape({
      dataArea: PropTypes.string.isRequired,
    }).isRequired,
  };

  state = {
    schoolTerms: [],
    isLoadingSchoolTerms: true,
    errorFetchingSchoolTerms: null,
  };

  componentDidMount() {
    this.loadSchoolTerms();
  }

  @autobind
  async loadSchoolTerms() {
    const {
      appContext: { dataArea },
    } = this.props;
    const schoolTermService = new SchoolTermService(dataArea);

    try {
      const { schoolTerms } = await schoolTermService.fetchSchoolTerms();
      this.setState({ schoolTerms });
    } catch (error) {
      this.setState({ errorFetchingSchoolTerms: error });
    } finally {
      this.setState({ isLoadingSchoolTerms: false });
    }
  }

  render() {
    const { schoolTerms, isLoadingSchoolTerms, errorFetchingSchoolTerms } =
      this.state;

    if (errorFetchingSchoolTerms) throw errorFetchingSchoolTerms;

    return (
      <div className="SchoolTermsMainScreen">
        <BrowserRouter>
          <Switch>
            <Route
              exact
              path="/schools/school_terms"
              location={{ state: {} }}
              render={(props) => (
                <SchoolTermsList
                  schoolTerms={schoolTerms}
                  isLoading={isLoadingSchoolTerms}
                  toastState={props.location.state}
                  history={props.history}
                />
              )}
            />
            <Route
              exact
              path="/schools/school_terms/new"
              render={() => <SchoolTermsNew />}
            />
            <Route
              exact
              path="/schools/school_terms/:schoolTermId/edit"
              render={(props) => <SchoolTermsEdit match={props.match} />}
            />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default composeFunctions(
  toastMessageProvider,
  withErrorBoundary,
  withAppContext,
  appContextProvider
)(SchoolTermsMainScreen);
