import { Box, Text } from '@agendaedu/ae-web-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as S from './styles';

const StorageList = () => {
  const { t } = useTranslation(['storage']);

  const dataArea = useSelector(
    (state: { root: { dataArea: string } }) => state.root.dataArea
  );

  return (
    <Box mt="sm" mb="xl" mx={{ _: 'sm', desktopLG: 'xl' }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb="md"
      >
        <Text
          variant="headline-h2-bold-24"
          fontWeight="bold"
          color="neutral.black"
        >
          {t('list.title')}
        </Text>

        <Link to={`/${dataArea}/storage/folders/new`}>
          <S.NewButton id="new-button" size="md">
            {t('list.new_folder')}
          </S.NewButton>
        </Link>
      </Box>
    </Box>
  );
};

export default StorageList;
