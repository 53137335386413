import React, { useContext, useMemo } from 'react';
import { SectionGroup } from './components/SectionGroup';
import {
  BabyBottleCard,
  DiaperCard,
  EvacuationCard,
  ObservationCard,
  ShowerCard,
  SleepCard,
} from 'pages/diarySectionsV3/Sections/components/ShowSectionModal/Sections';
import { DiaryFormContext } from 'core/contexts/DiaryForm';

import * as S from './styles';
import {
  Alert,
  Box,
  DefaultThemeProps,
  Icon,
  Text,
} from '@agendaedu/ae-web-components';
import { useTranslation } from 'react-i18next';
import { CustomSectionGroup } from './components/CustomSectionGroup';
import { useSelector } from 'react-redux';
import { DiaryState } from 'store/dailySummaries/types';
import SectionsSkeleton from './components/Skeleton';
import { useTheme } from 'styled-components';

export const SectionList = () => {
  const { t } = useTranslation('diary');
  const { breakpoints } = useTheme() as DefaultThemeProps;
  const {
    form: { values, setFieldValue, initialValues },
    studentsIdsSelecteds,
  } = useContext(DiaryFormContext);
  const {
    diarySections: { activesSections },
    isLoadingStudentDiaryForm,
    classroom: {
      attributes: {
        student_profiles: { data },
      },
    },
  } = useSelector((state: DiaryState) => state.dailySummaries);

  const activeSectionsSlugs = activesSections
    .map(({ slug }) => slug)
    .filter(Boolean);

  const hasSomeStudentSelectedWithDiary = useMemo(
    () =>
      studentsIdsSelecteds.length > 1 &&
      data.some(
        (student) =>
          studentsIdsSelecteds.includes(student.id) &&
          student.attributes.has_daily_summary
      ),
    [data, studentsIdsSelecteds]
  );

  if (isLoadingStudentDiaryForm) return <SectionsSkeleton />;

  return (
    <S.SectionsWrapper>
      {hasSomeStudentSelectedWithDiary && (
        <Box maxWidth={breakpoints.tabletMD}>
          <Alert mb="md" variant="informative">
            {t('form.fill_info')}
          </Alert>
        </Box>
      )}

      {activeSectionsSlugs.includes('meals') && <SectionGroup field="meals" />}

      {activeSectionsSlugs.includes('baby_bottle') && (
        <BabyBottleCard
          babyBottles={values.baby_bottles}
          onChange={(babyBottles) => setFieldValue('baby_bottles', babyBottles)}
        />
      )}

      {activeSectionsSlugs.includes('sleep') && (
        <SleepCard
          sleeps={values.sleeps}
          onChange={(sleeps) => setFieldValue('sleeps', sleeps)}
        />
      )}

      {activeSectionsSlugs.includes('evacuation') && (
        <EvacuationCard
          evacuations={values.evacuations}
          onChange={(evacuations) => setFieldValue('evacuations', evacuations)}
        />
      )}

      <S.RowWrapper>
        {activeSectionsSlugs.includes('diaper') && (
          <DiaperCard
            diaper={values.diaper}
            onChange={(diaper) => setFieldValue('diaper', diaper)}
          />
        )}

        {activeSectionsSlugs.includes('shower') && (
          <ShowerCard
            shower={values.shower}
            onChange={(shower) => setFieldValue('shower', shower)}
          />
        )}
      </S.RowWrapper>

      {activeSectionsSlugs.includes('occurrence') && (
        <SectionGroup field="occurrences" />
      )}

      {activeSectionsSlugs.includes('observation') && (
        <ObservationCard
          observation={values.observation}
          onChange={(observation) => setFieldValue('observation', observation)}
          hasInitialValue={!!initialValues.observation}
        />
      )}

      <CustomSectionGroup />

      <Box>
        <Box display="flex" alignItems="center" mt="xl">
          <Icon name="info-circle" />
          <Text
            variant="label-regular-14"
            color="neutral.gray2"
            ml="xs2"
            mb={0}
          >
            {t('form.footer_info')}
          </Text>
        </Box>
      </Box>
    </S.SectionsWrapper>
  );
};
