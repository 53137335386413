import React, { useCallback, useContext, useMemo } from 'react';
import { useTheme } from 'styled-components';

import {
  Box,
  Button,
  DefaultThemeProps,
  SubtleIconButton,
  Text,
  TextField,
} from '@agendaedu/ae-web-components';
import { DiarySectionContext } from 'core/contexts/DiarySections';

import { useTranslation } from 'react-i18next';
import Accordion from 'components/Accordion';
import SectionWrapper from '../SectionWrapper';

const InfoStep = () => {
  const { t } = useTranslation(['diary_sections', 'common']);
  const {
    form: { values, isValid, submitForm, setFieldValue, errors },
  } = useContext(DiarySectionContext);

  const { breakpoints, colors } = useTheme() as DefaultThemeProps;

  const tBase = useCallback(
    (key: string, params?: Record<string, unknown>) =>
      t(`sections.form.info_tab.${key}`, params),
    [t]
  );

  const fieldActions = useMemo(
    () => ({
      addNewField: () => {
        const newQuestionsAttributes = {
          title: '',
          kind: 'text',
        };

        setFieldValue('questionsAttributes', [
          ...values.questionsAttributes,
          newQuestionsAttributes,
        ]);
      },
      duplicateField: (index: number) => {
        const copyQuestionsAttributes = structuredClone(
          values.questionsAttributes[index]
        );

        delete copyQuestionsAttributes.id;

        copyQuestionsAttributes.answerOptionsAttributes?.forEach((option) => {
          delete option.id;
        });

        setFieldValue('questionsAttributes', [
          ...values.questionsAttributes,
          copyQuestionsAttributes,
        ]);
      },
      deleteField: (index: number) => {
        const questionsAttributes = structuredClone(values.questionsAttributes);

        questionsAttributes.splice(index, 1);

        setFieldValue('questionsAttributes', questionsAttributes);
      },
    }),
    [setFieldValue, values.questionsAttributes]
  );

  const footer = (
    <Box
      py="sm"
      position="fixed"
      left={0}
      right={0}
      bottom={0}
      px={{ _: 'lg', mobileXS: 'xs2', desktopLG: 'xl5' }}
      backgroundColor={colors.neutral.white}
    >
      <Button
        data-testid="next-step-button"
        ml="auto"
        disabled={!isValid}
        onClick={submitForm}
      >
        {t('common:button.next')}
      </Button>
    </Box>
  );

  const fieldsList = useMemo(() => {
    const getActions = (index: number) =>
      [
        {
          as: 'button',
          title: t('common:button.duplicate'),
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
            fieldActions.duplicateField(index);
          },
        },
        values.questionsAttributes.length > 1 && {
          as: 'button',
          title: t('common:button.delete'),
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
            fieldActions.deleteField(index);
          },
        },
      ].filter(Boolean);

    return values.questionsAttributes.map((question, index) => (
      <Box key={index} mt="sm">
        <Accordion
          allowZeroExpanded
          expanded="sections"
          header={
            <Box
              display="flex"
              width="100%"
              justifyContent="space-between"
              ml="md"
              alignItems="center"
            >
              <Text variant="title-bold-20" mb="0" color="neutral.black">
                {tBase('field', { count: index + 1 })}
              </Text>

              <SubtleIconButton
                size="md"
                align="right"
                actions={getActions(index)}
              />
            </Box>
          }
          body={<SectionWrapper question={question} index={index} />}
        />
      </Box>
    ));
  }, [fieldActions, t, tBase, values.questionsAttributes]);

  return (
    <Box
      display="flex"
      position="relative"
      flex={1}
      flexDirection="column"
      justifyContent="space-between"
      mx={{ _: 'lg', mobileXS: 'xs2', desktopLG: 'xl5' }}
    >
      <Box width="100%" height="100%" mt="xl2" pb="md" overflow="auto">
        <Box maxWidth={breakpoints.tabletMD} mx="auto" mb="30vh">
          <TextField
            id="name-field"
            fullWidth
            label={tBase('name_field_label')}
            onChange={(e) => setFieldValue('name', e.target.value)}
            value={values.name}
            error={!!errors.name}
            errorMessage={errors.name}
          />

          <Box display="flex" flexDirection="column">
            {fieldsList}
          </Box>

          <Box>
            <Button
              mt="lg"
              mx="auto"
              variant="secondary"
              onClick={fieldActions.addNewField}
            >
              {tBase('add_field')}
            </Button>
          </Box>
        </Box>
      </Box>

      {footer}
    </Box>
  );
};

export default InfoStep;
