import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import autobind from 'autobind-decorator';

import PageContainer from 'components/PageContainer';
import PageSubTitle from 'components/PageSubTitle';
import FormContainer from 'components/Form/FormContainer';
import Button from 'components/Button';

import './style.scss';
import SchoolTermsProgressionForm from 'components/SchoolTerms/ProgressionForm';
import ClassroomsService from 'core/services/Classrooms';
import Loader from 'components/Loader';
import Breadcrumb from 'components/Breadcrumb';
import ModalContainer from 'components/Modal/ModalContainer';
import ModalTitle from 'components/Modal/ModalTitle';

import SchoolTermService from 'core/services/SchoolTerm';

import FormContext from 'core/contexts/Form';
import withAppContext from 'core/hoc/withAppContext';
import withToastMessage from 'core/hoc/withToastMessage';

class SchoolTermsNew extends Component {
  static propTypes = {
    appContext: PropTypes.shape({
      dataArea: PropTypes.string.isRequired,
    }).isRequired,
    toastActions: PropTypes.shape({
      showToast: PropTypes.func.isRequired,
    }).isRequired,
    isSubmitting: PropTypes.bool,
  };

  state = {
    classrooms: [],
    isLoading: true,
    isModalOpen: true,
    isSubmitting: false,
  };

  async componentDidMount() {
    const classroomsService = new ClassroomsService('schools');

    try {
      const classrooms = await classroomsService.getClassrooms();
      this.setState({ classrooms, isLoading: false });
    } catch (error) {
      this.setState({ error });
    }
  }

  @autobind
  async onSubmit(formState) {
    const {
      appContext: { dataArea },
      toastActions: { showToast },
    } = this.props;
    const schoolTermService = new SchoolTermService(dataArea);

    this.setState({ isSubmitting: true, isLoading: true });
    try {
      const schoolTerm = await schoolTermService.create(formState);
      this.setState({ schoolTerm, isRedirectingToListSchoolTerm: true });
    } catch (error) {
      showToast(
        'Ocorreu um erro, verifique se o nome do período letivo já está em uso. Se o erro persistir, aguarde um instante e tente novamente.'
      );
      this.setState({ error, isSubmitting: false, isLoading: false });
    }
  }

  @autobind
  footer({ form, hasErrorOnAttribute }) {
    const { isSubmitting } = this.state;
    return (
      <Button
        onClick={() => this.onSubmit(form)}
        disabled={
          isSubmitting || hasErrorOnAttribute('name') || !form.name.length
        }
      >
        Criar período letivo
      </Button>
    );
  }

  @autobind
  closeModal() {
    this.setState({ isModalOpen: false });
  }

  render() {
    const {
      appContext: { dataArea },
    } = this.props;
    const { isRedirectingToListSchoolTerm } = this.state;

    const { classrooms, isLoading, isModalOpen } = this.state;

    if (isRedirectingToListSchoolTerm) {
      return <Redirect to={`/${dataArea}/school_terms/`} />;
    }

    return (
      <div className="NewSchoolTerm">
        <Loader isLoading={isLoading}>
          <FormContainer
            action="new"
            formMeta={{ classrooms }}
            form={{ classroom_ids: [], name: '' }}
          >
            <FormContext.Consumer>
              {({ form, hasErrorOnAttribute }) => (
                <PageContainer
                  title="Criar período letivo"
                  breadcrumb={
                    <Breadcrumb
                      title="Períodos letivos"
                      path="/schools/school_terms"
                    />
                  }
                  footer={this.footer({ form, hasErrorOnAttribute })}
                  variation="centered"
                >
                  <PageSubTitle>Informações do novo período</PageSubTitle>
                  <span className="info">
                    Você deve criar um novo período letivo para progredir os
                    alunos para suas novas turmas.
                  </span>
                  <span className="info">
                    Selecione quais turmas irão permanecer no novo período.
                  </span>
                  <SchoolTermsProgressionForm />
                </PageContainer>
              )}
            </FormContext.Consumer>
          </FormContainer>
          <ModalContainer
            isOpen={isModalOpen}
            toggleModal={this.closeModal}
            className="NewSchoolTermModal"
          >
            <ModalTitle>É importante lembrar!</ModalTitle>
            <p>
              Após ativação do período, todas as turmas selecionadas para o novo
              período estarão prontas para receber os alunos progredidos e novos
              alunos cadastrados.
            </p>
            <p>
              Os profissionais continuam vinculados às mesmas turmas, para
              alterar acesse o perfil do profissional.
            </p>
            <div className="actions">
              <Button onClick={this.closeModal}>Entendi</Button>
            </div>
          </ModalContainer>
        </Loader>
      </div>
    );
  }
}

export default withAppContext(withToastMessage(SchoolTermsNew));
