import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GroupMembersStepProps, SchoolMembersSelected } from './types';
import * as S from './styles';

import tabifyForWizard from 'core/hoc/tabifyForWizard';
import withFormContext from 'core/hoc/withFormContext';

import Avatar from 'components/Avatar';
import FormSelector from 'components/Form/Selector';
import Title from 'components/WizardForm/Title';

import actionMessages from 'store/messages/actions';
import { MessageState } from 'store/messages/types';

const GroupMembersStep = ({
  formContext: {
    form: { member_ids: schoolMembersIds },
    changeMeta,
    updateAttribute,
  },
}: GroupMembersStepProps) => {
  const dispatch = useDispatch();

  const { schoolUsers } = useSelector((state: MessageState) => state.messages);

  const { fetchSchoolUsersRequest } = actionMessages;

  const handlerSelectSchoolMemberIds = ({
    selectedValues,
  }: SchoolMembersSelected): void => {
    updateAttribute('member_ids', selectedValues);
  };

  const renderOptionComponent = (option): React.ReactNode => {
    if (option) {
      return (
        <S.ItemWrapper>
          <Avatar user={option} />
          <S.Titulo>{option.attributes.name}</S.Titulo>
        </S.ItemWrapper>
      );
    }
  };
  useEffect(() => {
    schoolMembersIds?.length > 0
      ? changeMeta('nextStepDisabled', false)
      : changeMeta('nextStepDisabled', true);
  }, [schoolMembersIds, changeMeta]);

  useEffect(() => {
    dispatch(fetchSchoolUsersRequest());
  }, [dispatch, fetchSchoolUsersRequest]);

  return (
    <S.GroupMembersStepWrapper>
      <Title name="Selecionar membros" />
      <div className="FormSelectorWrapper">
        <FormSelector
          options={schoolUsers}
          selectedValues={schoolMembersIds ?? []}
          optionComponent={renderOptionComponent}
          onChange={handlerSelectSchoolMemberIds}
          optionAccessor={(optionAccessor) => optionAccessor.id}
          onSelectBehavior="highlight"
          selectAllLabel="Selecionar todos"
          counterLabel={(length) =>
            length === 1 ? 'profissional' : 'profissionais'
          }
        />
      </div>
    </S.GroupMembersStepWrapper>
  );
};

export default tabifyForWizard({
  title: '2 Membros',
})(withFormContext(GroupMembersStep));
