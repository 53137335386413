import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import withFormContext from 'core/hoc/withFormContext';
import walletsActions from 'store/edupay/wallets/actions';
import Field from 'components/Form/Field';
import { RootState, WalletConfigurationProps } from './types';
import * as S from './styles';

const WALLET_DEFAULT_NAME =
  'Mensalidade Garantida - EDU PAY SECURITIZADORA S/A';

const WalletConfiguration = ({
  formContext: {
    updateAttribute,
    form: { recipient_wallet_id: recipientWalletId },
  },
}: WalletConfigurationProps) => {
  const dispatch = useDispatch();
  const { fetchRecipientsWalletsOptionsRequest } = walletsActions;
  const { walletsOptions } = useSelector((state) => state.wallets);

  const {
    currentUser: {
      attributes: { is_debug_user },
    },
  } = useSelector((state: RootState) => state.root);

  useEffect(() => {
    dispatch(fetchRecipientsWalletsOptionsRequest());
  }, [dispatch, fetchRecipientsWalletsOptionsRequest]);

  const updateRecipientWalletId = (value) => {
    updateAttribute('recipient_wallet_id', value);
  };

  const filterWalletOptions = is_debug_user
    ? walletsOptions.filter((wallet) => wallet.label === WALLET_DEFAULT_NAME)
    : walletsOptions.filter(
        (option) => !option.label.includes('Mensalidade Garantida')
      );

  useEffect(() => {
    if (is_debug_user) {
      const guaranteedMonthlyFeeWallet = walletsOptions.find(
        (wallet) => wallet.label === WALLET_DEFAULT_NAME
      );
      updateRecipientWalletId(guaranteedMonthlyFeeWallet?.value);
    }
  }, [walletsOptions, is_debug_user]);

  return (
    <S.Wrapper>
      <Field
        fullWidth
        withArrowSelect
        label="Carteira de destino*"
        type="selectWithIcon"
        attributeName="recipient_wallet_id"
        classNamePrefix="wallet_configuration"
        options={filterWalletOptions}
        value={recipientWalletId}
        onChange={(e) => {
          updateRecipientWalletId(e.value);
        }}
        disabled={is_debug_user}
      />
    </S.Wrapper>
  );
};

export default withFormContext(WalletConfiguration);
