import React, { createContext, useCallback, useState } from 'react';
import * as yup from 'yup';

import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { StorageFolder } from 'store/storage/types';
import StorageActions from 'store/storage/actions';

import { ContextParams, Props } from './types';
import { useDispatch } from 'react-redux';

const initialValuesInfo: StorageFolder['form'] = {
  title: '',
  description: '',
  sentKind: 'classrooms',
  personaType: 'both',
  classroomIds: [],
  classroomWithStudentProfileIds: {},
};

export const StorageFolderContext = createContext<Props>({
  form: { values: initialValuesInfo },
} as Props);

const StorageFolderProvider: React.FC<ContextParams> = ({
  children,
  folderAscendingId,
}) => {
  const dispatch = useDispatch();
  const { saveFolderRequest } = StorageActions;
  const [currentStep, setCurrentStep] = useState(1);

  const { t } = useTranslation('storage');

  const tBase = useCallback(
    (key: string, params?: Record<string, string | number>) =>
      t(`folder_form.${key}`, params),
    [t]
  );

  const formValidationSchema = yup.object().shape({
    title: yup
      .string()
      .required(tBase('info_step.required_field_error'))
      .max(100, tBase('info_step.limit_character_error', { count: 100 })),
    description: yup
      .string()
      .optional()
      .max(2000, tBase('info_step.limit_character_error', { count: 2000 })),
    sentKind: yup.string().required(),
    personaType: yup.string().required(),
    classroomIds: yup
      .array()
      .test(
        'classroomIds-validation',
        'classroomIds-min-error',
        (value, context) => {
          const { sentKind } = context.parent;

          if (sentKind === 'classrooms') {
            return value.length > 0;
          }
          return true;
        }
      ),
    classroomWithStudentProfileIds: yup
      .object()
      .test(
        'classroomWithStudentProfileIds-validation',
        'classroomWithStudentProfileIds-min-error',
        (value, context) => {
          const { sentKind } = context.parent;

          if (sentKind === 'students') {
            return value && Object.keys(value).length > 0;
          }
          return true;
        }
      ),
  });

  const onSubmit = (values: StorageFolder['form']) => {
    dispatch(saveFolderRequest({ ...values, folderAscendingId }));
  };

  const form = useFormik({
    initialValues: initialValuesInfo,
    onSubmit: onSubmit,
    validationSchema: formValidationSchema,
    validateOnChange: true,
    isInitialValid: false,
  });

  const isInfoStepValid = [
    !!form.values.title,
    !form.errors.title,
    !form.errors.description,
  ].every(Boolean);

  return (
    <StorageFolderContext.Provider
      value={{
        form,
        currentStep,
        setCurrentStep,
        isInfoStepValid,
      }}
    >
      {children}
    </StorageFolderContext.Provider>
  );
};

export default StorageFolderProvider;
