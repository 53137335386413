import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import actions from './actions';
import { fetchApi, postApi, putApi, handleError } from 'core/utils/api';
import { push } from 'connected-react-router';
import {
  buildToast,
  toastCss,
  ToastTypes,
} from 'store/middlewares/toast/actions';

export function* fetchOffers(action) {
  try {
    const { dataArea } = yield select((state) => state.root);
    const { currentWallet } = yield select((state) => state.wallets);

    const response = yield call(
      fetchApi,
      `/${dataArea}/marketplaces/offers.json`,
      {
        params: {
          recipient_wallet_id: currentWallet?.attributes?.recipient_wallet_id,
          ...action.filter,
        },
      }
    );
    yield put({
      type: actions.FETCH_SUCCESS_OFFERS,
      data: response.history,
      totalItemsCount: response.totalItemsCount,
      filters: action.filter,
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_ERROR,
      error,
      toast: buildToast(
        'Não foi possível obter a listagem de ofertas.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchClassroomsSelectOptions() {
  try {
    const { dataArea } = yield select((state) => state.root);

    const {
      meta: { select_options },
    } = yield call(fetchApi, `/${dataArea}/marketplaces/offers/new.json`);
    yield put({
      type: actions.FETCH_SUCCESS_CLASSROMS_SELECT_OPTIONS,
      selectOptions: select_options,
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_ERROR,
      error,
    });
  }
}

function* fetchOfferDetails(action) {
  try {
    const { dataArea } = yield select((state) => state.root);
    const { currentWallet } = yield select((state) => state.wallets);

    const { history } = yield call(
      fetchApi,
      `/${dataArea}/marketplaces/offers/${action.offerId}.json`,
      {
        params: {
          recipient_wallet_id: currentWallet?.attributes?.recipient_wallet_id,
          ...action.filter,
        },
      }
    );
    yield put({
      type: actions.FETCH_SUCCESS_OFFER_DETAILS,
      data: history,
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_ERROR,
      error,
      toast: buildToast(
        'Não foi possível obter os detalhes da oferta.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* setClassroomsRequest() {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { history } = yield call(
      fetchApi,
      `/${dataArea}/student_profiles/classrooms.json`
    );
    yield put({
      type: actions.SET_CLASSROOMS_SUCCESS,
      classrooms: history,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível obter a listagem de turmas.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* fetchOfferEdit(action) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data, meta } = yield call(
      fetchApi,
      `/${dataArea}/marketplaces/offers/${action.id}/edit.json`
    );

    yield put({
      type: actions.FETCH_SUCCESS_OFFER_EDIT,
      meta,
      data: {data},
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

export function* addOffer(action) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data } = yield call(
      postApi,
      `/${dataArea}/marketplaces/offers`,
      action.params
    );

    yield put(push(`/schools/school_products/tab/shop`));

    yield put({
      type: actions.ADD_OFFER_SUCCESS,
      toast: buildToast(
        'Oferta cadastrada com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        handleError(action.error),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* editOffer(action) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data } = yield call(
      putApi,
      `/${dataArea}/marketplaces/offers/${action.offerId}`,
      action.params
    );

    yield put(push(`/schools/school_products/tab/shop`));

    yield put({
      type: actions.EDIT_OFFER_SUCCESS,
      toast: buildToast(
        'Oferta editada com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        handleError(action.error),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchClassroomsStudents(action) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { history: classroomsStudents } = yield call(
      fetchApi,
      `/${dataArea}/marketplaces/offers/${action.id}/students`
    );

    yield put({
      type: actions.FETCH_SUCCESS_CLASSROOMS_STUDENTS,
      classroomsStudents,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      toast: buildToast(
        'Não foi possivel carregar as turmas',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
      error,
    });
  }
}

function* shopSagas() {
  yield all([
    takeLatest(actions.FETCH_REQUEST_OFFERS, fetchOffers),
    takeLatest(actions.FETCH_REQUEST_OFFER_DETAILS, fetchOfferDetails),
    takeLatest(actions.SET_CLASSROOMS_REQUEST, setClassroomsRequest),
    takeLatest(actions.ADD_OFFER_REQUEST, addOffer),
    takeLatest(actions.EDIT_OFFER_REQUEST, editOffer),
    takeLatest(
      actions.FETCH_REQUEST_CLASSROMS_SELECT_OPTIONS,
      fetchClassroomsSelectOptions
    ),
    takeLatest(
      actions.FETCH_REQUEST_CLASSROOMS_STUDENTS,
      fetchClassroomsStudents
    ),
    takeLatest(actions.FETCH_REQUEST_OFFER_EDIT, fetchOfferEdit),
  ]);
}

export default shopSagas;
