import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-autosize-textarea';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';

import AgendaIcon from 'components/AgendaIcon';
import Loader from 'components/Loader';
import MessagesUploadModal from 'components/Messages/UploadModal';
import MessagesUploadModalInvalidAttachment from 'components/Messages/UploadModal/InvalidAttachment';

import withAppContext from 'core/hoc/withAppContext';
import withToastMessage from 'core/hoc/withToastMessage';
import withFormContext from 'core/hoc/withFormContext';

import composeFunctions from 'core/utils/composeFunctions';
import colorWithAlpha from 'core/utils/colorWithAlpha';

import emojiTranslations from './emojiTranslations';
import getEmojiPickerStyles from './getEmojiPickerStyles';
import './style.scss';

class ChatTextAreaComponent extends Component {
  static propTypes = {
    appContext: PropTypes.shape({
      dataArea: PropTypes.string,
      primaryColor: PropTypes.string,
    }),
    attributeName: PropTypes.string.isRequired,
    attachment: PropTypes.any,
    formContext: PropTypes.shape({
      changeAttribute: PropTypes.func,
      form: PropTypes.object.isRequired,
    }),
    isLoadingNewMessage: PropTypes.bool,
    invalidAttachment: PropTypes.bool,
    invalidAttachmentError: PropTypes.array,
    isUploadModalOpen: PropTypes.bool,
    toggleInvalidAttachmentModal: PropTypes.func,
    onAttachmentSelect: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    onUploadCancel: PropTypes.func,
    onUploadSuccess: PropTypes.func,
    onChangeMessage: PropTypes.func,
    editionMode: PropTypes.bool,
    originalMessage: PropTypes.string,
    message: PropTypes.string,
    disabledAttachment: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    disabledAttachment: false,
    disabled: false,
  };

  state = {
    isemojiPanelOpen: false,
    isSendingMessage: false,
    typedMessage: false,
    hoveringEmoji: false,
    hoveringAttachment: false,
  };

  hoveringBackground = () => {
    this.setState({ hoveringAttachment: true });
  };

  listenKeyboardPress = (event) => {
    if ((event.key === 'Enter' || event.keyCode === 13) && event.shiftKey) {
      event.preventDefault();
      this.onSubmit();
    }
  };

  onUploadSuccess = (message) => {
    this.setState({ showProgressBar: false });
    this.props.onUploadSuccess(message);
  };

  onSubmit = async () => {
    const { onSubmit, message } = this.props;

    if (this.state.isSendingMessage) {
      return;
    }

    this.setState({ isSendingMessage: true });

    try {
      await onSubmit({ message, clearInput: this.clearMessage });
    } catch (error) {
      this.setState({ isSendingMessage: false });
      throw error;
    } finally {
      this.setState({ isSendingMessage: false });
    }
  };

  clearMessage = () => {
    const { onChangeMessage, formContext } = this.props;

    const { changeAttribute } = formContext;

    const fakeEvent = {
      target: {
        value: '',
      },
    };

    this.setState({ typedMessage: false });
    this.onChange(fakeEvent);
    onChangeMessage('');
    changeAttribute('message')(fakeEvent);
  };

  handleCommentAttachment = ({ message }) => {
    const { onChangeMessage } = this.props;

    this.setState({ typedMessage: !!message });
    onChangeMessage(message);
  };

  onChange = (event) => {
    const { onChangeMessage } = this.props;

    const message = event.target.value;

    this.setState({ typedMessage: !!message });
    onChangeMessage(message);
  };

  setEditorNode = (node) => {
    this.textArea = node;
  };

  focusEditor = () => {
    this.textArea.focus();
    window.scrollTo(0, document.body.scrollHeight);
  };

  // Later move the emoji picker to a separated component

  toggleEmoji = () => {
    this.setState((prevState) => ({
      isEmojiPanelOpen: !prevState.isEmojiPanelOpen,
    }));
  };

  addEmoji = (emoji) => {
    const { formContext, onChangeMessage, message } = this.props;
    const { changeAttribute } = formContext;
    const { selectionStart, selectionEnd } = this.textArea;

    const messageText =
      message.substring(0, selectionStart) +
      emoji.native +
      message.substring(selectionEnd, message.length);

    const fakeEvent = {
      target: {
        value: messageText,
      },
    };

    // We need this callback to ensure we change the text area's selection
    // AFTER state's update.
    const afterInsertEmojiCallback = () => {
      this.textArea.focus();
      // For some reason, emojis add 2 characters, not one.
      this.textArea.setSelectionRange(selectionStart + 2, selectionStart + 2);
    };

    changeAttribute('message', afterInsertEmojiCallback)(fakeEvent);
    onChangeMessage(messageText);
  };

  setEmojiWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  handleClickOutsideEmoji = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.toggleEmoji();
    }
  };

  waitingForEdit = () => {
    const { editionMode, originalMessage, message } = this.props;

    return editionMode && originalMessage === message;
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutsideEmoji);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutsideEmoji);
  }

  render() {
    const {
      typedMessage,
      hoveringAttachment,
      hoveringEmoji,
      isEmojiPanelOpen,
      isSendingMessage,
    } = this.state;

    const {
      appContext,
      onAttachmentSelect,
      isLoadingNewMessage,
      isUploadModalOpen,
      attachment,
      onUploadCancel,
      invalidAttachment,
      invalidAttachmentError,
      toggleInvalidAttachmentModal,
      message,
      disabledAttachment,
      disabled,
    } = this.props;

    let emojiBackgroundStyle = {};
    let emojiIconStyle = {};
    let attachmentBackgroundStyle = {};
    let attachmentIconStyle = {};
    let sendBackgroundStyle = {};

    if (hoveringEmoji) {
      emojiBackgroundStyle = {
        backgroundColor: colorWithAlpha(appContext.primaryColor, 0.1),
      };
      emojiIconStyle = {
        color: appContext.primaryColor,
      };
    }

    if (hoveringAttachment) {
      attachmentBackgroundStyle.backgroundColor = colorWithAlpha(
        appContext.primaryColor,
        0.1
      );
      attachmentIconStyle.color = appContext.primaryColor;
    }

    if (isSendingMessage || this.waitingForEdit() || disabled) {
      sendBackgroundStyle.backgroundColor = '#c7cbd6';
    } else {
      sendBackgroundStyle.backgroundColor = appContext.primaryColor;
    }

    return (
      <div className="MessagesChatInput">
        <div className="input-content">
          {isEmojiPanelOpen && (
            <div ref={this.setEmojiWrapperRef} className="picker-wrapper">
              <Picker
                onSelect={this.addEmoji}
                i18n={emojiTranslations}
                style={getEmojiPickerStyles()}
              />
            </div>
          )}
          <div
            className="emoji-picker"
            onClick={this.toggleEmoji}
            style={emojiBackgroundStyle}
            onMouseEnter={() => this.setState({ hoveringEmoji: true })}
            onMouseLeave={() => this.setState({ hoveringEmoji: false })}
          >
            <AgendaIcon
              name="smile"
              style={emojiIconStyle}
              className="emoji-icon"
            />
          </div>
          <div
            className={`input-area ${typedMessage ? 'typed' : ''}`}
            onClick={this.focusEditor}
          >
            <TextareaAutosize
              maxRows={6}
              placeholder="Digite uma mensagem"
              onChange={this.onChange}
              ref={this.setEditorNode}
              onKeyPress={this.listenKeyboardPress}
              value={message}
            />
          </div>
          <div className="input-actions">
            {!typedMessage && !disabledAttachment && (
              <div
                className="chat-attachment"
                style={attachmentBackgroundStyle}
                onMouseEnter={() => this.setState({ hoveringAttachment: true })}
                onMouseLeave={() =>
                  this.setState({ hoveringAttachment: false })
                }
              >
                <label htmlFor="attachment-input" className="attachment-input">
                  <AgendaIcon
                    name="attachment"
                    size="x-medium"
                    style={attachmentIconStyle}
                  />
                </label>

                <input
                  id="attachment-input"
                  type="file"
                  onChange={onAttachmentSelect}
                />
              </div>
            )}
            <button
              className="round-icon"
              style={sendBackgroundStyle}
              disabled={
                isLoadingNewMessage || this.waitingForEdit() || disabled
              }
              onMouseEnter={() => this.setState({ hoveringSend: true })}
              onMouseLeave={() => this.setState({ hoveringSend: false })}
              onClick={this.onSubmit}
            >
              <Loader
                isLoading={isLoadingNewMessage || isSendingMessage}
                variation="centered"
                color="#FFFFFF"
              >
                <AgendaIcon name="send-message" />
              </Loader>
            </button>
          </div>
        </div>
        {attachment && (
          <MessagesUploadModal
            isOpen={isUploadModalOpen}
            toggleModal={onUploadCancel}
            attachment={attachment}
            sendFile={this.onSubmit}
            onChange={this.handleCommentAttachment}
          />
        )}
        {invalidAttachment && (
          <MessagesUploadModalInvalidAttachment
            isOpen={invalidAttachment}
            toggleModal={toggleInvalidAttachmentModal}
            errorType={invalidAttachmentError}
          />
        )}
      </div>
    );
  }
}

const ChatTextArea = composeFunctions(
  withToastMessage,
  withFormContext,
  withAppContext
)(ChatTextAreaComponent);

export { ChatTextArea };
