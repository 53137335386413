import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';
import { CardAvatar } from '@agendaedu/ae-web-components';

export const WhiteBackground = styled.div`
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.14);
  border-radius: ${({ theme }) => theme.border.radius.md};
  &::-webkit-scrollbar {
    background-color: ${({ theme }) => theme.white};
    width: 1px;
    border-radius: ${({ theme }) => theme.border.radius.md};
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.white};
    border-radius: ${({ theme }) => theme.border.radius.md};
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
    box-shadow: 0px 0px 0px 10vh white;
    border-radius: 10px;
    border: 50%;
  }
`;

export const HorizontalScroll = styled(WhiteBackground)`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    margin-bottom: ${space.xl2};
  `}
`;

export const ContainerCard = styled.div`
  ${({ theme: { colors, space } }: ThemeProps) => css`
    display: inline-block;
    background-color: ${colors.neutral.white};
    margin: ${space.lg} 0px ${space.lg} ${space.lg};
  `}
`;

export const SquareIcon = styled.div<{ bgcolor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  background-color: ${(props) => props.bgcolor};
  border-radius: ${({ theme }) => theme.border.radius.md};
`;

export const Content = styled.div`
  position: relative;
  z-index: 0;
`;

export const Container = styled.div`
  position: relative;
`;

export const Overlay = styled.div`
  position: absolute;
  z-index: 1;
  margin-left: -18px;
  margin-top: 8px;
  visibility: hidden;
`;

export const Logo = styled.img`
  height: 64px;
  width: 64px;
  border-radius: ${({ theme }) => theme.border.radius.md};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MainTitle = styled.h3`
  ${({ theme: { colors, typography, font } }: ThemeProps) => css`
    ${typography.title.Bold20};
    color: ${colors.neutral.black};
    font-size: ${font.size.xl};
    line-height: 24px;
    margin-top: 0px;
  `}
`;

export const TermColor = styled.span`
  line-height: 170%;
  font-family: ${({ theme }) => theme.font.family.auxiliary};
  font-style: normal;
  font-weight: 700;
  font-size: ${({ theme }) => theme.font.size.sm};
  width: 80%;
  color: ${({ theme }) => theme.colors.brand.primary.default};
  padding: 1px 2px 1px 1px;
  text-align: justify;
  text-justify: inter-word;
`;

export const ContainerTitle = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: ${space.xl} 0px ${space.xl} 0px;
  `}
`;

export const CardAvatarStyled = styled(CardAvatar)`
  ${({ theme: { colors, space, border } }: ThemeProps) => css`
    box-shadow: none !important;
    border: ${border.width.md} solid ${colors.neutral.gray4};
    padding: ${space.sm} ${space.sm} 0px !important;

    > h1 {
      margin: ${space.sm} 0px ${space.lg} 0px !important;
      padding: 0px !important;
      font-size: ${space.sm} !important;
    }
    > div {
      margin-bottom: 0px !important;
    }
  `}
`;
