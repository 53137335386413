export const flags = Object.freeze({
  RESEND_BILLET_EMAIL: 'time-inadimplencia.resend.billet',
  SCHOOL_PRODUCT_CANCELLATION: 'time-pagamentos.school.product.cancellation',
  SCHOOL_PRODUCT_PAID_AT_SCHOOL: 'time-pagamentos.school.product.paidAtSchool',
  ENROLLMENT_CANCELLATION: 'time-pagamentos.enrollment.cancellation',
  ENROLLMENT_PAID_AT_SCHOOL: 'time-pagamentos.enrollment.paidAtSchool',
  RECURRENT_EDIT_IN_BATCH: 'time-pagamentos-recurrent-edit-in-batch',
  NEGOTIATION_IS_ACTIVE: 'time-inadimplencia.negotiation',
  CANCEL_WITH_BOND: 'time-pagamentos-cancellation-with-bond',
  PANEL_HUBSTORE: 'time-conexoes.hub-store.explore',
  NEGOTIATION_TAB: 'time-inadimplencia.negotiation.tab',
  NEGOTIATION_TAB_CHECKBOX: 'time-inadimplencia.negotiation.tab.checkbox',
  PIX_ISSUED_AT_SCHOOL: 'time-core-banking-pix-issued-at-school',
  PIX_ISSUED_AT_SCHOOL_PRODUCT_AND_NEGOTIATION:
    'time-core-banking-pix-issued-at-school-product-and-negotiation',
  ABSORB_TAX: 'time-pagamentos-absorb-tax',
  ATTENDANT_TIME: 'time-comunicacao-channel-opening-hours',
  CREDIT_CARD_DISCOUNT: 'pagamentos-descontos-no-cartao-em-recorrencia',
  DUPLICATE_HANDOUT: 'time-comunicacao-duplicate-handout',
  HANDOUT_TEMPLATE: 'time-comunicacao-handout-template',
  CUSTOM_SECTIONS: 'time-acompanhamento.diario.secoes-personalizadas',
});
