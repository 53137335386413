import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Socket, io } from 'socket.io-client';

import EmptyState from 'components/EmptyState';
import GroupCard from 'components/Messages/MessageGroup/GroupCard';
import GroupCardSkeleton from 'components/Messages/MessageGroup/GroupCard/skeleton';
import GroupChat from 'components/Messages/MessageGroup/GroupChat';
import GroupHeader from 'components/Messages/MessageGroup/GroupHeader';
import MessagesModal from 'components/Messages/Modals/MessagesModal';
import NotificationModal from 'components/Messages/Modals/NotificationModal';
import Toast from 'components/Toast';

import { MESSAGE_GROUP_IMAGES } from 'core/constants';
import withAppContext from 'core/hoc/withAppContext';

import actionMessages from 'store/messages/actions';
import { MessageState, GroupProps } from 'store/messages/types';

import { MessageGroupScreenProps } from './types';

import * as S from './styles';

const MessageGroupScreen = ({
  appContext: {
    currentUser: { id: userId },
  },
}: MessageGroupScreenProps) => {
  const dispatch = useDispatch();

  const [socket, setSocket] = useState<Socket | null>(null);

  const {
    groups,
    currentGroup,
    showModal,
    showNotificationModal,
    isLoading,
    isReadCurrentMessages,
  } = useSelector((state: MessageState) => state.messages);

  const {
    fetchGroupsRequest,
    fetchLastMessageRequest,
    fetchRealTimeMessageRequest,
    setArchiveGroupRequest,
    setReadCurrentMessagesRequest,
    setMuteAndTurnOnNotificationRequest,
    toggleModal,
    toggleNotificationModal,
  } = actionMessages;

  const isEmpty = !isLoading && groups.length === 0;

  const handleToggleModal = useCallback(() => {
    dispatch(toggleModal());
  }, [dispatch, toggleModal]);

  const handleToggleNotificationModal = useCallback(() => {
    dispatch(toggleNotificationModal());
  }, [dispatch, toggleNotificationModal]);

  const handleArchiveGroup = useCallback((): void => {
    dispatch(setArchiveGroupRequest(currentGroup));
    handleToggleModal();
  }, [dispatch, setArchiveGroupRequest, handleToggleModal, currentGroup]);

  const handleSetReadCurrentMessages = useCallback(() => {
    dispatch(setReadCurrentMessagesRequest(currentGroup));
  }, [dispatch, setReadCurrentMessagesRequest, currentGroup]);

  const socketEvents = useCallback(
    (event: 'join-room' | 'leave-room') => {
      const groupIds = groups.map((group: GroupProps) => group.id);

      const eventTypes = {
        'join-room': () => {
          groupIds.forEach((groupId: string) => {
            socket.emit('join-room', groupId);
          });
        },
        'leave-room': () => {
          groupIds.forEach((groupId: string) => {
            socket.emit('leave-room', groupId);
          });
        },
      };

      eventTypes[event]();
    },
    [groups, socket]
  );

  const renderGroups = () => {
    if (isLoading) {
      return <GroupCardSkeleton amount={5} />;
    } else if (groups.length > 0) {
      return groups.map((group: GroupProps) => (
        <GroupCard key={group.id} group={group} />
      ));
    } else {
      return (
        <EmptyState
          message="Não há grupos criados"
          imgUrl={MESSAGE_GROUP_IMAGES.no_groups}
        />
      );
    }
  };

  const handleMuteAndTurnOnNotification = useCallback(() => {
    if (currentGroup) {
      dispatch(setMuteAndTurnOnNotificationRequest(currentGroup));

      handleToggleNotificationModal();
    }
  }, [
    currentGroup,
    dispatch,
    setMuteAndTurnOnNotificationRequest,
    handleToggleNotificationModal,
  ]);

  const setModalType = () =>
    currentGroup && currentGroup.attributes.enable_sound ? 'mute' : 'active';

  useEffect(() => {
    dispatch(fetchGroupsRequest(userId));
  }, []);

  useEffect(() => {
    let newSocket: Socket | null = null;

    newSocket = io(process.env.WEBSOCKETS_SERVER_URL || 'ws://localhost:8080');
    setSocket(newSocket);

    return () => {
      if (newSocket) {
        newSocket.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (!socket) return;

    socketEvents('join-room');
    socket.on('new-message', (messageId: string, groupId: string) => {
      const params = {
        groupId,
        messageId,
      };

      dispatch(fetchLastMessageRequest(params));
    });

    socket.on('edit-message', (messageId: string, groupId: string) => {
      const params = {
        groupId,
        messageId,
      };

      dispatch(fetchRealTimeMessageRequest(params));
    });

    return () => {
      socketEvents('leave-room');
      socket.off('new-message');
      socket.off('edit-message');
    };
  }, [
    dispatch,
    fetchLastMessageRequest,
    socket,
    socketEvents,
    fetchRealTimeMessageRequest,
  ]);

  useEffect(() => {
    if (isReadCurrentMessages) handleSetReadCurrentMessages();
  }, [handleSetReadCurrentMessages, isReadCurrentMessages]);

  return (
    <>
      <S.GroupWrapper currentGroup={!!currentGroup}>
        <S.GroupContainer>
          <GroupHeader title="Grupos" />
          <S.GroupList empty={isEmpty}>{renderGroups()}</S.GroupList>
        </S.GroupContainer>

        <S.MessageContainer>
          {currentGroup ? (
            <GroupChat />
          ) : (
            <EmptyState
              message="Selecione um grupo e veja as mensagens aqui"
              imgUrl={MESSAGE_GROUP_IMAGES.message_info}
            />
          )}
        </S.MessageContainer>
      </S.GroupWrapper>
      <MessagesModal
        dataTestId="archive-group-modal"
        showModal={showModal}
        toggleModal={handleToggleModal}
        handleSubmit={handleArchiveGroup}
        title="Deseja arquivar este grupo?"
        description="O grupo será arquivado e todos os membros não terão mais acesso ao histórico de mensagens. Tem certeza que deseja arquivar?"
        submitButtonText="Arquivar"
      />
      <NotificationModal
        showModal={showNotificationModal}
        type={setModalType()}
        toggleModal={handleToggleNotificationModal}
        handleMuteAndTurnOnNotification={handleMuteAndTurnOnNotification}
      />
      <Toast />
    </>
  );
};

export default withAppContext(MessageGroupScreen);
