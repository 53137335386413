import React, { useState, useEffect, useCallback, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';

import { ChatTextArea } from 'components/Messages/ChatTextArea';
import DropdownButton from 'components/DropdownButton';
import EmptyState from 'components/EmptyState';
import FormContainer from 'components/Form/FormContainer';
import FooterToast from 'components/Messages/TS/Animations/FooterToast';
import GroupDetailsModal from 'components/Messages/Modals/GroupDetailsModal';
import Message from 'components/Messages/MessageGroup/MessageBalloon';
import MessageBalloonSkeleton from 'components/Messages/MessageGroup/MessageBalloon/skeleton';

import { MESSAGE_GROUP_IMAGES } from 'core/constants';
import withAppContext from 'core/hoc/withAppContext';

import messagesActions from 'store/messages/actions';
import {
  MessageProps,
  MessageState,
  CreateNewMessageRequestParams,
  UpdateMessageRequestParams,
} from 'store/messages/types';

import { GroupChatProps, HandlerSubmitProps } from './types';
import * as S from './styles';

const GroupChat = ({ appContext }: GroupChatProps) => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState<string>(null);

  const soundNotificationRef = useRef<HTMLAudioElement>(
    new Audio(
      'https://static.agendaedu.com/communication/group/notification.mp3'
    )
  );

  const submitDisabled = !message || /^[\n]+$/.test(message);

  const {
    messages,
    selectedMessage,
    currentGroup,
    pagination: { page: currentPage, per_page: itemsPerPage },
    showDetailModal,
    isEditingMessage,
    isSending,
    isLoading,
    isLoadingMessages,
    isLoadingMoreMessages,
    isSoundNotification,
  } = useSelector((state: MessageState) => state.messages);

  const {
    id: groupId,
    attributes: { name, enable_sound: enableSound },
  } = currentGroup;

  const {
    clearCurrentGroup,
    closeEditMessage,
    createNewMessageRequest,
    fetchMessagesRequest,
    toggleModal,
    toggleDetailModal,
    toggleNotificationModal,
    updateMessageRequest,
  } = messagesActions;

  const fetchMessages = useCallback(
    (page: number) => {
      const params = { groupId, page };

      dispatch(fetchMessagesRequest(params));
    },
    [dispatch, fetchMessagesRequest, groupId]
  );

  const onChangeMessage = (message: string) => {
    setMessage(message);
  };

  const handleCloseEditMessage = useCallback(() => {
    dispatch(closeEditMessage());
  }, [dispatch, closeEditMessage]);

  const handleClearCurrentGroup = useCallback(() => {
    dispatch(clearCurrentGroup());
  }, [dispatch, clearCurrentGroup]);

  const handleCreateNewMessage = useCallback(
    (message: string) => {
      const newMessage: CreateNewMessageRequestParams = {
        groupId,
        content: message.trim(),
      };

      dispatch(createNewMessageRequest(newMessage));
    },
    [dispatch, createNewMessageRequest, groupId]
  );

  const handleUpdateMessage = useCallback(
    (message: string) => {
      const updateMessage: UpdateMessageRequestParams = {
        groupId,
        messageId: selectedMessage.id,
        updatedContent: message.trim(),
      };

      dispatch(updateMessageRequest(updateMessage));
    },
    [dispatch, updateMessageRequest, groupId, selectedMessage]
  );

  const handleSubmit = useCallback(
    ({ message }: HandlerSubmitProps) => {
      if (submitDisabled) return;

      if (!selectedMessage) {
        handleCreateNewMessage(message);
      } else {
        handleUpdateMessage(message);
      }

      setMessage('');
    },
    [
      handleCreateNewMessage,
      handleUpdateMessage,
      submitDisabled,
      selectedMessage,
    ]
  );

  const handleToggleDetailModal = useCallback(() => {
    dispatch(toggleDetailModal());
  }, [dispatch, toggleDetailModal]);

  const handleToggleModal = useCallback(() => {
    dispatch(toggleModal());
  }, [dispatch, toggleModal]);

  const handleToggleNotificationModal = useCallback(() => {
    dispatch(toggleNotificationModal());
  }, [dispatch, toggleNotificationModal]);

  const renderActionsItems = () => {
    const actions = [];

    actions.push({
      text: enableSound ? 'Silenciar grupo' : 'Ativar notificações',
      onClick: handleToggleNotificationModal,
    });

    appContext.policies?.can_destroy_group &&
      actions.push({
        text: 'Arquivar grupo',
        onClick: handleToggleModal,
      });

    return actions;
  };

  const hasMoreMessages = () => {
    const totalNumberOfMessages = currentPage * itemsPerPage;
    return totalNumberOfMessages === messages.length;
  };

  const fetchMoreMessages = () => {
    const page = currentPage + 1;
    fetchMessages(page);
  };

  const renderMessages = () => {
    if (isLoading || isLoadingMessages) {
      return (
        <>
          <MessageBalloonSkeleton variantType="received" />
          <MessageBalloonSkeleton variantType="sent" />
        </>
      );
    } else if (messages.length > 0) {
      return (
        <InfiniteScroll
          dataLength={messages.length}
          next={fetchMoreMessages}
          hasMore={hasMoreMessages()}
          loader={
            isLoadingMoreMessages && (
              <>
                <MessageBalloonSkeleton variantType="received" />
                <MessageBalloonSkeleton variantType="sent" />
              </>
            )
          }
          inverse={true}
          scrollableTarget="chat-content-wrapper"
        >
          {messages.map((message: MessageProps) => (
            <Message key={message.id} message={message} />
          ))}
        </InfiniteScroll>
      );
    } else {
      return (
        <S.EmptyWrapper>
          <EmptyState
            message="Sem mensagens por aqui ainda. Que tal mandar a primeira?"
            imgUrl={MESSAGE_GROUP_IMAGES.no_messages}
          />
        </S.EmptyWrapper>
      );
    }
  };

  useEffect(() => {
    fetchMessages(1);
  }, [fetchMessages]);

  useEffect(() => {
    const { current: soundNotification } = soundNotificationRef;

    if (!soundNotification) return;

    if (isSoundNotification) soundNotification.play();
  }, [isSoundNotification]);

  useEffect(() => {
    if (selectedMessage) {
      setMessage(selectedMessage.attributes.content);
      return;
    }
    setMessage('');
  }, [selectedMessage]);

  return (
    <S.GroupChatWrapper>
      <S.ChatHeaderWrapper data-testid="chat-header-wrapper">
        <S.ChatHeaderContainer>
          <S.BackButton icon="arrow-left" onClick={handleClearCurrentGroup} />
          <S.ChatHeaderContent
            onClick={handleToggleDetailModal}
            data-testid="chat-header-content"
          >
            <S.ChatHeaderTitle>Grupo</S.ChatHeaderTitle>
            <S.ChatHeaderSubtitle>{name}</S.ChatHeaderSubtitle>
          </S.ChatHeaderContent>
        </S.ChatHeaderContainer>

        <S.DropdownButtonWrapper>
          <DropdownButton
            icon="dots-vertical"
            variation="white"
            dropdownItems={renderActionsItems()}
          />
        </S.DropdownButtonWrapper>
      </S.ChatHeaderWrapper>

      <S.ChatContentWrapper
        id="chat-content-wrapper"
        data-testid="chat-content-wrapper"
      >
        {renderMessages()}
      </S.ChatContentWrapper>

      {isEditingMessage && (
        <FooterToast variant="edit" handleOnClose={handleCloseEditMessage} />
      )}

      <S.ChatFooterWrapper data-testid="chat-footer-wrapper">
        <FormContainer>
          <ChatTextArea
            attributeName="message"
            message={message}
            onChangeMessage={onChangeMessage}
            onSubmit={handleSubmit}
            isLoadingNewMessage={isSending}
            disabledAttachment
            disabled={submitDisabled}
          />
        </FormContainer>
      </S.ChatFooterWrapper>
      {showDetailModal && (
        <GroupDetailsModal
          dataTestId="group-detail-modal"
          title="Detalhes do grupo"
          isOpen={showDetailModal}
          group={currentGroup}
          toggleModal={handleToggleDetailModal}
        />
      )}
    </S.GroupChatWrapper>
  );
};

export default withAppContext(GroupChat);
