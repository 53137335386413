import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './styles';

import {
  Box,
  Button,
  DefaultThemeProps,
  Icon,
  Select,
  Text,
  TextField,
  Tooltip,
} from '@agendaedu/ae-web-components';
import UploadFilesProvider, {
  UploadFilesContext,
} from 'core/contexts/UploadFiles';
import { validFilesTypes } from 'core/contexts/DiaryForm/constants';
import { LIMIT_SIZE_10_MB } from 'core/contexts/UploadFiles/constants';
import { FileUpload, Props } from './types';
import { FileItem } from './FileItem';
import { useTheme } from 'styled-components';

export const FILE_LIMIT = 10;

const OccurrenceComponent = ({
  occurrence,
  onChange,
}: Props): React.ReactElement => {
  const { t } = useTranslation(['diary_sections']);
  const {
    selectedFiles,
    openSelectLocalFiles,
    uploadFile,
    destroyFile,
    setSelectedFiles,
  } = useContext(UploadFilesContext);

  const { colors } = useTheme() as DefaultThemeProps;

  const [occurrenceValues, setOccurrenceValues] = useState(occurrence);

  const OPTIONS = [
    {
      label: t('sections.card.occurrence.options.select'),
      value: '',
    },
    {
      label: t('sections.card.occurrence.options.attendance'),
      value: 'attendance',
    },
    {
      label: t('sections.card.occurrence.options.punctuality'),
      value: 'punctuality',
    },
    {
      label: t('sections.card.occurrence.options.school_supplies'),
      value: 'school_supplies',
    },
    {
      label: t('sections.card.occurrence.options.uniform'),
      value: 'uniform',
    },
    {
      label: t('sections.card.occurrence.options.behavior'),
      value: 'behavior',
    },
    {
      label: t('sections.card.occurrence.options.others'),
      value: 'others',
    },
  ];

  const handleChangeOptions = (value: string) => {
    if (!value) setSelectedFiles([]);

    setOccurrenceValues((prev) => ({
      ...prev,
      category: value,
      description: !value ? '' : prev.description,
      files: !value ? [] : prev.files,
    }));
  };

  const handleChangeDescription = (value: string) => {
    setOccurrenceValues((prev) => ({ ...prev, description: value }));
  };

  const validateSelectedFiles: FileUpload[] = selectedFiles.reduce(
    (prev, file: FileUpload) => {
      const invalidType =
        !validFilesTypes.includes(file.type) &&
        t('sections.card.occurrence.file_errors.invalid_file');

      const invalidSize =
        file.size > LIMIT_SIZE_10_MB &&
        t('sections.card.occurrence.file_errors.invalid_size');

      const invalidReasons = [invalidType, invalidSize].filter(Boolean);

      return [...prev, Object.assign(file, { invalidReasons })];
    },
    []
  );

  const canAddFile = selectedFiles.length < FILE_LIMIT;

  useEffect(() => {
    setSelectedFiles(occurrence.files as FileUpload[]);
  }, []);

  useEffect(() => {
    setOccurrenceValues((prev) => ({
      ...prev,
      files: validateSelectedFiles.map(
        ({ name, size, type, signedId, url, invalidReasons }) => ({
          name,
          size,
          type,
          signedId,
          fileId: signedId,
          url,
          invalidReasons,
        })
      ),
    }));
  }, [selectedFiles]);

  useEffect(() => {
    onChange(occurrenceValues);
  }, [occurrenceValues]);

  return (
    <S.OccurrenceWrapper data-testid="occurrence-card">
      <Text variant="title-bold-16" color="neutral.black" marginBottom="0">
        {t('sections.card.occurrence.title')}
      </Text>

      <Select
        fullWidth
        label={t('sections.card.occurrence.category')}
        placeholder={t('sections.card.occurrence.category_placeholder')}
        options={OPTIONS}
        value={occurrenceValues.category}
        onChange={(option) => handleChangeOptions(option.value)}
      />

      <TextField
        fullWidth
        disabled={!occurrence.category}
        label={t('sections.card.occurrence.description')}
        placeholder={t('sections.card.occurrence.description_placeholder')}
        value={occurrenceValues.description}
        onChange={(e) => handleChangeDescription(e.target.value)}
      />

      <S.ButtonWrapper>
        <Box display="flex">
          <Text
            variant="label-regular-14"
            color="neutral.gray1"
            marginBottom="0"
            mr="xs2"
          >
            {t('sections.card.occurrence.file_label')}
          </Text>

          <Tooltip
            align="top-end"
            content={t('sections.card.occurrence.accepted_files')}
            elementRef={
              <Box display="flex" alignItems="center">
                <Icon
                  name="help-circle"
                  size="sm"
                  color={colors.neutral.gray1}
                />
              </Box>
            }
          />

          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
            ml="auto"
          >
            <Text
              variant="label-regular-14"
              color="neutral.gray1"
              marginBottom="0"
            >
              {t('sections.card.occurrence.files_count', {
                selected: selectedFiles.length,
                limit: FILE_LIMIT,
              })}
            </Text>

            {selectedFiles.length > FILE_LIMIT && (
              <Text
                variant="label-regular-14"
                color="context.alert.default"
                marginBottom="0"
              >
                {t('sections.card.occurrence.file_errors.file_limit', {
                  limit: FILE_LIMIT,
                })}
              </Text>
            )}
          </Box>
        </Box>

        <Box>
          {canAddFile && (
            <Button
              icon="attachment"
              variant="secondary"
              disabled={!occurrenceValues.category || !canAddFile}
              onClick={() => openSelectLocalFiles()}
            >
              {t('sections.card.occurrence.file_button')}
            </Button>
          )}
        </Box>

        <>
          {validateSelectedFiles.map((file) => (
            <Box key={file.fileId}>
              <FileItem
                file={file}
                uploadFile={uploadFile}
                destroyFile={destroyFile}
              />
            </Box>
          ))}
        </>
      </S.ButtonWrapper>
    </S.OccurrenceWrapper>
  );
};

export const OccurrenceCard = (props: Props) => (
  <UploadFilesProvider shouldConcatFiles providerId={props.id}>
    <OccurrenceComponent {...props} />
  </UploadFilesProvider>
);
