import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import withAppContext from 'core/hoc/withAppContext';
import withFormContext from 'core/hoc/withFormContext';
import SurveyActions from 'store/surveys/actions';
import FormContainer from 'components/Form/FormContainer';
import Toast from 'components/Toast';
import PageContainer from 'components/PageContainer';
import SurveyList from 'pages/surveys/SurveyList';
import SurveySearchTitle from 'pages/surveys/SurveySearchTitle';
import SideSheetFilters from 'pages/surveys/SideSheetFilters';
import KindSurveyModal from 'pages/surveys/KindSurveyModal';
import withWindowSize from 'core/hoc/withWindowSize';

import {
  Box,
  FlexGrid,
  FlexGridItem,
  Button,
  SingleDate,
  Select,
  Text,
} from '@agendaedu/ae-web-components';

import * as S from './styles';

const Surveys = ({ windowSize }) => {
  const dispatch = useDispatch();
  const { fetchSurveys, setClassrooms } = SurveyActions;

  const surveysMeta = useSelector((state) => state.surveys.surveysMeta);
  const title = useSelector((state) => state.surveys.title_cont);

  const [openSideSheet, setOpenSideSheet] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [surveyKind, setSurveyKind] = useState('');
  const [showKindModal, setShowKindModal] = useState(false);

  const SURVEYS_KIND = [
    { label: 'Todos os tipos', value: '' },
    { label: 'Autorização', value: 'authorization' },
    { label: 'Enquete', value: 'poll' },
  ].filter((kind) =>
    [
      !kind.value,
      kind.value === surveyKind,
      surveysMeta?.allowed_kinds?.includes(kind.value),
    ].some(Boolean)
  );

  const callFetchSurveys = useCallback(
    (parameters, props) => {
      const queryParams = {
        params: {
          filter: {
            ...parameters,
          },
        },
        ...props,
      };
      dispatch(fetchSurveys(queryParams));
    },
    [dispatch, fetchSurveys]
  );

  useEffect(() => {
    callFetchSurveys({ title, startDate, endDate, kind: surveyKind });
  }, [callFetchSurveys, endDate, startDate, surveyKind, title]);

  const fetchClassrooms = useCallback(() => {
    dispatch(setClassrooms());
  }, [dispatch, setClassrooms]);

  useEffect(() => {
    fetchClassrooms();
  }, [dispatch, setClassrooms]);

  const sideSheetToggle = () => setOpenSideSheet(!openSideSheet);

  const centeredBox = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  };

  return (
    <S.PageWrapper>
      <PageContainer
        className="Surveys"
        header={
          <Box
            flex={1}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb="md"
          >
            <Text
              variant="headline-h2-bold-24"
              fontWeight="bold"
              color="neutral.black"
            >
              Formulários
            </Text>
            {surveysMeta?.can_create && (
              <Button onClick={() => setShowKindModal(true)}>
                {windowSize >= 768 ? 'Novo formulário' : 'Novo'}
              </Button>
            )}
          </Box>
        }
      >
        <FormContainer variation="horizontal">
          <FlexGrid>
            <FlexGridItem cols={8}>
              <Box
                display={{ tabletMD: 'flex' }}
                flexWrap={{ tabletMD: 'wrap', desktopXL: 'nowrap' }}
              >
                <SurveySearchTitle callFetchSurveys={callFetchSurveys} />

                <SingleDate
                  id="start-date"
                  value={!!startDate && moment(startDate)}
                  handleChange={setStartDate}
                  fullWidth
                  placeholder={'Data inicial'}
                  handleOutsideRange={() => false}
                  mx={{ desktopXL: 'md' }}
                  mb="sm"
                />

                <SingleDate
                  id="end-date"
                  value={!!endDate && moment(endDate)}
                  handleChange={setEndDate}
                  fullWidth
                  placeholder={'Data final'}
                  handleOutsideRange={() => false}
                  mr={{ desktopXL: 'md' }}
                  mb="sm"
                />

                <Select
                  fullWidth
                  className="ae-select"
                  options={SURVEYS_KIND}
                  value={surveyKind}
                  defaultValue=""
                  onChange={(e) => setSurveyKind(e.value)}
                />
              </Box>
            </FlexGridItem>

            <FlexGridItem cols={4}>
              <Box marginLeft="auto" width="fit-content">
                <Button
                  variant="secondary"
                  onClick={sideSheetToggle}
                  isOnlyIcon
                  icon="filter"
                />

                <SideSheetFilters
                  isOpen={openSideSheet}
                  onDismiss={sideSheetToggle}
                  title="Filtros"
                  callFetchSurveys={callFetchSurveys}
                />
              </Box>
            </FlexGridItem>
          </FlexGrid>
        </FormContainer>

        <Box
          {...centeredBox}
          justifyContent="space-between"
          margin="16px 0 8px auto"
        >
          <div className="totalSurveys">
            {!!surveysMeta?.count && (
              <span>
                {surveysMeta.count === 1
                  ? `${surveysMeta?.count} formulário`
                  : `${surveysMeta?.count} formulários`}
              </span>
            )}
          </div>
        </Box>

        <SurveyList callFetchSurveys={callFetchSurveys} />
        <Toast />
      </PageContainer>

      <KindSurveyModal
        isOpen={showKindModal}
        onClose={() => setShowKindModal(false)}
      />
    </S.PageWrapper>
  );
};

Surveys.propTypes = {
  windowSize: PropTypes.number.isRequired,
};

export default withAppContext(withFormContext(withWindowSize(Surveys)));
