import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';

import composeFunctions from 'core/utils/composeFunctions';
import reduxProvider from 'core/hoc/reduxProvider';
import withAppContext from 'core/hoc/withAppContext';

import StorageFolderProvider from 'core/contexts/Storage/Folder';

import StorageList from 'pages/storage/StorageList';
import FolderForm from 'pages/storage/Forms/Folder';

import { StorageRoutesProps } from './types';

const StorageRoutes = ({ appContext: { dataArea } }: StorageRoutesProps) => {
  useEffect(() => {
    const mainContent = document.getElementById('main-content');

    if (mainContent) {
      mainContent.style.minHeight = '100vh';
      mainContent.style.backgroundColor = '#ffffff';
    }
  }, []);

  return (
    <div id="StorageRoutes">
      <CookiesProvider>
        <Switch>
          <Route exact path={`/${dataArea}/storage`} component={StorageList} />
          <Route
            exact
            path={`/${dataArea}/storage/folders/new`}
            component={() => (
              <StorageFolderProvider>
                <FolderForm />
              </StorageFolderProvider>
            )}
          />
        </Switch>
      </CookiesProvider>
    </div>
  );
};

export default composeFunctions(withAppContext, reduxProvider)(StorageRoutes);
