import createModuleReducer from '../../storeConfig/createModuleReducer';
import actions from './actions';

export const INITIAL_STATE = {
  classrooms: [],
  dataOffers: { data: [], included: [] },
  filters: {
    status: null,
    title: null,
  },
  current: null,
  totalItemsCount: null,
  isLoading: false,
  isLoadingClassrooms: false,
  sending: false,
  error: null,
  classroomsSelectOptions: null,
  classroomsStudents: null,
  meta: null,
};

export const fetchRequestOffers = (state) => ({
  ...state,
  isLoading: true,
});

export const fetchSuccessOffers = (state, action) => ({
  ...state,
  dataOffers: action.data,
  totalItemsCount: action.totalItemsCount,
  filters: action.filters,
  isLoading: false,
});

export const fetchClassroomsSelectOptions = (state) => ({
  ...state,
  isLoading: true,
});

export const fetchClassroomsSelectOptionsSuccess = (state, action) => ({
  ...state,
  isLoading: false,
  classroomsSelectOptions: action.selectOptions,
});

export const fetchRequestOfferDetails = (state) => ({
  ...state,
  isLoading: true,
});

export const fetchSuccessOfferDetails = (state, action) => ({
  ...state,
  current: action.data,
  isLoading: false,
});

const setClassroomsRequest = (state) => ({
  ...state,
  classrooms: [],
  isLoadingClassrooms: true,
});

const setClassroomsSuccess = (state, action) => {
  const classrooms = action.classrooms;

  return {
    ...state,
    classrooms,
    isLoadingClassrooms: false,
  };
};

export const fetchSuccessClassRoomsStudents = (state, action) => ({
  ...state,
  classroomsStudents: action.classroomsStudents,
});

export const addOfferRequest = (state) => ({
  ...state,
  sending: true,
});

export const addOfferSuccess = (state) => ({
  ...state,
  sending: false,
  dataOffers: [],
  totalItemsCount: undefined,
  current: null,
});

export const editOfferRequest = (state) => ({
  ...state,
  sending: true,
});

export const editOfferSuccess = (state) => ({
  ...state,
  sending: false,
  dataOffers: [],
  totalItemsCount: undefined,
  current: null,
});

export const fetchOfferEditRequest = (state) => ({
  ...state,
  isLoading: true,
  current: null
});

export const fetchOfferEditSuccess = (state, action) => ({
  ...state,
  isLoading: false,
  current: action.data,
  meta: action.meta,
});

export const fetchError = (state, action) => ({
  ...state,
  error: action.error,
  isLoading: false,
  sending: false,
});

const HANDLERS = {
  [actions.FETCH_REQUEST_OFFERS]: fetchRequestOffers,
  [actions.FETCH_SUCCESS_OFFERS]: fetchSuccessOffers,
  [actions.FETCH_REQUEST_CLASSROMS_SELECT_OPTIONS]:
    fetchClassroomsSelectOptions,
  [actions.ADD_OFFER_REQUEST]: addOfferRequest,
  [actions.ADD_OFFER_SUCCESS]: addOfferSuccess,
  [actions.FETCH_SUCCESS_CLASSROMS_SELECT_OPTIONS]:
    fetchClassroomsSelectOptionsSuccess,
  [actions.FETCH_SUCCESS_CLASSROOMS_STUDENTS]: fetchSuccessClassRoomsStudents,
  [actions.FETCH_REQUEST_OFFER_DETAILS]: fetchRequestOfferDetails,
  [actions.FETCH_SUCCESS_OFFER_DETAILS]: fetchSuccessOfferDetails,
  [actions.FETCH_REQUEST_OFFER_EDIT]: fetchOfferEditRequest,
  [actions.FETCH_SUCCESS_OFFER_EDIT]: fetchOfferEditSuccess,
  [actions.SET_CLASSROOMS_REQUEST]: setClassroomsRequest,
  [actions.SET_CLASSROOMS_SUCCESS]: setClassroomsSuccess,
  [actions.FETCH_ERROR]: fetchError,
};

const shop = createModuleReducer(INITIAL_STATE, HANDLERS);

export default shop;
