import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';
import { StylesProps } from './types';

const emptyStateStyles = ({ theme: { colors, typography } }: ThemeProps) => css`
  width: 192px;

  margin: 0px;
  ${typography.subtitle.Medium16};

  color: ${colors.neutral.gray1};

  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    max-width: fit-content;
    margin-bottom: 24px;
  }
`;

export const GroupWrapper = styled.div<StylesProps>`
  ${({ theme, currentGroup }) => css`
    height: calc(100vh - 80px);
    margin: -16px -16px -90px;

    display: flex;

    ${GroupContainer} {
      display: ${currentGroup && 'none'};
    }

    ${MessageContainer} {
      display: ${currentGroup && 'flex'};
    }

    .EmptyState {
      ${emptyStateStyles({ theme })};
    }

    @media screen and (min-width: 992px) {
      ${GroupContainer} {
        display: flex;
      }

      ${MessageContainer} {
        display: flex;
      }
    }
  `}
`;

export const GroupContainer = styled.div`
  ${({ theme: { border, colors } }: ThemeProps) => css`
    width: 100%;
    height: auto;
    border-right: ${border.width.sm} ${border.style.solid}
      ${colors.neutral.gray4};
    background: ${colors.neutral.white};
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 992px) {
      max-width: 366px;
    }
  `}
`;

export const GroupList = styled.ul<Omit<StylesProps, 'currentGroup'>>`
  ${({ theme: { border, colors, gray4 }, empty }) => css`
    height: 100%;
    padding: 0px;
    margin: 0px;

    display: flex;
    align-items: ${empty ? 'center' : 'initial'};
    justify-content: ${empty ? 'center' : 'flex-start'};
    flex-direction: column;

    overflow-y: auto;

    &::-webkit-scrollbar {
      background-color: transparent;
      width: 6px;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    &::-webkit-scrollbar-track {
      background-color: ${colors.neutral.gray5};
    }

    &::-webkit-scrollbar-thumb {
      border-radius: ${border.radius.sm};
      border: ${border.width.sm} ${border.style.solid} ${gray4};
    }
  `}
`;

export const MessageContainer = styled.div`
  width: 100%;
  height: 100%;

  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
