import React, {
  useEffect,
  useCallback,
  useMemo,
  lazy,
  Suspense,
  useState,
} from 'react';
import actionEnrollmentPlans from 'store/edupay/enrollmentPlans/actions';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Toast from 'components/Toast';
import Loader from 'components/Loader';
import { SaveConfirmatioModal } from '../SaveConfirmationModal';
import {
  ALLOWED_PAYMENT_METHOD,
  ALLOWED_PAYMENT_METHOD_DEMONSTRATION_SCHOOL,
} from 'core/constants';
import { formattedDiscounts, buildDiscount } from 'core/utils/edupay/functions';
import {
  DISCOUNT_KINDS,
  INSTALLMENTS_SCHOOLPRODUCT,
  ENROLLMENT_FORM_TYPES,
} from 'core/constants/index';

const NewEnrollmentPlan = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['enrollments']);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [latestEnrollmentForm, setLatestEnrollmentForm] = useState({});

  const {
    addEnrollmentPlanRequest,
    addMassEnrollmentPlanRequest,
    setClassrooms,
    setActiveContractModels,
  } = actionEnrollmentPlans;

  const { creditCardTax, billetTax, pixTax, policies } = useSelector(
    (state) => state.root
  );

  const { sending, isLoading, recipients, classrooms, contractModels } =
    useSelector((state) => state.enrollmentPlans);

  const setClassroomsEnrollment = useCallback(() => {
    dispatch(setActiveContractModels());
    dispatch(setClassrooms());
  }, [dispatch, setClassrooms, setActiveContractModels]);

  useEffect(() => {
    setClassroomsEnrollment();
  }, [setClassroomsEnrollment]);

  const createEnrollmentPlan = useCallback(
    (params) => {
      dispatch(addEnrollmentPlanRequest(params));
    },
    [dispatch, addEnrollmentPlanRequest]
  );

  const createMassEnrollmentPlan = useCallback(
    (params) => {
      dispatch(addMassEnrollmentPlanRequest(params));
    },
    [dispatch, addEnrollmentPlanRequest]
  );

  const handleCreateEnrollment = (formContext) => {
    const { form } = formContext;

    const responsibleProfiles = form.selectedRecipients.map(
      (recipient) => recipient.id
    );

    createEnrollmentPlan({
      title: form.title,
      description: form.description,
      price: form.price,
      expires_at: form.expires_at,
      max_installments: form.max_installments,
      allowed_payment_method: form.allowed_payment_method,
      edupay_discounts: formattedDiscounts(form),
      attachments: [form.signedId],
      classroom_ids: [form.selectedClassroom],
      classroom_with_student_profile_ids: {
        [form.selectedClassroom]: [form.selectedStudent],
      },
      school_products_responsible_profile_ids: responsibleProfiles,
      recipient_wallet_id: form.recipient_wallet_id,
      type: 'single',
      allow_credit_card_discount: form.allow_credit_card_discount,
      settings: { absorb_tax: form?.absorb_tax },
    });
  };

  const handleOnSubmit = (formContext) => {
    const { form } = formContext;

    if (form.formType === 'single') {
      handleCreateEnrollment(formContext);
      return;
    }

    setIsConfirmationModalOpen(true);
    setLatestEnrollmentForm(form);
  };

  const handleSave = () => {
    createMassEnrollmentPlan({
      title: latestEnrollmentForm.title,
      description: latestEnrollmentForm.description,
      price: latestEnrollmentForm.price,
      expires_at: latestEnrollmentForm.expires_at,
      max_installments: latestEnrollmentForm.max_installments,
      allowed_payment_method: latestEnrollmentForm.allowed_payment_method,
      edupay_discounts: formattedDiscounts(latestEnrollmentForm),
      classroom_ids: [latestEnrollmentForm.selectedClassroom],
      recipient_wallet_id: latestEnrollmentForm.recipient_wallet_id,
      classroom_with_student_and_responsible_profile_ids: {
        [latestEnrollmentForm.selectedClassroom]:
          latestEnrollmentForm.selectedStudentResponsibleIds,
      },
      contract_template_id: latestEnrollmentForm.contractModelId,
      settings: { absorb_tax: latestEnrollmentForm?.absorb_tax },
    });
  };

  const FormFullScreen = useMemo(
    () =>
      lazy(() => import('components/Payments/Enrollment/EnrollmentPlanForm')),
    [classrooms, contractModels]
  );

  const form = {
    recipients,
    selectedClassroom: null,
    classroom_with_student_profile_ids: {},
    allowed_payment_method:
      policies?.school_plan === 'trial'
        ? ALLOWED_PAYMENT_METHOD_DEMONSTRATION_SCHOOL
        : ALLOWED_PAYMENT_METHOD,
    price: 0,
    absorb_credit_card_tax: true,
    absorb_billet_tax: true,
    absorb_pix_tax: true,
    absorb_installment_tax: true,
    max_installments: 1,
    toggle_discount: false,
    edupay_discounts: [buildDiscount()],
    allow_credit_card_discount: true,
    selectedStudent: null,
    selectedRecipients: [],
    recipient_wallet_id: null,
    formType: 'single',
    contractModelId: null,
    absorb_tax: false,
  };

  const formMeta = {
    tax: creditCardTax,
    billet_tax: billetTax,
    pix_tax: pixTax,
    status: 'pending',
    select_options: {
      max_installments: INSTALLMENTS_SCHOOLPRODUCT,
      kind: DISCOUNT_KINDS,
      formTypes: ENROLLMENT_FORM_TYPES,
    },
    contractModels,
    classrooms,
  };

  return (
    <div className="NewEnrollmentPlan">
      <Loader isLoading={isLoading}>
        <Suspense fallback={null}>
          <FormFullScreen
            form={form}
            formMeta={formMeta}
            onSubmit={handleOnSubmit}
            isSubmitting={sending}
            action="new"
            backTo="/schools/school_products/tab/enrollment"
            titlePage={t('form.enrollment.new_enrollment')}
            titleModal={t('form.enrollment.discard_modal.title')}
            contentModal={t('form.enrollment.discard_modal.info')}
          />
        </Suspense>
        <Toast />
      </Loader>
      {isConfirmationModalOpen && (
        <SaveConfirmatioModal
          isOpen={isConfirmationModalOpen}
          handleClose={() => setIsConfirmationModalOpen(false)}
          handleSave={handleSave}
          sending={sending}
        />
      )}
    </div>
  );
};

export default NewEnrollmentPlan;
