import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MESSAGE_CHAT_TABS, TICKET_CHAT_TABS } from 'core/constants';

import omniChannelActions from 'store/messages/omniChannel/actions';
import { OmniChannelState } from 'store/messages/omniChannel/types';

import * as S from './styles';

import { ChatList } from '../ChatList';
import Tabs from '../../Tabs';

export const ChatTabs = (): JSX.Element => {
  const dispatch = useDispatch();

  const {
    fetchChatPendingCountRequest,
    fetchTicketPendingCountRequest,
    fetchChatsRequest,
    fetchTicketsRequest,
    setChatCurrentTab,
  } = omniChannelActions;

  const { activeChannel, chatCurrentTab, chatFilters, chatPendingCount } =
    useSelector((state: OmniChannelState) => state.omniChannel);

  const handleOnChangeTab = (tab: number) => dispatch(setChatCurrentTab(tab));

  const channelType = activeChannel?.kind;

  const fetchChats = useCallback(() => {
    const params = {
      channelId: activeChannel.id,
      chatStatus: MESSAGE_CHAT_TABS[chatCurrentTab]?.tabName,
      filters: chatFilters,
      page: 1,
    };

    dispatch(fetchChatsRequest(params));
  }, [dispatch, fetchChatsRequest, activeChannel, chatFilters, chatCurrentTab]);

  const fetchTickets = useCallback(() => {
    const params = {
      channelId: activeChannel.id,
      status: TICKET_CHAT_TABS[chatCurrentTab]?.tabName,
      page: 1,
    };

    dispatch(fetchTicketsRequest(params));
  }, [activeChannel, chatCurrentTab, dispatch, fetchTicketsRequest]);

  const fetchChatsPendingCount = useCallback(() => {
    const params = {
      channelId: activeChannel?.id,
      filters: chatFilters,
    };

    dispatch(fetchChatPendingCountRequest(params));
  }, [dispatch, fetchChatPendingCountRequest, activeChannel, chatFilters]);

  const fetchTicketsPendingCount = useCallback(() => {
    const params = {
      status: null,
      channelId: activeChannel?.id,
    };
    dispatch(fetchTicketPendingCountRequest(params));
  }, [activeChannel?.id, dispatch, fetchTicketPendingCountRequest]);

  const currentTabs = {
    family: MESSAGE_CHAT_TABS,
    private: MESSAGE_CHAT_TABS,
    ticket: TICKET_CHAT_TABS,
  };

  const initialFetch = {
    family: fetchChats,
    private: fetchChats,
    ticket: fetchTickets,
  };

  const pendingCountFetch = {
    family: fetchChatsPendingCount,
    private: fetchChatsPendingCount,
    ticket: fetchTicketsPendingCount,
  };

  useEffect(() => {
    if (activeChannel) {
      pendingCountFetch[channelType]();
      initialFetch[channelType]();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChannel, channelType, chatCurrentTab, chatFilters]);

  return (
    <S.Wrapper>
      <Tabs
        badges={chatPendingCount}
        currentTab={chatCurrentTab}
        onChangeTab={handleOnChangeTab}
        tabs={currentTabs[channelType]}
      />
      <ChatList />
    </S.Wrapper>
  );
};
