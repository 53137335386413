import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import reduxProvider from 'core/hoc/reduxProvider';

import DiarySectionProvider from 'core/contexts/DiarySections';
import DiaryFormProvider from 'core/contexts/DiaryForm';

import DailySummariesPage from 'pages/dailySummaries';
import DiaryForm from 'pages/dailySummaries/DiaryForm';
import DiarySectionsV3 from 'pages/diarySectionsV3/Sections';
import SectionForm from 'pages/diarySectionsV3/SectionForm';
import DiaryDetails from 'pages/dailySummaries/DiaryDetails';
import DiaryReport from 'pages/dailySummaries/DiaryReport';

const DailySummariesRoutes = () => {
  useEffect(() => {
    const mainContent = document.getElementById('main-content');

    if (mainContent) {
      mainContent.style.backgroundColor = '#ffffff';
    }
  }, []);

  return (
    <div className="DailySummariesRoutes">
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path="/*/daily_summaries/classrooms/:classroom_id"
            render={(routerProps) => (
              <DiaryFormProvider
                classroomId={routerProps.match.params.classroom_id}
              >
                <DiaryForm
                  classroomId={routerProps.match.params.classroom_id}
                />
              </DiaryFormProvider>
            )}
          />
          <Route
            exact
            path="/*/diary_sections"
            render={() => <DiarySectionsV3 />}
          />
          <Route
            exact
            path="/*/diary_sections/:id/edit"
            render={() => (
              <DiarySectionProvider>
                <SectionForm />
              </DiarySectionProvider>
            )}
          />
          <Route
            exact
            path="/*/diary_sections/new"
            render={() => (
              <DiarySectionProvider>
                <SectionForm />
              </DiarySectionProvider>
            )}
          />
          <Route
            exact
            path="/*/daily_summaries"
            render={() => <DailySummariesPage />}
          />
          <Route
            exact
            path="/*/daily_summaries/classrooms/:classroom_id/details"
            render={(routerProps) => (
              <DiaryDetails
                classroomId={routerProps.match.params.classroom_id}
              />
            )}
          />
          <Route
            exact
            path="/*/daily_summaries/report"
            render={() => <DiaryReport />}
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default reduxProvider(DailySummariesRoutes);
