import styled from 'styled-components';
import FormFieldset from 'components/Form/Fieldset';

export const GroupInformationStepWrapper = styled(FormFieldset)``;

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;

  .Title {
    padding: 0 15px;
  }

  .TextField {
    display: flex;
    flex-direction: column;

    input {
      height: 40px;
      border-radius: 6px;
      font-size: 16px;
    }
  }
`;
