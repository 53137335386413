import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NewGroupFormProps } from './types';
import * as S from './styles';

import FormFullScreen from 'components/FormFullScreen';
import Loader from 'components/Loader';
import Toast from 'components/Toast';

import withAppContext from 'core/hoc/withAppContext';

import GroupInformationStep from 'components/Messages/Forms/Steps/GroupInformationStep';
import GroupMembersStep from 'components/Messages/Forms/Steps/GroupMembersStep';

import actionMessages from 'store/messages/actions';
import { MessageState } from 'store/messages/types';

const WIZARD_STEPS = [GroupInformationStep, GroupMembersStep];

const NewGroupForm = ({ appContext: { dataArea } }: NewGroupFormProps) => {
  const dispatch = useDispatch();

  const { isLoading, isSending } = useSelector(
    (state: MessageState) => state.messages
  );

  const { createNewGroupRequest } = actionMessages;

  const handlerCreateNewGroup = useCallback(
    (params) => {
      dispatch(createNewGroupRequest(params));
    },
    [dispatch, createNewGroupRequest]
  );

  const handleSubmit = (formContext) => {
    const { form } = formContext;
    handlerCreateNewGroup(form);
  };

  return (
    <S.NewGroupFormsWrapper>
      <Loader isLoading={isLoading}>
        <FormFullScreen
          id="message-group-create"
          form={{
            name: null,
            description: null,
            member_ids: [],
          }}
          formMeta={{ nextStepDisabled: true }}
          action="new"
          onSubmit={handleSubmit}
          isSubmitting={isSending}
          steps={WIZARD_STEPS}
          backTo={`/${dataArea}/messages/groups`}
          titlePage="Novo Grupo"
          titleModal="Descartar preenchimento"
          contentModal="O cadastro do grupo será cancelado e as informações preenchidas serão descartadas. Tem certeza que deseja descartar?"
        />
        <Toast />
      </Loader>
    </S.NewGroupFormsWrapper>
  );
};

export default withAppContext(NewGroupForm);
