import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Box, FullScreenModal, Stepper } from '@agendaedu/ae-web-components';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import withAppContext from 'core/hoc/withAppContext';

import useWindowDimensions from 'core/hooks/useWindowDimensions';
import { isMobile } from 'core/utils/viewport';
import { StorageFolderContext } from 'core/contexts/Storage/Folder';
import StorageActions from 'store/storage/actions';

import Toast from 'components/Toast';
import InfoStep from './components/InfoStep';
import { Props } from './types';
import RecipientsStep from './components/RecipientsStep';
import { useDispatch } from 'react-redux';

const FolderForm = ({ history: { goBack } }: Props) => {
  const { fetchRecipientsClassrooms } = StorageActions;
  const { t } = useTranslation(['storage', 'common']);
  const dispatch = useDispatch();

  const { screenWidth } = useWindowDimensions();

  const { currentStep, setCurrentStep, isInfoStepValid } =
    useContext(StorageFolderContext);

  const [isMobileScreen, setIsMobile] = useState(isMobile(screenWidth));

  const tBase = useCallback((key: string) => t(`folder_form.${key}`), [t]);

  const stepInfos = useMemo(
    () => [
      {
        step: 1,
        title: tBase('info_step.title'),
        isActive: currentStep === 1,
        isDisabled: false,
      },
      {
        title: tBase('recipients_step.title'),
        isActive: currentStep === 2,
        isDisabled: !isInfoStepValid,
        step: 2,
      },
    ],
    [currentStep, isInfoStepValid, tBase]
  );

  const getHeaderContent = useMemo(
    () => (
      <Box
        display="flex"
        justifyContent="center"
        position="absolute"
        left={0}
        right={0}
        width="min-content"
        mx="auto"
        gap="sm"
      >
        {stepInfos.map((item, index) => (
          <Stepper
            key={index}
            isMobile={isMobileScreen}
            onClick={() => setCurrentStep(item.step)}
            {...item}
          />
        ))}
      </Box>
    ),
    [isMobileScreen, stepInfos]
  );

  const getStepContent = {
    1: <InfoStep />,
    2: <RecipientsStep />,
  }[currentStep];

  useEffect(() => {
    if (isMobileScreen !== isMobile(screenWidth)) {
      setIsMobile(isMobile(screenWidth));
    }
  }, [isMobileScreen, screenWidth]);

  useEffect(() => {
    dispatch(fetchRecipientsClassrooms());
  }, [dispatch, fetchRecipientsClassrooms]);

  return (
    <>
      <FullScreenModal
        isOpen
        title={tBase('title')}
        headerContent={getHeaderContent}
        onClose={goBack}
      >
        <Box data-testid="content" display="flex" flex={1} overflow="hidden">
          {getStepContent}
        </Box>
      </FullScreenModal>

      <Toast />
    </>
  );
};

export default withAppContext(withRouter(FolderForm));
