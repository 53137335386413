import validatePresence from 'core/lib/FormValidator/validators/presence';
import validateLength from 'core/lib/FormValidator/validators/length';
import validateTextAreaPresence from 'core/lib/FormValidator/validators/textAreaPresence';
import validateNumericalFloat from 'core/lib/FormValidator/validators/numericalFloat';

export default [
  {
    attributeName: 'recipient_wallet_id',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'title',
    validator: validateLength({ min: 1, max: 50 }),
    message: 'Campo obrigatório. Máximo de caracteres: 50',
  },
  {
    attributeName: 'image',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'description',
    validator: validateTextAreaPresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'max_quantity_per_order',
    validator: validateNumericalFloat({ min: 1 }),
    message: 'Mínimo: 1',
  },
];
