import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './styles';

import {
  Box,
  Button,
  Checkbox,
  Select,
  Text,
} from '@agendaedu/ae-web-components';
import { Props } from './types';

export const EvacuationCard = ({
  evacuations,
  onChange,
}: Props): React.ReactElement => {
  const { t } = useTranslation(['diary_sections']);
  const [evacuationsValues, setEvacuationsValues] = useState(evacuations);

  const OPTIONS = [
    {
      key: 'solid',
      value: 'solid',
      label: t('sections.card.evacuation.options.solid'),
    },
    {
      key: 'liquid',
      value: 'liquid',
      label: t('sections.card.evacuation.options.liquid'),
    },
    {
      key: 'pasty',
      value: 'pasty',
      label: t('sections.card.evacuation.options.pasty'),
    },
  ];

  const handleNotEvacuated = () =>
    setEvacuationsValues((prev) => ({
      ...prev,
      not_evacuated: !prev.not_evacuated,
    }));

  const addQuantity = () => {
    setEvacuationsValues((prev) => ({
      ...prev,
      appearances: [...prev.appearances, { evacuation: '' }],
    }));
  };

  const handleEvacuation = (value: string, index: number) => {
    const newEvacuations = structuredClone(evacuationsValues);

    newEvacuations.appearances[index].evacuation = value;

    setEvacuationsValues(newEvacuations);
  };

  const removeEvacuation = (index: number) => {
    const newEvacuations = structuredClone(evacuationsValues);

    newEvacuations.appearances.splice(index, 1);

    setEvacuationsValues(newEvacuations);
  };

  useEffect(() => {
    onChange(evacuationsValues);
  }, [evacuationsValues]);

  useEffect(() => {
    setEvacuationsValues(evacuations);
  }, [evacuations]);

  return (
    <S.EvacuationWrapper data-testid="evacuation-card">
      <S.RowWrapper>
        <Text variant="title-bold-16" color="neutral.black" marginBottom="0">
          {t('sections.card.evacuation.title')}
        </Text>

        <Checkbox
          data-testid="not-evacuated-checkbox"
          checked={evacuationsValues.not_evacuated}
          onChange={handleNotEvacuated}
        >
          <Text variant="label-regular-16" marginBottom="0">
            {t('sections.card.evacuation.checkbox_label')}
          </Text>
        </Checkbox>
      </S.RowWrapper>

      {!evacuationsValues.not_evacuated && (
        <>
          <S.ContentBox>
            {evacuationsValues.appearances.map(({ evacuation }, index) => (
              <Box key={index} maxWidth="250px" display="flex" width="100%">
                <Select
                  fullWidth
                  label={t('sections.card.evacuation.appearance')}
                  placeholder={t(
                    'sections.card.evacuation.appearance_placeholder'
                  )}
                  options={OPTIONS}
                  value={evacuation}
                  onChange={(option) => handleEvacuation(option.value, index)}
                />

                {evacuationsValues.appearances.length > 1 && (
                  <Button
                    isOnlyIcon
                    ml="xs3"
                    variant="secondary"
                    mt="auto"
                    contextVariant="alert"
                    icon="trash-bin"
                    onClick={() => removeEvacuation(index)}
                  />
                )}
              </Box>
            ))}
          </S.ContentBox>

          <Button
            isOnlyIcon
            variant="secondary"
            icon="plus"
            onClick={addQuantity}
          />
        </>
      )}
    </S.EvacuationWrapper>
  );
};
