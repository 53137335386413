import React from 'react';

import OutlineBox from 'components/OutlineBox';
import Button from 'components/Button';
import AgendaIcon from 'components/AgendaIcon';
import { Link } from 'react-router-dom';

export default function table(onToggleClick, canEditActive) {
  return [
    {
      Header: 'Períodos letivos',
      accessor: schoolTerm => `${schoolTerm.attributes.name}`
    },
    {
      Header: 'Status',
      accessor: (schoolTerm) => <div className="table-status">
        {schoolTerm.attributes.can_be_activated ? (
          < Button
            type='button'
            size="btn-xs"
            variation="success"
            onClick={() => onToggleClick(schoolTerm)}
          >
            Ativar
          </Button>
        ) : (
            <OutlineBox
              variation={schoolTerm.attributes.status ? 'default' : 'dimming'}
            >
              {schoolTerm.attributes.status ? 'Ativo' : 'Inativo'}
            </OutlineBox>
          )}
      </div>
    },
    {
      Header: '',
      accessor: (schoolTerm) => <div className="table-status">
        {(schoolTerm.attributes.can_be_activated || (schoolTerm.attributes.status && canEditActive)) &&
          <Link to={`/schools/school_terms/${schoolTerm.id}/edit`} >
            <div className="icon-holder">
              <AgendaIcon name="edit" size="small" />
            </div>
          </Link>
        }
      </div>
    }
  ];
}
