import React, { memo, useCallback } from 'react';
import Linkify from 'react-linkify';
import { useDispatch } from 'react-redux';

import ActionButton from 'components/Messages/TS/Animations/ActionButton';
import Avatar from 'components/Avatar';

import { formatDatesCalendar } from 'core/utils/date';
import messagesActions from 'store/messages/actions';

import { AvatarAttributesProps, MessageBalloonProps } from './types';

import * as S from './styles';

const MessageBalloon = ({ message }: MessageBalloonProps) => {
  const dispatch = useDispatch();

  const {
    type,
    content,
    created_at,
    sender: {
      attributes: { name, name_initials, avatar_color, avatar_url, role },
    },
    updated,
  } = message.attributes;

  const isMyOwnerMessage = type !== 'received';

  const { setEditMessage } = messagesActions;

  const handleSetEditMessage = useCallback(() => {
    dispatch(setEditMessage(message));
  }, [dispatch, setEditMessage, message]);

  const getAvatarAttributes = ({
    avatarColor,
    avatarUrl,
    nameInitials,
  }: AvatarAttributesProps) => {
    return {
      attributes: {
        avatar_color: avatarColor,
        avatar_url: avatarUrl,
        name_initials: nameInitials,
      },
    };
  };

  const renderAvatar = () =>
    !isMyOwnerMessage && (
      <S.AvatarContainer data-testid="avatar-container">
        <Avatar
          user={getAvatarAttributes({
            avatarColor: avatar_color,
            avatarUrl: avatar_url,
            nameInitials: name_initials,
          })}
        />
      </S.AvatarContainer>
    );

  const renderMessageUser = () =>
    !isMyOwnerMessage && (
      <S.MessageUser data-testid="message-user">{`${name} • ${role}`}</S.MessageUser>
    );

  return (
    <S.WrapperMessage variantType={type} data-testid="message-wrapper">
      {renderAvatar()}

      <S.MessageContainer>
        <S.ContentWrapper>
          {renderMessageUser()}
          <S.MessageContent>
            <Linkify properties={{ target: '_blank' }}>{content}</Linkify>
          </S.MessageContent>
        </S.ContentWrapper>

        <S.ActionsWrapper
          canSubmitAction={isMyOwnerMessage}
          data-testid="actions-wrapper"
        >
          <S.ActionContainer variantType={type}>
            <ActionButton
              name="pencil"
              handleOnClick={handleSetEditMessage}
              testId="edit-action"
            />
          </S.ActionContainer>
        </S.ActionsWrapper>
      </S.MessageContainer>

      <S.InfoContainer>
        {formatDatesCalendar(created_at)} {updated && '• Editada'}
      </S.InfoContainer>
    </S.WrapperMessage>
  );
};

export default memo(MessageBalloon);
