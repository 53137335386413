import styled, { css } from 'styled-components';

import { ThemeProps } from 'config/themes/types';

import { StylesProps } from './types';

const textOverflowStyle = css`
  white-space: nowrap;
  overflow: hidden;

  text-overflow: ellipsis;
`;

export const GroupCardWrapper = styled.li<StylesProps>`
  ${({ theme: { colors }, isSelected }) => css`
    max-height: 98px;
    padding: 18px 22px 18px;

    background: ${isSelected ? `${colors.brand.primary.op10}` : 'initial'};

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 4px;

    border-bottom: 1px solid ${colors.neutral.gray4};

    cursor: pointer;

    &:hover {
      background: ${colors.brand.primary.op10};
    }
  `}
`;

export const GroupCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const GroupCardWrapperTitle = styled.div`
  max-width: 60%;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const GroupCardTitle = styled.h4`
  ${textOverflowStyle};
  ${({ theme: { colors, typography } }: ThemeProps) => css`
    width: 100%;
    margin: 0px;
    ${typography.title.Bold16};

    color: ${colors.neutral.black};
  `}
`;

export const GroupCardTimestamp = styled.span`
  ${({ theme: { colors, typography } }: ThemeProps) => css`
    ${typography.subtitle.Medium12};

    color: ${colors.neutral.gray2};
  `}
`;

export const LastMessagePreview = styled.p`
  ${textOverflowStyle};
  ${({ theme: { colors, typography } }: ThemeProps) => css`
    width: 100%;
    margin: 0px;

    ${typography.body.Regular14};

    color: ${colors.neutral.black};
  `}
`;

type NotificationWrapperProps = {
  hasUnreadMessage: boolean;
  theme: ThemeProps;
};

export const NotificationWrapper = styled.div<NotificationWrapperProps>`
  ${({ theme, hasUnreadMessage }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    ${hasUnreadMessage &&
    css`
      > p {
        width: 80%;
        font-weight: ${theme.font.weight.bold};
      }
    `}
  `}
`;

export const CountWrapper = styled.span`
  ${({ theme: { colors, typography } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 20px;
    height: 20px;
    border-radius: 10px;
    padding: 0 6px;
    text-align: center;
    ${typography.subtitle.Medium12};

    color: ${colors.neutral.white};
    background: ${colors.brand.primary.default};
  `}
`;
