import React, { useEffect, useMemo } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Tag, Tooltip } from '@agendaedu/ae-web-components';
import { useTranslation } from 'react-i18next';

import LabeledContainer from 'components/LabeledContainer';
import FormCheckbox from 'components/Form/Checkbox';
import { NegotiationCard } from '../../NegotiationCard';

import { isRecurrentBillNonNegotiable } from 'core/utils/edupay/functions';
import { NEGOTIATION_STATUS } from 'core/constants';
import { flags } from 'core/constants/flags';
import { addZeroBeforeNumber } from 'core/utils/addZeroBeforeNumber';
import { centsToBRL } from 'core/utils/centsToBrl';
import negotiationsActions from 'store/edupay/negotiations/actions';

import * as S from './styles';
import { NegotiationAccordionHeaderProps } from './types';
import { RootState } from '../types';

const getPercentPaidColor = (percentPaid: number) => {
  if (percentPaid > 0 && percentPaid < 100) return 'info';
  else if (percentPaid === 100) return 'success';
  else return 'default';
};

export const NegotiationAccordionHeader = ({
  negotiated_at,
  bills_quantity,
  total_price_cents,
  status,
  percent_paid,
  negotiationId,
  recurrentBills,
}: NegotiationAccordionHeaderProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['negotiations']);

  const { setSelectedRecurrentBills, setSelectNegotiation } =
    negotiationsActions;

  const {
    policies: { non_payment_active_flags: nonPaymentActiveFlags },
  } = useSelector((state: RootState) => state.root);

  const isCheckboxActive = nonPaymentActiveFlags.includes(
    flags.NEGOTIATION_TAB_CHECKBOX
  );

  const { selectedRecurrentBills, selectedNegotiations } = useSelector(
    (state: RootState) => state.negotiations
  );

  const {
    currentUser: {
      attributes: { is_debug_user: isDebugUser },
    },
  } = useSelector((state: RootState) => state.root);

  const idsEligibleToNegotiate = useMemo(
    () =>
      recurrentBills
        .filter(
          ({ status, is_wallet_guaranteed_monthly_fee }) =>
            !isRecurrentBillNonNegotiable(
              status,
              isDebugUser,
              is_wallet_guaranteed_monthly_fee
            )
        )
        .map(({ id }) => id),
    [recurrentBills, isDebugUser]
  );

  const handleSelectedRecurrentBillsChange = () => {
    const isEveryRecurrentBillSelected = idsEligibleToNegotiate.every((id) =>
      selectedRecurrentBills.includes(id)
    );
    const isNegotiationSelected = selectedNegotiations.includes(negotiationId);

    if (
      (isEveryRecurrentBillSelected && !isNegotiationSelected) ||
      (!isEveryRecurrentBillSelected && isNegotiationSelected)
    ) {
      handleSelectNegotiation();
    }
  };

  useEffect(() => {
    if (idsEligibleToNegotiate?.length) handleSelectedRecurrentBillsChange();
  }, [selectedRecurrentBills.length, idsEligibleToNegotiate]);

  const handleSelectNegotiation = () =>
    dispatch(setSelectNegotiation(negotiationId));

  const handleSelect = () => {
    recurrentBills.forEach(
      ({ id, status, is_wallet_guaranteed_monthly_fee }) => {
        const cantNegotiate = isRecurrentBillNonNegotiable(
          status,
          isDebugUser,
          is_wallet_guaranteed_monthly_fee
        );
        if (!cantNegotiate) dispatch(setSelectedRecurrentBills(id));
      }
    );
  };

  const isDisabled =
    status === 'finished' ||
    status === 'negotiated' ||
    idsEligibleToNegotiate.length === 0
      ? true
      : false;

  return (
    <NegotiationCard key={negotiationId}>
      <S.Container>
        {isCheckboxActive && (
          <S.CheckboxContainer>
            {isDisabled ? (
              <Tooltip
                align="left"
                content={
                  idsEligibleToNegotiate.length == 0
                    ? t('tooltips.unnegotiable.bills')
                    : t('tooltips.unnegotiable.status')
                }
                elementRef={
                  <FormCheckbox
                    checked={selectedNegotiations.includes(negotiationId)}
                    onChange={handleSelect}
                    disabled={isDisabled}
                  />
                }
              />
            ) : (
              <FormCheckbox
                checked={selectedNegotiations.includes(negotiationId)}
                onChange={handleSelect}
                disabled={isDisabled}
              />
            )}
          </S.CheckboxContainer>
        )}
        <LabeledContainer title="ID">
          <p>{negotiationId}</p>
        </LabeledContainer>
        <LabeledContainer title="Data da negociação">
          <p>{moment(negotiated_at).format('DD/MM/yyyy')}</p>
        </LabeledContainer>
        <LabeledContainer title="Cobranças">
          <p>{addZeroBeforeNumber(bills_quantity)}</p>
        </LabeledContainer>
        <LabeledContainer title="Valor Total">
          <p>{centsToBRL(total_price_cents)}</p>
        </LabeledContainer>
        <LabeledContainer title="Percentual pagas">
          <S.ColoredText
            textColor={
              status !== 'negotiated'
                ? getPercentPaidColor(percent_paid)
                : 'alert'
            }
          >
            {`${percent_paid}%`}
          </S.ColoredText>
        </LabeledContainer>
        <LabeledContainer title="">
          <Tag
            name={NEGOTIATION_STATUS[status]?.name}
            size="small"
            variant={NEGOTIATION_STATUS[status]?.variant}
          />
        </LabeledContainer>
      </S.Container>
    </NegotiationCard>
  );
};
