const actions = {
  ERROR: 'ERROR/schoolProducts',
  FETCH_SCHOOLPRODUCTS_LIST_REQUEST:
    'FETCH_SCHOOLPRODUCTS_LIST_REQUEST/schoolProducts',
  FETCH_SCHOOLPRODUCTS_LIST_SUCCESS:
    'FETCH_SCHOOLPRODUCTS_LIST_SUCCESS/schoolProducts',
  FETCH_SCHOOLPRODUCT_REQUEST: 'FETCH_SCHOOLPRODUCT_REQUEST/schoolProducts',
  FETCH_SCHOOLPRODUCT_SUCCESS: 'FETCH_SCHOOLPRODUCT_SUCCESS/schoolProducts',
  FETCH_ORDERS_REQUEST: 'FETCH_ORDERS_REQUEST/schoolProducts',
  FETCH_ORDERS_SUCCESS: 'FETCH_ORDERS_SUCCESS/schoolProducts',
  FETCH_BALANCE_REQUEST: 'FETCH_BALANCE_REQUEST/schoolProducts',
  FETCH_BALANCE_SUCCESS: 'FETCH_BALANCE_SUCCESS/schoolProducts',
  FETCH_HISTORY_REQUEST: 'FETCH_HISTORY_REQUEST/schoolProducts',
  FETCH_HISTORY_SUCCESS: 'FETCH_HISTORY_SUCCESS/schoolProducts',
  REJECT_SCHOOL_PRODUCT: 'REJECT_SCHOOL_PRODUCT/schoolProducts',
  FETCH_CLASSROOMS_REQUEST: 'FETCH_CLASSROOMS_REQUEST/schoolProducts',
  FETCH_CLASSROOMS_SUCCESS: 'FETCH_CLASSROOMS_SUCCESS/schoolProducts',
  FETCH_CLASSROOMS_STUDENTS_REQUEST:
    'FETCH_CLASSROOMS_STUDENTS_REQUEST/schoolProducts',
  FETCH_CLASSROOMS_STUDENTS_SUCCESS:
    'FETCH_CLASSROOMS_STUDENTS_SUCCESS/schoolProducts',
  FETCH_CLONE_SCHOOL_PRODUCT_REQUEST:
    'FETCH_CLONE_SCHOOL_PRODUCT_REQUEST/schoolProducts',
  FETCH_CLONE_SCHOOL_PRODUCT_SUCCESS:
    'FETCH_CLONE_SCHOOL_PRODUCT_SUCCESS/schoolProducts',
  FETCH_EDIT_SCHOOL_PRODUCT_REQUEST:
    'FETCH_EDIT_SCHOOL_PRODUCT_REQUEST/schoolProducts',
  FETCH_EDIT_SCHOOL_PRODUCT_SUCCESS:
    'FETCH_EDIT_SCHOOL_PRODUCT_SUCCESS/schoolProducts',
  FETCH_NEW_SCHOOL_PRODUCT_FORM_META_REQUEST:
    'FETCH_NEW_SCHOOL_PRODUCT_FORM_META_REQUEST/schoolProducts',
  FETCH_NEW_SCHOOL_PRODUCT_FORM_META_SUCCESS:
    'FETCH_NEW_SCHOOL_PRODUCT_FORM_META_SUCCESS/schoolProducts',
  FETCH_CURRENT_TAX_AMOUNT_REQUEST:
    'FETCH_CURRENT_TAX_AMOUNT_REQUEST/schoolProducts',
  FETCH_CURRENT_TAX_AMOUNT_SUCCESS:
    'FETCH_CURRENT_TAX_AMOUNT_SUCCESS/schoolProducts',
  SET_NEW_SCHOOL_PRODUCT_REQUEST:
    'SET_NEW_SCHOOL_PRODUCT_REQUEST/schoolProducts',
  SET_NEW_SCHOOL_PRODUCT_SUCCESS:
    'SET_NEW_SCHOOL_PRODUCT_SUCCESS/schoolProducts',
  SET_INFORMATION_TO_PAID: 'SET_INFORMATION_TO_PAID/schoolProducts',
  SET_CANCEL_ORDER_REQUEST: 'SET_CANCEL_ORDER_REQUEST/schoolProducts',
  SET_CANCEL_ORDER_SUCCESS: 'SET_CANCEL_ORDER_SUCCESS/schoolProducts',
  SET_CANCEL_ORDER_REQUEST_LD: 'SET_CANCEL_ORDER_REQUEST_LD/schoolProducts',
  SET_CANCEL_ORDER_SUCCESS_LD: 'SET_CANCEL_ORDER_SUCCESS_LD/schoolProducts',
  SET_APPROVE_SCHOOL_PRODUCT_REQUEST:
    'SET_APPROVE_SCHOOL_PRODUCT_REQUEST/schoolProducts',
  SET_APPROVE_SCHOOL_PRODUCT_SUCCESS:
    'SET_APPROVE_SCHOOL_PRODUCT_SUCCESS/schoolProducts',
  SET_CHECKOUT_REQUEST: 'SET_CHECKOUT_REQUEST/schoolProducts',
  SET_CHECKOUT_SUCCESS: 'SET_CHECKOUT_SUCCESS/schoolProducts',
  SET_CHECKOUT_ERROR: 'SET_CHECKOUT_ERROR/schoolProducts',
  SET_MANUALLY_PAID_ORDER_REQUEST:
    'SET_MANUALLY_PAID_ORDER_REQUEST/schoolProducts',
  SET_MANUALLY_PAID_ORDER_SUCCESS:
    'SET_MANUALLY_PAID_ORDER_SUCCESS/schoolProducts',
  SET_PAID_AT_SCHOOL_ORDER_REQUEST:
    'SET_PAID_AT_SCHOOL_ORDER_REQUEST/schoolProducts',
  SET_PAID_AT_SCHOOL_ORDER_SUCCESS:
    'SET_PAID_AT_SCHOOL_ORDER_SUCCESS/schoolProducts',
  SET_DELETE_SCHOOL_PRODUCT_REQUEST:
    'SET_DELETE_SCHOOL_PRODUCT_REQUEST/schoolProducts',
  SET_DELETE_SCHOOL_PRODUCT_SUCCESS:
    'SET_DELETE_SCHOOL_PRODUCT_SUCCESS/schoolProducts',
  SET_CANCEL_SCHOOL_PRODUCT_REQUEST:
    'SET_CANCEL_SCHOOL_PRODUCT_REQUEST/schoolProducts',
  SET_CANCEL_SCHOOL_PRODUCT_SUCCESS:
    'SET_CANCEL_SCHOOL_PRODUCT_SUCCESS/schoolProducts',
  SET_REFUND_ORDER_REQUEST: 'SET_REFUND_ORDER_REQUEST/schoolProducts',
  SET_REFUND_ORDER_SUCCESS: 'SET_REFUND_ORDER_SUCCESS/schoolProducts',
  SET_REJECT_SCHOOL_PRODUCT_SUCCESS:
    'SET_REJECT_SCHOOL_PRODUCT_SUCCESS/schoolProducts',
  SET_REJECT_SCHOOL_PRODUCT_ERROR:
    'SET_REJECT_SCHOOL_PRODUCT_ERROR/schoolProducts',
  SET_ClONE_SCHOOL_PRODUCT_REQUEST:
    'SET_ClONE_SCHOOL_PRODUCT_REQUEST/schoolProducts',
  SET_ClONE_SCHOOL_PRODUCT_SUCCESS:
    'SET_ClONE_SCHOOL_PRODUCT_SUCCESS/schoolProducts',
  SET_EDIT_SCHOOL_PRODUCT_REQUEST:
    'SET_EDIT_SCHOOL_PRODUCT_REQUEST/schoolProducts',
  SET_EDIT_SCHOOL_PRODUCT_SUCCESS:
    'SET_EDIT_SCHOOL_PRODUCT_SUCCESS/schoolProducts',
  TOGGLE_CANCEL_ORDER_MODAL: 'TOGGLE_CANCEL_ORDER_MODAL/schoolProducts',
  TOGGLE_CHECKOUT_MODAL: 'TOGGLE_CHECKOUT_MODAL/schoolProducts',
  TOGGLE_CHECKOUT_LINK_MODAL: 'TOGGLE_CHECKOUT_LINK_MODAL/schoolProducts',
  TOGGLE_MANUALLY_PAID_MODAL: 'TOGGLE_MANUALLY_PAID_MODAL/schoolProducts',
  TOGGLE_MANUAL_DEAL_MODAL: 'TOGGLE_MANUAL_DEAL_MODAL/schoolProducts',
  TOGGLE_REFUND_ORDER_MODAL: 'TOGGLE_REFUND_ORDER_MODAL/schoolProducts',
  TOGGLE_SCHOOL_PRODUCT_INFORMATION_MODAL:
    'TOGGLE_SCHOOL_PRODUCT_INFORMATION_MODAL/schoolProducts',
  TOGGLE_CURRENT_TAB: 'TOGGLE_CURRENT_TAB/schoolProducts',
  SET_CLOSE_REFUND_ORDER_SUCCESS_MODAL:
    'SET_CLOSE_REFUND_ORDER_SUCCESS_MODAL/schoolProducts',
  fetchSchoolProductsList: (filters) => ({
    type: actions.FETCH_SCHOOLPRODUCTS_LIST_REQUEST,
    filters,
  }),
  fetchCurrentSchoolProduct: ({ schoolProductId }) => ({
    type: actions.FETCH_SCHOOLPRODUCT_REQUEST,
    schoolProductId,
  }),
  fetchOrdersRequest: ({ schoolProductId, filters }) => ({
    type: actions.FETCH_ORDERS_REQUEST,
    schoolProductId,
    filters,
  }),
  fetchBalance: ({ schoolProductId }) => ({
    type: actions.FETCH_BALANCE_REQUEST,
    schoolProductId,
  }),
  fetchHistory: ({ schoolProductId, page }) => ({
    type: actions.FETCH_HISTORY_REQUEST,
    schoolProductId,
    page,
  }),
  rejectSchoolProduct: ({ schoolProductId, formState }) => ({
    type: actions.REJECT_SCHOOL_PRODUCT,
    schoolProductId,
    formState,
  }),
  fetchClassrooms: () => ({
    type: actions.FETCH_CLASSROOMS_REQUEST,
  }),
  fetchClassroomsStudents: ({ schoolProductId, validateAssociations }) => ({
    type: actions.FETCH_CLASSROOMS_STUDENTS_REQUEST,
    schoolProductId,
    validateAssociations,
  }),
  fetchCurrentTaxAmount: (params) => ({
    type: actions.FETCH_CURRENT_TAX_AMOUNT_REQUEST,
    params,
  }),
  fetchNewSchoolProductFormMeta: () => ({
    type: actions.FETCH_NEW_SCHOOL_PRODUCT_FORM_META_REQUEST,
  }),
  fetchNewSchoolProductFormMetaSuccess: ({ meta }) => ({
    type: actions.FETCH_NEW_SCHOOL_PRODUCT_FORM_META_SUCCESS,
    meta,
  }),
  fetchCloneSchoolProductRequest: ({ schoolProductId }) => ({
    type: actions.FETCH_CLONE_SCHOOL_PRODUCT_REQUEST,
    schoolProductId,
  }),
  fetchEditSchoolProductRequest: ({ schoolProductId }) => ({
    type: actions.FETCH_EDIT_SCHOOL_PRODUCT_REQUEST,
    schoolProductId,
  }),
  setCancelOrder: ({ schoolProductId, orderId }) => ({
    type: actions.SET_CANCEL_ORDER_REQUEST,
    schoolProductId,
    orderId,
  }),
  setCloneSchoolProduct: (form) => ({
    type: actions.SET_ClONE_SCHOOL_PRODUCT_REQUEST,
    form,
  }),
  setNewSchoolProduct: (form) => ({
    type: actions.SET_NEW_SCHOOL_PRODUCT_REQUEST,
    form,
  }),
  setNewSchoolProductSuccess: (schoolProduct) => ({
    type: actions.SET_NEW_SCHOOL_PRODUCT_SUCCESS,
    schoolProduct,
  }),
  setCancelOrderLD: ({ schoolProductId, orderId, form }) => ({
    type: actions.SET_CANCEL_ORDER_REQUEST_LD,
    schoolProductId,
    orderId,
    form,
  }),
  setCloseRefundOrderSuccessModal: () => ({
    type: actions.SET_CLOSE_REFUND_ORDER_SUCCESS_MODAL,
  }),
  setCheckout: ({ orderId, responsibleData, method }) => ({
    type: actions.SET_CHECKOUT_REQUEST,
    orderId,
    responsibleData,
    method,
  }),
  setInformationToPaid: ({ orderId, orders }) => ({
    type: actions.SET_INFORMATION_TO_PAID,
    orderId,
    orders,
  }),
  setManuallyPaidOrder: ({ schoolProductId, orderId, responsibleId }) => ({
    type: actions.SET_MANUALLY_PAID_ORDER_REQUEST,
    schoolProductId,
    orderId,
    responsibleId,
  }),
  setPaidAtSchoolOrder: ({ form, schoolProductId, orderId }) => ({
    type: actions.SET_PAID_AT_SCHOOL_ORDER_REQUEST,
    form,
    schoolProductId,
    orderId,
  }),
  setRefundOrder: ({ schoolProductId, orderId }) => ({
    type: actions.SET_REFUND_ORDER_REQUEST,
    schoolProductId,
    orderId,
  }),
  setApproveSchoolProduct: ({ schoolProductId }) => ({
    type: actions.SET_APPROVE_SCHOOL_PRODUCT_REQUEST,
    schoolProductId,
  }),
  setDeleteSchoolProduct: ({ schoolProductId }) => ({
    type: actions.SET_DELETE_SCHOOL_PRODUCT_REQUEST,
    schoolProductId,
  }),
  setCancelSchoolProduct: ({ schoolProductId }) => ({
    type: actions.SET_CANCEL_SCHOOL_PRODUCT_REQUEST,
    schoolProductId,
  }),
  setEditSchoolProduct: ({ schoolProductId, form }) => ({
    type: actions.SET_EDIT_SCHOOL_PRODUCT_REQUEST,
    schoolProductId,
    form,
  }),
  toggleCancelOrderModal: () => ({
    type: actions.TOGGLE_CANCEL_ORDER_MODAL,
  }),
  toggleCheckoutModal: () => ({
    type: actions.TOGGLE_CHECKOUT_MODAL,
  }),
  toggleCheckoutLinkModal: () => ({
    type: actions.TOGGLE_CHECKOUT_LINK_MODAL,
  }),
  toggleManuallyPaidModal: () => ({
    type: actions.TOGGLE_MANUALLY_PAID_MODAL,
  }),
  toggleManualDealModal: () => ({
    type: actions.TOGGLE_MANUAL_DEAL_MODAL,
  }),
  toggleRefundOrderModal: () => ({
    type: actions.TOGGLE_REFUND_ORDER_MODAL,
  }),
  toggleInformationModal: () => ({
    type: actions.TOGGLE_SCHOOL_PRODUCT_INFORMATION_MODAL,
  }),
  toggleCurrentTab: ({ currentTab }) => ({
    type: actions.TOGGLE_CURRENT_TAB,
    currentTab,
  }),
};

export default actions;
