import React, { useCallback, useContext } from 'react';
import { Box, Button, DefaultThemeProps } from '@agendaedu/ae-web-components';

import {
  MealCard,
  OccurrenceCard,
} from 'pages/diarySectionsV3/Sections/components/ShowSectionModal/Sections';
import { DiaryFormContext } from 'core/contexts/DiaryForm';
import { DiaryFormStructure } from 'store/dailySummaries/types';
import { useTheme } from 'styled-components';
import { defaultSectionGroupValues } from 'core/contexts/DiaryForm/constants';

import { Props } from './types';

export const SectionGroup = ({ field }: Props) => {
  const { border, colors } = useTheme() as DefaultThemeProps;

  const {
    form: { values, setFieldValue },
  } = useContext(DiaryFormContext);

  const fieldValues = structuredClone(values[field]) as Partial<
    DiaryFormStructure['form'][typeof field][0]
  >[];

  const handleSectionChanges = (
    value: DiaryFormStructure['form'][typeof field][0],
    index: number
  ) => {
    fieldValues[index] = value;

    setFieldValue(field, fieldValues);
  };

  const addSectionCard = useCallback(
    (index: number) => {
      const newValues = defaultSectionGroupValues[field]();

      if (index >= 0 && index < fieldValues.length) {
        fieldValues.splice(index + 1, 0, newValues);
      } else {
        fieldValues.push(newValues);
      }
      setFieldValue(field, fieldValues);
    },
    [field, fieldValues, setFieldValue]
  );

  const removeSectionCard = useCallback(
    (index: number) => {
      if (
        field === 'occurrences' &&
        (fieldValues[index] as DiaryFormStructure['occurrence']).id
      ) {
        setFieldValue('occurrences_to_destroy', [
          ...values.occurrences_to_destroy,
          (fieldValues[index] as DiaryFormStructure['occurrence']).id,
        ]);
      }

      fieldValues.splice(index, 1);
      setFieldValue(field, fieldValues);
    },
    [fieldValues, setFieldValue]
  );

  const getSectionCard = (value: typeof fieldValues[0], index: number) =>
    ({
      meals: (
        <MealCard
          data-testid="meals-card"
          meal={value as DiaryFormStructure['form']['meals'][0]}
          onChange={(meal) => handleSectionChanges(meal, index)}
        />
      ),
      occurrences: (
        <OccurrenceCard
          id={index}
          data-testid="occurrence-card"
          occurrence={value as DiaryFormStructure['form']['occurrences'][0]}
          onChange={(occurrence) => handleSectionChanges(occurrence, index)}
        />
      ),
    }[field]);

  const canAddSectionCard = {
    meals: true,
    occurrences: fieldValues.length <= 5,
  }[field];

  const clearSectionAction = {
    meals: (index: number) => {
      const currentValue = fieldValues[
        index
      ] as DiaryFormStructure['form']['meals'][0];

      const emptyValue = {
        ...currentValue,
        ...defaultSectionGroupValues.meals(),
      };

      handleSectionChanges(emptyValue, index);
    },
    occurrences: (index: number) => {
      const currentValue = fieldValues[
        index
      ] as DiaryFormStructure['form']['occurrences'][0];

      const emptyValue = {
        ...currentValue,
        ...defaultSectionGroupValues.occurrences(),
      };

      handleSectionChanges(emptyValue, index);
    },
  };

  const isEmptySection = {
    meals: (index: number) => {
      const currentValue = fieldValues[
        index
      ] as DiaryFormStructure['form']['meals'][0];

      return [
        currentValue.category,
        currentValue.description,
        currentValue.not_ate,
      ].every((field) => !field);
    },
    occurrences: (index: number) => {
      const currentValue = fieldValues[
        index
      ] as DiaryFormStructure['form']['occurrences'][0];

      return [
        currentValue.category,
        currentValue.description,
        currentValue.files.length,
      ].every((field) => !field);
    },
  };

  return (
    <>
      {fieldValues.map((value, index) => (
        <Box width="100%" key={value.key} display="flex">
          <Box display="flex" width="100%">
            {getSectionCard(value, index)}

            <Box
              ml="sm"
              p="xs"
              gap="xs"
              display="flex"
              flexDirection="column"
              height="fit-content"
              borderRadius={border.radius.md}
              border={`${border.width.sm} ${border.style.solid} ${colors.neutral.gray4}`}
            >
              {canAddSectionCard && (
                <Button
                  testId="add-section"
                  isOnlyIcon
                  icon="plus"
                  size="sm"
                  variant="secondary"
                  onClick={() => addSectionCard(index)}
                />
              )}

              <Button
                testId="clear-values"
                isOnlyIcon
                icon="eraser"
                size="sm"
                variant="secondary"
                disabled={isEmptySection[field](index)}
                onClick={() => clearSectionAction[field](index)}
              />

              <Button
                testId="remove-section"
                isOnlyIcon
                icon="trash-bin"
                size="sm"
                variant="secondary"
                contextVariant="alert"
                disabled={index === 0}
                onClick={() => removeSectionCard(index)}
              />
            </Box>
          </Box>
        </Box>
      ))}
    </>
  );
};
