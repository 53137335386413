import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PageContainer from 'components/PageContainer';
import Breadcrumb from 'components/Breadcrumb';
import { useSelector, useDispatch } from 'react-redux';
import shopActions from 'store/edupay/shop/actions';
import Loader from 'components/Loader';
import InternalErrorBoundary from 'boundaries/InternalErrorBoundary';
import Tab from 'components/Tab';
import Tabs from 'components/Tabs';
import OfferDetailsInformationsTab from 'components/Payments/Shop/OfferDetailsInformationsTab';
import PageTitle from 'components/PageTitle';

const OfferDetailsContainer = ({
  match: {
    params: { id },
  },
}) => {
  const [currentTab, setCurrentTab] = useState(0);
  const { fetchOfferDetails } = shopActions;
  const { current, isLoading } = useSelector((state) => state.shop);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchOfferDetails(id));
  }, [id]);

  return (
    <PageContainer
      title="Detalhes da oferta"
      className="PageCharge"
      breadcrumb={
        <Breadcrumb
          title="Oferta do shop"
          path="/schools/school_products/tab/shop"
        />
      }
    >
      <InternalErrorBoundary>
        <Loader isLoading={isLoading}>
          <Tabs
            onSelect={(index) => setCurrentTab(index.selectedTab)}
            defaultIndex={currentTab}
          >
            <Tab title="Informações">
              {current ? (
                <OfferDetailsInformationsTab offer={current} />
              ) : (
                <PageTitle>Oferta não encontrada</PageTitle>
              )}
            </Tab>
          </Tabs>
        </Loader>
      </InternalErrorBoundary>
    </PageContainer>
  );
};

OfferDetailsContainer.defaultProps = {};

OfferDetailsContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default OfferDetailsContainer;
