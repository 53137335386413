import React from 'react';
import { Tag } from '@agendaedu/ae-web-components';

import Button from 'components/Button';

import { MemberProps } from 'store/messages/types';

import { GroupDetailModalProps } from './types';
import * as S from './styles';

const GroupDetailsModal = ({
  dataTestId,
  group: {
    id,
    attributes: { name, description },
    relationships: {
      members: { data: membersData },
    },
  },
  isOpen,
  title,
  closeModalText = 'Fechar',
  toggleModal,
}: GroupDetailModalProps) => (
  <>
    {isOpen && (
      <S.ModalWrapper data-testid={dataTestId}>
        <S.GroupDetailModal
          isOpen={isOpen}
          toggleModal={toggleModal}
          maxWidth="490px"
          title={title}
        >
          <S.Wrapper>
            <S.ContentWrapper>
              <Tag name={`ID #${id}`} variant="neutral" size="small" />
              <S.DetailWrapper>
                <S.Label>Título</S.Label>
                <S.Info>{name}</S.Info>
              </S.DetailWrapper>

              {description && (
                <S.DetailWrapper>
                  <S.Label>Descrição</S.Label>
                  <S.Info>{description}</S.Info>
                </S.DetailWrapper>
              )}

              <S.DetailWrapper>
                <S.Label>Membros</S.Label>
                {membersData.map((member: MemberProps) => (
                  <S.Info key={member.id}>{member.attributes.name}</S.Info>
                ))}
              </S.DetailWrapper>

              <S.ButtonWrapper>
                <Button variation="primary" onClick={toggleModal}>
                  {closeModalText}
                </Button>
              </S.ButtonWrapper>
            </S.ContentWrapper>
          </S.Wrapper>
        </S.GroupDetailModal>
      </S.ModalWrapper>
    )}
  </>
);

export default GroupDetailsModal;
