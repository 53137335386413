import { GroupProps, MemberProps } from 'store/messages/types';

export const getGroupListWithMembers = (
  data: GroupProps[],
  included: MemberProps[]
) => {
  const dataWithMembers = data.map((group: GroupProps) => {
    const members = group.relationships.members.data.map(
      (memberData: MemberProps) => {
        return included.find(
          (member: MemberProps) =>
            member.id === memberData.id && member.type === memberData.type
        );
      }
    );

    return {
      ...group,
      relationships: {
        ...group.relationships,
        members: {
          ...group.relationships.members,
          data: members,
        },
      },
    };
  });

  return dataWithMembers;
};

export const getCurrentGroupWithMembers = (
  data: GroupProps,
  included: MemberProps[]
) => {
  const currentGroupWithMembers = {
    ...data,
    relationships: {
      ...data.relationships,
      members: {
        ...data.relationships.members,
        data: included,
      },
    },
  };

  return currentGroupWithMembers;
};
