import styled from 'styled-components';
import FormFieldset from 'components/Form/Fieldset';

export const GroupMembersStepWrapper = styled(FormFieldset)``;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 64px;
  height: 100%;
  width: 100%;
  padding: 0 16px;

  .Avatar {
    width: 34px;
    height: 34px;
  }

  > span {
    padding-left: 0;
  }
`;

export const Titulo = styled.span`
  display: flex;
  padding-left: 0;
`;
