import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { fetchApi, postApi } from 'core/utils/api';

import actions from './actions';
import {
  buildToast,
  toastCss,
  ToastTypes,
} from 'store/middlewares/toast/actions';
import i18n from 'config/i18n';
import { ActionsSaga } from './types';

function* fetchRecipientsClassroomsSaga() {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { history } = yield call(
      fetchApi,
      `/${dataArea}/student_profiles/classrooms.json`
    );

    yield put({
      type: actions.FETCH_RECIPIENTS_CLASSROOMS_SUCCESS,
      data: history,
    });
  } catch (error) {
    yield put({ type: actions.ERROR, error });
  }
}

function* saveFolderRequestSaga(action: ActionsSaga['saveFolderRequestSaga']) {
  try {
    const { params } = action;
    const { dataArea } = yield select((state) => state.root);

    yield call(postApi, `/${dataArea}/storage/folders/new`, { params });

    yield put({
      type: actions.SAVE_FOLDER_SUCCESS,
      toast: buildToast(
        i18n.t('storage:folder_form.save_folder_success'),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.SAVE_FOLDER_ERROR,
      error,
      toast: buildToast(
        i18n.t('storage:folder_form.save_folder_error'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* storage() {
  yield all([
    takeLatest(
      actions.FETCH_RECIPIENTS_CLASSROOMS,
      fetchRecipientsClassroomsSaga
    ),
    takeLatest(actions.SAVE_FOLDER_REQUEST, saveFolderRequestSaga),
  ]);
}

export default storage;
