import React, { ChangeEvent, useMemo, useRef, useState } from 'react';
import useOutsideClick from 'core/hooks/useOutsideClick';
import { StudentProfile } from 'store/messages/omniChannel/types';

import * as S from './styles';

import { StudentSelectProps } from './types';

export const StudentSelect = ({
  options,
  placeholder,
  onChange,
  emptyText,
  label,
  selectedStudent,
}: StudentSelectProps): JSX.Element => {
  const selectRef = useRef(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [isOptionsVisible, setOptionsVisible] = useState<boolean>(false);

  useOutsideClick(selectRef, () => setOptionsVisible(false));

  const filteredOptions = useMemo(() => {
    const lowerSearchValue = searchValue.toLowerCase();

    return options?.filter((option: StudentProfile) =>
      option.attributes.name.toLowerCase().includes(lowerSearchValue)
    );
  }, [searchValue, options]);

  const handleToggleOptions = () => setOptionsVisible(!isOptionsVisible);

  const handleSelectOption = (option: StudentProfile) => {
    onChange(option);
    handleToggleOptions();
    setSearchValue('');
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    onChange(null);
    setSearchValue(value);
  };

  return (
    <S.StudentSelectWrapper ref={selectRef}>
      {!!label && <S.SelectTextLabel>{label}</S.SelectTextLabel>}
      <S.SelectWrapper
        isOptionsVisible={isOptionsVisible}
        onClick={handleToggleOptions}
      >
        <S.SelectContent>
          <S.TextField
            type="text"
            placeholder={placeholder}
            value={searchValue || selectedStudent?.attributes.name || ''}
            disabled={false}
            onChange={handleSearchChange}
          />
        </S.SelectContent>

        <S.SelectIcon name="caret-down" />
      </S.SelectWrapper>
      {isOptionsVisible && (
        <S.OptionList role="listbox">
          {!filteredOptions || filteredOptions?.length === 0 ? (
            <S.EmptyOption>{emptyText}</S.EmptyOption>
          ) : (
            filteredOptions?.map((student: StudentProfile, index: number) => {
              const optionSelected =
                student?.attributes.name === selectedStudent?.attributes.name;

              return (
                <S.OptionItem
                  key={index}
                  onClick={() => handleSelectOption(student)}
                  role="option"
                  aria-selected={optionSelected}
                  isSelected={optionSelected}
                >
                  <S.SelectAvatar
                    avatarColor={student.attributes.avatar_color}
                    size="xs"
                    nameInitials={student.attributes.name_initials}
                    avatarUrl={student.attributes.avatar_url}
                  />
                  {student.attributes.name}
                </S.OptionItem>
              );
            })
          )}
        </S.OptionList>
      )}
    </S.StudentSelectWrapper>
  );
};
