import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  fetchApi,
  postApi,
  putApi,
  patchApi,
  handleError,
} from 'core/utils/api';
import {
  buildToast,
  toastCss,
  ToastTypes,
} from 'store/middlewares/toast/actions';
import axios from 'axios';

import actions from './actions';
import { CHECKOUT_METHODS } from 'core/constants';

export function* fetchSchoolProductsList({ filters }) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const {
      data: schoolProducts,
      meta: { has_school_products: hasSchoolProducts },
      itemsCountPerPage,
      totalItemsCount,
    } = yield call(fetchApi, `/${dataArea}/school_products.json`, {
      params: filters,
    });

    const activePage = filters && filters.page ? filters.page : 1;

    yield put({
      type: actions.FETCH_SCHOOLPRODUCTS_LIST_SUCCESS,
      schoolProducts,
      hasSchoolProducts,
      paginate: {
        activePage,
        itemsCountPerPage,
        totalItemsCount,
      },
      filters,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível listar as cobranças',
        ToastTypes.error
      ),
    });
  }
}

export function* fetchCurrentSchoolProduct({ schoolProductId }) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data: schoolProduct } = yield call(
      fetchApi,
      `/${dataArea}/school_products/${schoolProductId}.json`
    );

    yield put({
      type: actions.FETCH_SCHOOLPRODUCT_SUCCESS,
      schoolProduct,
    });
  } catch (error) {
    yield put({ type: actions.ERROR, error });
  }
}

export function* fetchOrders({ schoolProductId, filters }) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const {
      data: orders,
      included: visualizations,
      itemsCountPerPage,
      totalItemsCount,
    } = yield call(
      fetchApi,
      `/${dataArea}/school_products/${schoolProductId}/orders.json`,
      {
        params: filters,
      }
    );

    const activePage = filters && filters.page ? filters.page : 1;

    yield put({
      type: actions.FETCH_ORDERS_SUCCESS,
      orders,
      filters,
      visualizations,
      paginate: {
        activePage,
        itemsCountPerPage,
        totalItemsCount,
      },
    });
  } catch (error) {
    yield put({ type: actions.ERROR, error });
  }
}

const getBalance = (dataArea, schoolProductId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/${dataArea}/school_products/${schoolProductId}/balance.json`)
      .then((response) => {
        const balance = response.data;
        resolve({ balance });
      })
      .catch((error) => reject(error));
  });
};

export function* fetchBalance({ schoolProductId }) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { balance } = yield call(getBalance, dataArea, schoolProductId);

    yield put({
      type: actions.FETCH_BALANCE_SUCCESS,
      balance,
    });
  } catch (error) {
    yield put({ type: actions.ERROR, error });
  }
}

export function* fetchHistory({ schoolProductId, page }) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { history, totalItemsCount, itemsCountPerPage } = yield call(
      fetchApi,
      `/${dataArea}/school_products/${schoolProductId}/history.json`,
      {
        params: {
          page,
        },
      }
    );

    yield put({
      type: actions.FETCH_HISTORY_SUCCESS,
      history: {
        historyData: history,
        totalItemsCount,
        itemsCountPerPage,
        activePage: page,
      },
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possivel carregar o histórico',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchClassrooms() {
  try {
    const { history: classrooms } = yield call(
      fetchApi,
      `/schools/messages/classrooms.json`
    );

    yield put({
      type: actions.FETCH_CLASSROOMS_SUCCESS,
      classrooms,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível carregar as turmas.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchNewSchoolProductFormMeta() {
  try {
    yield put({
      type: actions.FETCH_CLASSROOMS_REQUEST,
    });

    const {
      history: { meta },
    } = yield call(fetchApi, `/schools/school_products/new.json`);

    yield put({
      type: actions.FETCH_NEW_SCHOOL_PRODUCT_FORM_META_SUCCESS,
      meta,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível carregar o formulário.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* setNewSchoolProduct({ form }) {
  try {
    const { data: schoolProduct } = yield call(
      postApi,
      `/schools/school_products`,
      form
    );

    yield put(push(`/schools/school_products/${schoolProduct.id}`));

    yield put({
      type: actions.SET_NEW_SCHOOL_PRODUCT_SUCCESS,
      toast: buildToast(
        'Cobrança criada com sucesso',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível criar a cobrança.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* setCloneSchoolProduct({ form }) {
  try {
    const { data: schoolProduct } = yield call(
      postApi,
      `/schools/school_products`,
      form
    );
    yield put(push(`/schools/school_products/${schoolProduct.id}`));

    yield put({
      type: actions.SET_ClONE_SCHOOL_PRODUCT_SUCCESS,
      toast: buildToast(
        'Cobrança duplicada com sucesso',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível duplicar a cobrança.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* setCancelOrder({ schoolProductId, orderId }) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data: orderCanceled } = yield call(
      putApi,
      `/${dataArea}/school_products/${schoolProductId}/orders/${orderId}/cancel`
    );

    yield put({
      type: actions.SET_CANCEL_ORDER_SUCCESS,
      orderCanceled,
      toast: buildToast(
        'Cobrança cancelada com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possivel cancelar a cobrança.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* setCancelOrderLD({ schoolProductId, orderId, form }) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { cancelNote } = form;

    const { data: orderCanceled } = yield call(
      putApi,
      `/${dataArea}/school_products/${schoolProductId}/orders/${orderId}/manual_cancel`,
      { message: cancelNote }
    );

    yield put({
      type: actions.SET_CANCEL_ORDER_SUCCESS_LD,
      orderCanceled,
      toast: buildToast(
        'Cobrança cancelada com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possivel cancelar a cobrança.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* setCheckout({ orderId, responsibleData, method }) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data } = yield call(
      postApi,
      `/${dataArea}/edupay/orders/${orderId}/checkout`,
      {
        edupay: { ...responsibleData },
        event: `school_generated_${method}`,
      }
    );

    yield put({
      type: actions.SET_CHECKOUT_SUCCESS,
      data,
      toast: buildToast(
        `${CHECKOUT_METHODS[method]} gerado com sucesso.`,
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.SET_CHECKOUT_ERROR,
      toast: buildToast(
        `Não foi possivel gerar o ${CHECKOUT_METHODS[method]}`,
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
      error,
    });
  }
}

export function* setManuallyPaidOrder({
  schoolProductId,
  orderId,
  responsibleId,
}) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data: orderPaid } = yield call(
      postApi,
      `/${dataArea}/school_products/${schoolProductId}/orders/${orderId}/manual_payment`,
      { responsible_profile_id: responsibleId }
    );

    yield put({
      type: actions.SET_MANUALLY_PAID_ORDER_SUCCESS,
      orderPaid,
      toast: buildToast(
        'Cobrança marcada como paga com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possivel marcar a cobrança como paga',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* setPaidAtSchoolOrder({ form, schoolProductId, orderId }) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const {
      paymentmethod,
      divergentValue,
      responsibleProfileId,
      toggleDivergentValue,
      attachments,
      paidAtSchoolDate,
      paidAtSchoolNote,
    } = form;

    const { data: orderPaid } = yield call(
      patchApi,
      `/${dataArea}/school_products/${schoolProductId}/orders/${orderId}/manual_order_payment`,
      {
        responsible_profile_id: responsibleProfileId,
        observation: paidAtSchoolNote,
        method_payment: paymentmethod,
        paid_amount_cents: divergentValue,
        paid_at: paidAtSchoolDate,
        paid_with_different_amount: toggleDivergentValue,
        attachments,
      }
    );

    yield put({
      type: actions.SET_PAID_AT_SCHOOL_ORDER_SUCCESS,
      orderPaid,
      toast: buildToast(
        'Cobrança marcada como paga com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        handleError(error),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* setApproveSchoolProduct({ schoolProductId }) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data: schoolProduct } = yield call(
      putApi,
      `/${dataArea}/school_products/${schoolProductId}/approve`
    );

    yield put({
      type: actions.SET_APPROVE_SCHOOL_PRODUCT_SUCCESS,
      schoolProduct,
      toast: buildToast(
        'Cobrança aprovada com sucesso',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possivel aprovar o envio da cobrança',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* setDeleteSchoolProduct({ schoolProductId }) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data: schoolProduct } = yield call(
      putApi,
      `/${dataArea}/school_products/${schoolProductId}/delete`
    );

    yield put(push('/schools/school_products/tab/school_products'));

    yield put({
      type: actions.SET_DELETE_SCHOOL_PRODUCT_SUCCESS,
      schoolProduct,
      toast: buildToast(
        'Cobrança excluida com sucesso',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possivel excluir a cobrança',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* setCancelSchoolProduct({ schoolProductId }) {
  try {
    const { dataArea } = yield select((state) => state.root);
    const { data: schoolProduct } = yield call(
      putApi,
      `/${dataArea}/school_products/${schoolProductId}/cancel`
    );
    yield put({
      type: actions.SET_CANCEL_SCHOOL_PRODUCT_SUCCESS,
      schoolProduct,
      toast: buildToast(
        'Cobrança cancelada com sucesso',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possivel cancelar a cobrança.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* setRefundOrder({ schoolProductId, orderId }) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data: orderRefunded } = yield call(
      putApi,
      `/${dataArea}/school_products/${schoolProductId}/orders/${orderId}/refund`
    );

    yield put({
      type: actions.SET_REFUND_ORDER_SUCCESS,
      orderRefunded,
      toast: buildToast(
        'Processando reembolso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possivel reembolsar a cobrança',
        ToastTypes.error
      ),
    });
  }
}

export function* rejectSchoolProduct({ schoolProductId, formState }) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data: schoolProduct } = yield call(
      putApi,
      `/${dataArea}/school_products/${schoolProductId}/reject`,
      {
        school_product: {
          cancellation_description: formState.cancellation_description,
        },
      }
    );

    yield put({
      type: actions.SET_REJECT_SCHOOL_PRODUCT_SUCCESS,
      schoolProduct,
    });

    yield put(push(`/schools/school_products/${schoolProductId}`));
  } catch (error) {
    yield put({
      type: actions.SET_REJECT_SCHOOL_PRODUCT_ERROR,
      hasBackendValidationError: error.response.status === 422 ? true : false,
      error,
    });
  }
}

export function* fetchCloneSchoolProduct({ schoolProductId }) {
  try {
    const { dataArea } = yield select((state) => state.root);

    yield put({
      type: actions.FETCH_CLASSROOMS_REQUEST,
    });

    const validateAssociations = true;
    yield put({
      type: actions.FETCH_CLASSROOMS_STUDENTS_REQUEST,
      schoolProductId,
      validateAssociations,
    });

    const { data: schoolProduct, meta } = yield call(
      fetchApi,
      `/${dataArea}/school_products/${schoolProductId}/clone_charge.json`
    );

    yield put({
      type: actions.FETCH_CLONE_SCHOOL_PRODUCT_SUCCESS,
      schoolProduct: {
        ...schoolProduct,
        meta,
      },
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      toast: buildToast(
        'Não foi possivel carregar a cobrança',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
      error,
    });
  }
}

export function* fetchEditSchoolProduct({ schoolProductId }) {
  try {
    const { dataArea } = yield select((state) => state.root);

    yield put({
      type: actions.FETCH_CLASSROOMS_REQUEST,
    });

    yield put({
      type: actions.FETCH_CLASSROOMS_STUDENTS_REQUEST,
      schoolProductId,
    });

    const { data: schoolProduct, meta } = yield call(
      fetchApi,
      `/${dataArea}/school_products/${schoolProductId}/edit.json`
    );

    yield put({
      type: actions.FETCH_EDIT_SCHOOL_PRODUCT_SUCCESS,
      schoolProduct: {
        ...schoolProduct,
        meta,
      },
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      toast: buildToast(
        'Não foi possivel carregar a cobrança',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
      error,
    });
  }
}

export function* fetchClassroomsStudents({ schoolProductId, validateAssociations }) {
  try {
    const { dataArea } = yield select((state) => state.root);

    let endpoint = `/${dataArea}/school_products/${schoolProductId}/students.json`;
    if (validateAssociations) {
      endpoint += '?validate_associations=true';
    }

    const { history: classroomsStudents } = yield call(
      fetchApi,
      endpoint
    );

    yield put({
      type: actions.FETCH_CLASSROOMS_STUDENTS_SUCCESS,
      classroomsStudents,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      toast: buildToast(
        'Não foi possivel carregar as turmas',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
      error,
    });
  }
}

export function* fetchTaxAmount(params) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { history: taxAmount } = yield call(
      fetchApi,
      `/${dataArea}/edupay/card_fees_calculation/tax_calculation`,
      params
    );

    yield put({
      type: actions.FETCH_CURRENT_TAX_AMOUNT_SUCCESS,
      taxAmount,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

export function* setEditSchoolProduct({ schoolProductId, form }) {
  try {
    const { dataArea } = yield select((state) => state.root);

    yield call(putApi, `/${dataArea}/school_products/${schoolProductId}.json`, {
      ...form,
    });

    yield put(push(`/schools/school_products`));

    yield put({
      type: actions.SET_EDIT_SCHOOL_PRODUCT_SUCCESS,
      toast: buildToast(
        'Cobrança atualizada com sucesso',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      toast: buildToast(
        'Não foi possivel atualizar a cobrança',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
      error,
    });
  }
}

function* schoolProductSagas() {
  yield all([
    takeLatest(
      actions.FETCH_SCHOOLPRODUCTS_LIST_REQUEST,
      fetchSchoolProductsList
    ),
    takeLatest(actions.FETCH_ORDERS_REQUEST, fetchOrders),
    takeLatest(actions.FETCH_SCHOOLPRODUCT_REQUEST, fetchCurrentSchoolProduct),
    takeLatest(actions.FETCH_BALANCE_REQUEST, fetchBalance),
    takeLatest(actions.FETCH_HISTORY_REQUEST, fetchHistory),
    takeLatest(actions.FETCH_CLASSROOMS_REQUEST, fetchClassrooms),
    takeLatest(
      actions.FETCH_NEW_SCHOOL_PRODUCT_FORM_META_REQUEST,
      fetchNewSchoolProductFormMeta
    ),
    takeLatest(
      actions.FETCH_CLASSROOMS_STUDENTS_REQUEST,
      fetchClassroomsStudents
    ),
    takeLatest(
      actions.FETCH_CLONE_SCHOOL_PRODUCT_REQUEST,
      fetchCloneSchoolProduct
    ),
    takeLatest(actions.FETCH_CURRENT_TAX_AMOUNT_REQUEST, fetchTaxAmount),
    takeLatest(
      actions.FETCH_EDIT_SCHOOL_PRODUCT_REQUEST,
      fetchEditSchoolProduct
    ),

    takeLatest(actions.SET_NEW_SCHOOL_PRODUCT_REQUEST, setNewSchoolProduct),
    takeLatest(actions.SET_CANCEL_ORDER_REQUEST, setCancelOrder),
    takeLatest(actions.SET_CANCEL_ORDER_REQUEST_LD, setCancelOrderLD),
    takeLatest(actions.SET_CHECKOUT_REQUEST, setCheckout),
    takeLatest(actions.SET_MANUALLY_PAID_ORDER_REQUEST, setManuallyPaidOrder),
    takeLatest(actions.SET_PAID_AT_SCHOOL_ORDER_REQUEST, setPaidAtSchoolOrder),
    takeLatest(actions.SET_REFUND_ORDER_REQUEST, setRefundOrder),
    takeLatest(actions.SET_ClONE_SCHOOL_PRODUCT_REQUEST, setCloneSchoolProduct),
    takeLatest(actions.SET_EDIT_SCHOOL_PRODUCT_REQUEST, setEditSchoolProduct),
    takeLatest(
      actions.SET_APPROVE_SCHOOL_PRODUCT_REQUEST,
      setApproveSchoolProduct
    ),
    takeLatest(
      actions.SET_DELETE_SCHOOL_PRODUCT_REQUEST,
      setDeleteSchoolProduct
    ),
    takeLatest(
      actions.SET_CANCEL_SCHOOL_PRODUCT_REQUEST,
      setCancelSchoolProduct
    ),
    takeLatest(actions.REJECT_SCHOOL_PRODUCT, rejectSchoolProduct),
  ]);
}

export default schoolProductSagas;
