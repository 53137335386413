import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Loader from 'components/Loader';
import Toast from 'components/Toast';
import RecurrentPlanForm from 'components/Payments/Recurrent/RecurrentPlanForm';
import RecurrentPlanFormInformationsTab from 'components/Payments/Recurrent/RecurrentPlanForm/RecurrentPlanFormInformationsTab';
import RecurrentPlanFormRecipientsTab from 'components/Payments/Recurrent/RecurrentPlanForm/RecurrentPlanFormRecipientsTab';
import selectOptions from 'components/Payments/Recurrent/RecurrentPlanForm/SelectOptions';

import actionRecurrentPlans from 'store/edupay/recurrentPlans/actions';
import actionRecurrentForms from 'store/edupay/recurrentForms/actions';
import actionRecurrentProducts from 'store/edupay/recurrentProducts/actions';

import { buildDiscount } from 'core/utils/edupay/functions';
import { DISCOUNT_KINDS } from 'core/constants/index';
import withAppContext from 'core/hoc/withAppContext';
import { ALLOWED_PAYMENT_METHOD_DEMONSTRATION_SCHOOL } from 'core/constants';
import {
  formattedDiscounts,
  formattedPenality,
} from 'core/utils/edupay/functions';

const WIZARD_STEPS = [
  RecurrentPlanFormInformationsTab,
  RecurrentPlanFormRecipientsTab,
];

const NewRecurrentPlan = ({
  appContext: {
    policies: {
      guaranteed_monthly_fee_enabled: guaranteedMonthlyFeeEnabled,
      school_plan,
    },
  },
}) => {
  const dispatch = useDispatch();

  const { billetTax, pixTax, allowedPaymentMethod } = useSelector(
    (state) => state.root
  );

  const { recurrentProducts, isLoading: isLoadingProducts } = useSelector(
    (state) => state.recurrentProducts.recurrentProducts
  );

  const { sending, isLoading, recipients, recipientsWallet, isLoadingWallet } =
    useSelector((state) => state.recurrentPlans);

  const { formAction } = actionRecurrentForms;
  const { fetchRecurrentProducts } = actionRecurrentProducts;
  const { addRecurrentPlan, fetchRecipients } = actionRecurrentPlans;

  const createRecurrentPlan = useCallback(
    (params) => {
      dispatch(addRecurrentPlan(params));
    },
    [addRecurrentPlan, dispatch]
  );

  const getRecurrentProducts = useCallback(() => {
    dispatch(fetchRecurrentProducts({ per_page: 'all' }));
  }, [fetchRecurrentProducts, dispatch]);

  const enableFormAction = useCallback(() => {
    dispatch(formAction('new'));
  }, [formAction, dispatch]);

  const recurrentProductIds = () =>
    recurrentProducts &&
    recurrentProducts.map((recurrentProduct) => ({
      name: recurrentProduct.attributes.name,
      value: recurrentProduct.id,
    }));

  const formatRecipients = () =>
    recipients &&
    recipients.map((recipient) => ({
      label: recipient.label,
      options: recipient.options.map((option) => ({
        value: option.id,
        label: option.attributes.name,
      })),
    }));

  const formattedRecurrentItems = (recurrentItems) => {
    const formattedItems = [];

    recurrentItems.forEach((item) => {
      const formattedItem = {
        ...item,
        price_cents: item.price || 0,
      };

      if (formattedItem.discount_kind === 'percent') {
        formattedItem.discount_percent = formattedItem.discount_value || 0;
      } else {
        formattedItem.discount_price_cents = formattedItem.discount_value || 0;
      }

      formattedItems.push(formattedItem);
    });

    return formattedItems;
  };

  const handleOnSubmit = (formContext) => {
    const { form } = formContext;

    createRecurrentPlan({
      ...form,
      recurrent_items_attributes: formattedRecurrentItems(form.recurrentItems),
      edupay_penality_attributes: formattedPenality(form),
      edupay_discounts: formattedDiscounts(form),
    });
  };

  useEffect(() => {
    enableFormAction();
    dispatch(fetchRecipients());
    getRecurrentProducts();
  }, []);

  return (
    <div className="NewRecurrentPlan">
      <Loader isLoading={isLoading || isLoadingProducts || isLoadingWallet}>
        <RecurrentPlanForm
          action="new"
          steps={WIZARD_STEPS}
          form={{
            classroom_with_student_profile_ids: {},
            allowed_payment_method:
              school_plan === 'trial'
                ? ALLOWED_PAYMENT_METHOD_DEMONSTRATION_SCHOOL
                : allowedPaymentMethod,
            bills_quantity: null,
            due_day: null,
            bill_release_day: null,
            recipient_wallet_id: null,
            recurrentItems: [
              {
                price: 0,
                discount_kind: 'percent',
                discount_value: 0,
                recurrent_product_id: undefined,
                key: Math.random().toString(36).substring(7),
              },
            ],
            send_financial_responsible: false,
            toggle_penality: true,
            edupay_penality: {
              fine_percent: 2.0,
              interest_percent: 1.0,
            },
            toggle_discount: false,
            edupay_discounts: [buildDiscount()],
            auto_generate_pix: true,
            allow_credit_card_discount: true,
          }}
          formMeta={{
            billetTax,
            pixTax,
            status: 'pending',
            select_options: {
              ...selectOptions(),
              classroom_id: formatRecipients(),
              recurrent_product_id: recurrentProductIds(),
              recipientWalletOptions: recipientsWallet,
              kind: DISCOUNT_KINDS,
            },
          }}
          isSubmitting={sending}
          onSubmit={handleOnSubmit}
          backTo="/schools/school_products/tab/recurrent"
          titlePage="Novo plano de recorrência"
          titleModal="Descartar preenchimento"
          contentModal="O cadastro de um plano será cancelado e as informações preenchidas serão descartadas. Tem certeza que deseja descartar?"
        />
        <Toast />
      </Loader>
    </div>
  );
};

NewRecurrentPlan.propTypes = {
  appContext: PropTypes.shape({
    policies: PropTypes.shape({
      guaranteed_monthly_fee_enabled: PropTypes.bool,
      school_plan: PropTypes.bool,
    }),
  }).isRequired,
};

export default withAppContext(NewRecurrentPlan);
