import { ActionsParams, ActionsSaga } from './types';

const actions = {
  ERROR: 'ERROR/storage',
  SUCCESS: 'SUCCESS/storage',

  FETCH_RECIPIENTS_CLASSROOMS: 'FETCH_RECIPIENTS_CLASSROOMS/storage',
  FETCH_RECIPIENTS_CLASSROOMS_SUCCESS:
    'FETCH_RECIPIENTS_CLASSROOMS_SUCCESS/storage',

  SAVE_FOLDER_REQUEST: 'SAVE_FOLDER_REQUEST/storage',
  SAVE_FOLDER_SUCCESS: 'SAVE_FOLDER_SUCCESS/storage',
  SAVE_FOLDER_ERROR: 'SAVE_FOLDER_ERROR/storage',

  fetchRecipientsClassrooms: () => ({
    type: actions.FETCH_RECIPIENTS_CLASSROOMS,
  }),
  saveFolderRequest: ({
    title,
    description,
    personaType,
    folderAscendingId,
    sentKind,
    classroomIds,
    classroomWithStudentProfileIds,
  }: ActionsParams['saveFolderRequest']): ActionsSaga['saveFolderRequestSaga'] => {
    const availableTo: Record<
      ActionsParams['saveFolderRequest']['personaType'],
      ActionsSaga['saveFolderRequestSaga']['params']['availableTo']
    > = {
      both: 'family',
      students: 'student',
      responsibles: 'responsible',
    };

    const receiverType: Record<
      ActionsParams['saveFolderRequest']['sentKind'],
      ActionsSaga['saveFolderRequestSaga']['params']['receiverType']
    > = {
      students: 'student',
      classrooms: 'classroom',
    };

    return {
      type: actions.SAVE_FOLDER_REQUEST,
      params: {
        kind: 'folder',
        title,
        description,
        folderAscendingId,
        availableTo: availableTo[personaType],
        receiverType: receiverType[sentKind],
        classroomIds,
        classroomWithStudentProfileIds,
      },
    };
  },
};

export default actions;
