import validMimeType from 'core/utils/validMimeType';
import formatBytes from 'core/utils/formatBytes';

export default class AttachmentEntity {
  /**
   * @param {File} file
   */
  constructor(file) {
    this.fileObject = file;
  }

  isPresent() {
    return !!this.fileObject;
  }

  /**
   * @api public
   * @returns {String}
   */
  getName() {
    return this.fileObject.name;
  }

  /**
   * @api public
   * @returns {String}
   */
  getFormattedFileSize() {
    return formatBytes(this.fileSizeinBytes());
  }

  /**
   * @api public
   * @param {File} file  - The uploaded file.
   * @returns {String} The file type: document, image, video etc.
   */
  getType() {
    const fileTypeMapping = {
      application: 'document',
      image: 'image',
      video: 'play',
      audio: 'headphone',
    };
    const fileType = this.fileObject.type.substring(
      0,
      this.fileObject.type.indexOf('/')
    );
    return fileTypeMapping[fileType] || 'document';
  }

  /**
   * @api public
   *
   * NOTE: `url` only come from the server, not the file itself.
   */
  getUrl() {
    return this.fileObject.url;
  }

  /**
   * @api public
   * @return {File}
   */
  getUploadedFile() {
    return this.fileObject;
  }

  /**
   * @api public
   * @returns {Boolean}
   */
  isTypeValid(currentUserType) {
    return validMimeType(this.getFormattedType(), currentUserType);
  }

  /**
   * @api public
   * @returns {Boolean}
   */
  isSizeValid() {
    return this.fileSizeInMb() < 20;
  }

  /**
   * @api public
   * @returns {Boolean}
   */
  isSizeValid(currentUserType, currentSchool) {
    const maxAllowedSize = currentSchool.plan.upload_limit_in_bytes;
    if (currentUserType === 'SchoolUser') {
      return this.fileSizeinBytes() < maxAllowedSize;
    } else {
      return this.fileSizeInMb() < 20;
    }
  }

  /**
   * @api private
   */
  fileSizeInMb() {
    return this.fileSizeInKb() / 1024;
  }

  /**
   * @api private
   */
  fileSizeInKb() {
    return this.fileSizeinBytes() / 1024;
  }

  /**
   * @api private
   */
  fileSizeinBytes() {
    return this.fileObject.size;
  }

  /**
   * @api private
   */
  getFormattedType() {
    if (this.fileObject.type === '') {
      let fileName = this.fileObject.name.substring(
        this.fileObject.name.lastIndexOf('.'),
        this.fileObject.name.length
      );

      switch (fileName) {
        case '.doc':
          return 'application/msword';
        case '.docx':
          return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        case '.xls':
          return 'application/vnd.ms-excel';
        case '.xlsx':
          return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        case '.ppt':
          return 'application/vnd.ms-powerpoint';
        case '.pptx':
          return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
        default:
          return '';
      }
    }
    return this.fileObject.type;
  }
}
