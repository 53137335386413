import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const SectionsWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    flex: auto;
    padding-bottom: 30vh;

    > * {
      margin-bottom: ${space.lg};
    }
  `}
`;

export const RowWrapper = styled.div`
  ${({ theme: { space, breakpoints } }: ThemeProps) => css`
    display: flex;
    max-width: ${breakpoints.tabletMD};
    gap: ${space.lg};
  `};
`;
