import React, { useEffect, useMemo, useCallback } from 'react';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { IMAGES_CLASSROOMS_URL } from 'core/constants/index';
import DailySummariesActions from 'store/dailySummaries/actions';

import {
  CardList,
  FlexGrid,
  FlexGridItem,
  Box,
  SubtleIconButton,
} from '@agendaedu/ae-web-components';

import EmptyState from 'components/EmptyState';
import Loader from 'components/Loader';
import Skeleton from '../Skeleton';

import * as S from './styles';

const List = ({ history }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['diary', 'common']);
  const tBase = useCallback((key: string) => t(`list.${key}`), [t]);

  const dataArea = useSelector((state) => state.root.dataArea);

  const {
    classroomsList,
    isFetching,
    classroomPagination: { currentPage, itemsCountPerPage, totalItemsCount },
  } = useSelector((state) => state.dailySummaries);

  const hasMoreClassrooms = useMemo(() => {
    const numberOfItemsOnTheList = currentPage * itemsCountPerPage;

    return numberOfItemsOnTheList < totalItemsCount;
  }, [currentPage, itemsCountPerPage, totalItemsCount]);

  const { fetchClassroomsList } = DailySummariesActions;

  const fetchMoreClassrooms = () => {
    dispatch(fetchClassroomsList({ page: currentPage + 1 }));
  };

  const ACTIONS = (id) => [
    {
      as: 'button',
      title: tBase('actions.add'),
      onClick: (e) => getClassroomURL(id, e),
    },
    {
      as: 'button',
      title: tBase('actions.show'),
      onClick: (e) => getDetailsURLNewTab(id, e),
    },
  ];

  const getClassroomURL = (classroom, e) => {
    if (e && e.stopPropagation) e.stopPropagation();
    history.push(`/schools/daily_summaries/classrooms/${classroom}`);
  };

  const getDetailsURL = (classroom, e) => {
    if (e && e.stopPropagation) e.stopPropagation();
    history.push(`/schools/daily_summaries/classrooms/${classroom}/details`);
  };

  const getDetailsURLNewTab = (classroom, e) => {
    if (e && e.stopPropagation) e.stopPropagation();
    const win = window.open(
      `/${dataArea}/daily_summaries/classrooms/${classroom}/details`,
      '_blank'
    );
    win.focus();
  };

  if (hasMoreClassrooms.length <= 0) {
    return (
      <EmptyState
        imgUrl={IMAGES_CLASSROOMS_URL.emptyStateUrl}
        message={tBase('empty_state.message')}
      />
    );
  }

  useEffect(() => {
    dispatch(fetchClassroomsList({ page: 1 }));
  }, [dispatch, fetchClassroomsList]);

  return (
    <>
      {isFetching && !classroomsList.length ? (
        <Skeleton />
      ) : (
        <>
          {totalItemsCount > 0 && (
            <S.ClassroomsCounter>{`${totalItemsCount} ${
              totalItemsCount > 1
                ? tBase('common.classrooms')
                : tBase('common.classroom')
            }`}</S.ClassroomsCounter>
          )}

          <CardList
            hasMore={hasMoreClassrooms}
            loadMore={!isFetching && fetchMoreClassrooms}
            loader={
              <Loader
                key="classrooms-list-loader"
                aria-label={tBase('common.loading')}
              />
            }
            initialLoad={true}
            threshold={200}
            pageStart={1}
            mb={16}
          >
            {classroomsList.map((item, index) => {
              const {
                id,
                attributes: {
                  name,
                  headquarter_name: headquarterName,
                  educational_stage_name: educationalStageName,
                  filled_count: filledCount,
                  students_count: studentsCount,
                  last_updated: lastUpdated,
                },
              } = item;

              return (
                <>
                  <S.Row key={index} onClick={(e) => getDetailsURL(id, e)}>
                    <FlexGrid>
                      <FlexGridItem cols={{ desktopLG: 3, mobileSM: 2 }} filled>
                        <Box paddingRight={80}>
                          <S.Informations>
                            <S.labelTitle>
                              {tBase('card.classroom_title')}
                            </S.labelTitle>
                            <S.CardTitle>{name}</S.CardTitle>
                          </S.Informations>
                        </Box>
                      </FlexGridItem>

                      <FlexGridItem cols={{ desktopLG: 2, mobileSM: 2 }} filled>
                        <Box paddingRight={80}>
                          <S.Informations>
                            <S.labelTitle>
                              {tBase('card.headquarter_title')}
                            </S.labelTitle>
                            <S.CardTitle>{headquarterName}</S.CardTitle>
                          </S.Informations>
                        </Box>
                      </FlexGridItem>

                      <FlexGridItem cols={{ desktopLG: 2, mobileSM: 2 }} filled>
                        <Box paddingRight={80}>
                          <S.Informations>
                            <S.labelTitle>
                              {tBase('card.educational_stage_title')}
                            </S.labelTitle>
                            <S.CardTitle>{educationalStageName}</S.CardTitle>
                          </S.Informations>
                        </Box>
                      </FlexGridItem>

                      <FlexGridItem cols={{ desktopLG: 3 }} filled>
                        <Box
                          display="flex"
                          paddingRight={4}
                          alignItems="center"
                        >
                          <S.BodyContainer id={id}>
                            <S.UpdateContainer>
                              {lastUpdated && (
                                <>
                                  <S.labelTitle>
                                    {tBase('card.last_updated_title')}
                                  </S.labelTitle>
                                  <S.CardTitle title={lastUpdated}>
                                    {lastUpdated}
                                  </S.CardTitle>
                                </>
                              )}
                            </S.UpdateContainer>
                          </S.BodyContainer>
                        </Box>
                      </FlexGridItem>

                      <FlexGridItem cols={{ desktopLG: 1 }} filled>
                        <Box width="fit-content">
                          <S.labelTitle>
                            {tBase('card.filled_title')}
                          </S.labelTitle>
                          <S.CardTitle>
                            {t('list.card.filled_value', {
                              filled: filledCount,
                              students: studentsCount,
                            })}
                          </S.CardTitle>
                        </Box>
                      </FlexGridItem>

                      <FlexGridItem cols={{ desktopLG: 1 }} filled>
                        <Box position="absolute" top={20} right={16}>
                          <SubtleIconButton
                            align="right"
                            actions={ACTIONS(id)}
                          />
                        </Box>
                      </FlexGridItem>
                    </FlexGrid>
                  </S.Row>
                </>
              );
            })}
          </CardList>
        </>
      )}
    </>
  );
};

export default withRouter(List);
