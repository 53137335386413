/* eslint-disable import/no-unresolved */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Loader from 'components/Loader/index';

import PageContainer from 'components/PageContainer';
import PageSubTitle from 'components/PageSubTitle';
import Table from 'components/Table';
import Buttom from 'components/Button';
import ModalContainer from 'components/Modal/ModalContainer';
import ModalTitle from 'components/Modal/ModalTitle';
import FormCheckbox from 'components/Form/Checkbox';

import SchoolTermService from 'core/services/SchoolTerm';

import withAppContext from 'core/hoc/withAppContext';
import withToastMessage from 'core/hoc/withToastMessage';

import table from './table';

import './style.scss';

class SchoolTermsList extends Component {
  static propTypes = {
    appContext: PropTypes.shape({
      dataArea: PropTypes.string.isRequired,
    }).isRequired,
    toastActions: PropTypes.shape({
      showToast: PropTypes.func.isRequired,
    }).isRequired,
    toastState: PropTypes.shape({
      edited: PropTypes.bool,
    }),
    history: PropTypes.object,
  };

  static defaultProps = {
    toastState: {
      edited: false,
    },
  };

  state = {
    schoolTerms: [],
    isLoadingSchoolTerms: true,
    errorFetchingSchoolTerms: null,
    isModalOpen: false,
    checkActivateSchoolTerm: false,
    errorActiveSchoolTerm: null,
    selectedSchoolTerm: {},
  };

  componentDidMount() {
    this.loadSchoolTerms();
    this.displayToast();
  }

  onToggleClick = (schoolTerm) => {
    this.setState({ selectedSchoolTerm: schoolTerm, isModalOpen: true });
  };

  displayToast = () => {
    const {
      toastState: { edited },
      toastActions: { showToast },
    } = this.props;
    if (edited)
      showToast(
        'As informações do período foram atualizadas com sucesso.',
        'success'
      );
    this.clearToastState(); // need to clear this so toast doesn't apper again on page refresh
  };

  clearToastState = () => {
    const { history } = this.props;

    history.replace({
      pathname: '/schools/school_terms',
      state: {},
    });
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen,
      checkActivateSchoolTerm: false,
    }));
  };

  toggleCheckbox = () => {
    this.setState((prevState) => ({
      checkActivateSchoolTerm: !prevState.checkActivateSchoolTerm,
    }));
  };

  loadSchoolTerms = async () => {
    const {
      appContext: { dataArea },
    } = this.props;
    const schoolTermService = new SchoolTermService(dataArea);

    try {
      const { schoolTerms } = await schoolTermService.fetchSchoolTerms();
      this.setState({ schoolTerms });
    } catch (error) {
      this.setState({ errorFetchingSchoolTerms: error });
    } finally {
      this.setState({ isLoadingSchoolTerms: false });
    }
  };

  activateSchoolTerm = async () => {
    const {
      appContext: { dataArea },
      toastActions: { showToast },
    } = this.props;

    const { selectedSchoolTerm } = this.state;

    this.setState({ isModalOpen: false, isLoadingSchoolTerms: true });

    const schoolTermService = new SchoolTermService(dataArea);

    try {
      const { schoolTerm, oldSchoolTermActive } =
        await schoolTermService.activeSchoolTerm(selectedSchoolTerm.id);

      this.updateSchoolTermWith(schoolTerm, oldSchoolTermActive);
      showToast(
        'O novo período letivo foi ativado com sucesso, agora você pode realizar a progressão dos alunos.',
        'success'
      );
    } catch (errorActiveSchoolTerm) {
      const fallback = () => {
        if (errorActiveSchoolTerm.response) {
          showToast(
            'Ocorreu um erro ao tentar ativar o período letivo. Se o erro persistir, aguarde um instante e tente novamente.'
          );
        }
      };
    } finally {
      this.setState({ isModalOpen: false, isLoadingSchoolTerms: false });
    }
  };

  /**
   * @param schoolTerm.id {string}
   * @param schoolTerm.attributes {object}
   * @param oldSchoolTermActive.id {string}
   * @param oldSchoolTermActive.attributes {object}
   */
  updateSchoolTermWith = (ActiveSchoolTerm, oldSchoolTermActive) => {
    let { schoolTerms } = this.state;

    schoolTerms = schoolTerms.map((schoolTerm) => {
      if (ActiveSchoolTerm.id == schoolTerm.id) {
        schoolTerm.attributes = ActiveSchoolTerm.attributes;
      }

      if (oldSchoolTermActive.id == schoolTerm.id) {
        schoolTerm.attributes = oldSchoolTermActive.attributes;
      }
      return schoolTerm;
    });

    this.setState({ schoolTerms });
  };

  footer = (schoolTerms) => {
    const disableCreate = schoolTerms
      .map((schoolTerm) => schoolTerm.attributes.can_be_activated)
      .includes(true);

    return (
      <React.Fragment>
        <a href="/">
          <Buttom variation="secondary">Fechar</Buttom>
        </a>
        <Link to="/schools/school_terms/new">
          <Buttom disabled={disableCreate}>Criar período letivo</Buttom>
        </Link>
      </React.Fragment>
    );
  };

  render() {
    const {
      schoolTerms,
      isLoadingSchoolTerms,
      errorFetchingSchoolTerms,
      isModalOpen,
      checkActivateSchoolTerm,
      selectedSchoolTerm,
    } = this.state;

    if (errorFetchingSchoolTerms) throw errorFetchingSchoolTerms;

    const canEditActive = Boolean(
      schoolTerms.filter(
        (st) => !st.attributes.status && !st.attributes.can_be_activated
      ).length
    );

    return (
      <div className="ListSchoolTerms">
        <PageContainer
          title="Períodos letivos"
          footer={this.footer(schoolTerms)}
          variation="centered"
        >
          <PageSubTitle>Gerenciar períodos letivos</PageSubTitle>
          <span className="info">
            Você deve criar um novo período letivo para progredir os alunos para
            suas novas turmas.
          </span>
          <Loader isLoading={isLoadingSchoolTerms}>
            <Table
              columns={table(this.onToggleClick, canEditActive)}
              data={schoolTerms}
            />
          </Loader>
        </PageContainer>
        {isModalOpen && (
          <ModalContainer
            isOpen={isModalOpen && selectedSchoolTerm}
            toggleModal={this.toggleModal}
            className="activate-school-term-modal"
          >
            <ModalTitle>
              Ativar período letivo {selectedSchoolTerm.attributes.name}
            </ModalTitle>
            <p>
              <strong>
                Não será possível reativar o período atual, portanto, só ative o
                novo período após finalizar o período atual.
              </strong>
            </p>
            <p>
              A visualização das informações ficarão por padrão em{' '}
              {selectedSchoolTerm.attributes.name}, e tudo que foi enviado no
              período atual só estará disponível para consulta pelo filtro de
              ano.
            </p>
            <div className="agree-terms">
              <FormCheckbox
                checked={checkActivateSchoolTerm}
                onChange={this.toggleCheckbox}
              />
              <span>Estou ciente e desejo continuar com a ativação.</span>
            </div>
            <div className="actions">
              <Buttom
                disabled={!checkActivateSchoolTerm}
                onClick={this.activateSchoolTerm}
              >
                Ativar período letivo
              </Buttom>
              <Buttom variation="default" onClick={this.toggleModal}>
                Cancelar
              </Buttom>
            </div>
          </ModalContainer>
        )}
      </div>
    );
  }
}

export default withAppContext(withToastMessage(SchoolTermsList));
