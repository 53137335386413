import React, { useEffect } from 'react';
import { GroupInformationStepProps } from './types';
import * as S from './styles';

import tabifyForWizard from 'core/hoc/tabifyForWizard';
import withFormContext from 'core/hoc/withFormContext';

import Field from 'components/Form/Field';
import Title from 'components/WizardForm/Title';

const GroupInformationStep = ({
  formContext: {
    form: { name },
    changeMeta,
  },
}: GroupInformationStepProps) => {
  useEffect(() => {
    !name
      ? changeMeta('nextStepDisabled', true)
      : changeMeta('nextStepDisabled', false);
  }, [name, changeMeta]);

  return (
    <S.GroupInformationStepWrapper>
      <S.RowWrapper>
        <Title name="Informações gerais" />
        <Field
          label="Nome do grupo*"
          placeholder="ex: “Professores do quinto ano”"
          attributeName="name"
        />
        <Field
          label="Descrição do grupo"
          placeholder="ex: “Grupo para comunicação interna entre os professores”"
          attributeName="description"
        />
      </S.RowWrapper>
    </S.GroupInformationStepWrapper>
  );
};

export default tabifyForWizard({
  title: '1 Informações',
})(withFormContext(GroupInformationStep));
