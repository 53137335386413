import styled, { css } from 'styled-components';

import Button from 'components/Button';

import { ThemeProps } from 'config/themes/types';

export const GroupHeaderContainer = styled.div`
  ${({ theme: { border, colors } }: ThemeProps) => css`
    padding: 14px 24px 24px;
    max-height: 142px;

    background: ${colors.neutral.white};
    border-bottom: ${border.width.sm} ${border.style.solid}
      ${colors.neutral.gray4};

    display: flex;
    flex-direction: column;
    gap: 18px;
  `}
`;

export const GroupHeaderTitle = styled.h2`
  ${({ theme: { colors, typography } }: ThemeProps) => css`
    margin: 0px;
    ${typography.headline.H2Bold24};
    color: ${colors.neutral.black};

    & ~ a {
      max-width: 153px;
    }
  `}
`;

export const GroupHeaderButton = styled(Button)`
  ${({ theme: { border, font } }: ThemeProps) => css`
    width: 100%;
    height: 40px;
    padding: 12px 16px;

    font-family: ${font.family.brand}, sans-serif;
    font-size: ${font.size.md};
    font-weight: ${font.weight.bold};
    letter-spacing: ${font.spacing.normal};

    border-radius: ${border.radius.md};

    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;

    .AgendaIcon {
      font-size: ${font.size.xl};
      line-height: 100%;
    }
  `}
`;
