import styled from 'styled-components';
import Tooltip from 'components/Tooltip';

export const CustomLabel = styled.div`
  display: flex;
  align-items: center;
  > span {
    margin-right: 2px !important;
  }
`;

export const CustomTooltip = styled(Tooltip)`
  .tooltip-div {
    position: relative;
    width: 16px;
    height: 16px;
  }
`;
