import moment from 'moment';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { fetchApi, postApi, patchApi, deleteApi, putApi } from 'core/utils/api';

import { push } from 'connected-react-router';

import {
  getCurrentHandoutData,
  getHandoutsData,
  getEngagementsData,
  normalizeParams,
  queryUrlFiltered,
  getEngagementFilters,
  getCoverFileImage,
  handoutTemplateNormalizeParams,
  fetchHandoutTemplatesNormalize,
  editHandoutTemplateNormalizeParams,
} from 'core/helper/handouts';

import {
  buildToast,
  toastCss,
  ToastTypes,
} from 'store/middlewares/toast/actions';

import actions from './actions';

import i18n from 'config/i18n';

import {
  ActionsHandoutSagaProps,
  CreateNewHandoutSagaProps,
  CreateNewCategorySagaProps,
  FetchHandoutsSagaProps,
  FetchHandoutSagaProps,
  EditHandoutSagaProps,
  DeleteCategorySagaProps,
  FetchHandoutEngagementSagaProps,
  DuplicateHandoutAttachmentsSagaProps,
  CreateNewHandoutTemplateSagaProps,
  FetchHandoutTemplatesSagaProps,
  DeleteHandoutTemplateSagaProps,
  ApplyHandoutTemplateSagaProps,
  FetchCurrentHandoutTemplateSagaProps,
} from './types';

function* createNewHandoutRequest({ params }: CreateNewHandoutSagaProps) {
  const { form, duplicate } = params;

  try {
    const { dataArea } = yield select((state) => state.root);
    const payload = normalizeParams(form);

    let dataRequest = null;

    if (duplicate || form?.templateId) {
      dataRequest = yield call(
        postApi,
        `/${dataArea}/handouts/create_v2`,
        payload
      );
    } else {
      dataRequest = yield call(postApi, `/${dataArea}/handouts.json`, payload);
    }

    const newHandoutCreated = dataRequest.data;

    yield put(push(`/${dataArea}/handouts/${newHandoutCreated.id}`));

    yield put({
      type: actions.CREATE_NEW_HANDOUT_SUCCESS,
      toast: buildToast(
        `O comunicado "${form.title}" foi criado com sucesso.`,
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        `Ops, houve um erro ao criar o comunicado "${form.title}". Tente novamente!`,
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* createNewHandoutTemplateRequest({
  params,
}: CreateNewHandoutTemplateSagaProps) {
  const { form } = params;

  try {
    const { dataArea } = yield select((state) => state.root);
    const payload = handoutTemplateNormalizeParams(form);

    yield call(postApi, `/${dataArea}/handouts/templates`, payload);

    yield put(push(`/${dataArea}/handouts/templates`));

    yield put({
      type: actions.CREATE_NEW_HANDOUT_TEMPLATE_SUCCESS,
      toast: buildToast(
        i18n.t('handouts:templates.toast.success_create'),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        error.status === 422
          ? error.data.errors[0].detail
          : i18n.t('handouts:templates.toast.error_create', {
              title: form.templateTitle,
            }),

        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* duplicateHandoutAttachmentsRequest({
  attachments,
}: DuplicateHandoutAttachmentsSagaProps) {
  try {
    const newAttachments = [];

    for (let index = 0; index < attachments.length; index++) {
      const data = yield call(
        postApi,
        `/schools/direct_uploads/${attachments[index].signedId}/copy.json`
      );

      newAttachments.push({
        signedId: data.signed_id,
        url: data.url,
      });
    }
    yield put({
      type: actions.DUPLICATE_HANDOUT_ATTACHMENTS_SUCCESS,
      attachments: newAttachments,
      toast: buildToast(
        `Os anexos foram carregados com sucesso`,
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        `Ops, houve um erro ao carregar os anexos. Tente novamente!`,
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* approveHandoutRequest({ handout }: ActionsHandoutSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data } = yield call(
      putApi,
      `/${dataArea}/handouts/${handout.id}/approve.json`
    );

    yield put({
      type: actions.APPROVE_HANDOUT_SUCCESS,
      data,
      toast: buildToast(
        `Aguarde mais um pouco, logo o seu comunicado será aprovado.`,
        ToastTypes.info,
        toastCss(ToastTypes.info)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        `Ops, houve um erro ao aprovar o comunicado ${handout.title}. Tente novamente!`,
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* approveScheduleHandoutRequest({ handout }: ActionsHandoutSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data, included } = yield call(
      putApi,
      `/${dataArea}/handouts/${handout.id}/approve_schedule.json?include=versions`
    );

    yield put({
      type: actions.APPROVE_SCHEDULE_HANDOUT_SUCCESS,
      data: getCurrentHandoutData(data, included),
      toast: buildToast(
        `O agendamento do comunicado ${handout.title} foi aprovado com sucesso.`,
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        `Ops, houve um erro ao aprovar o agendamento do comunicado ${handout.title}. Tente novamente!`,
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* cancelScheduleHandoutRequest({ handout }: ActionsHandoutSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data, included } = yield call(
      putApi,
      `/${dataArea}/handouts/${handout.id}/cancel_schedule.json?include=versions`
    );

    yield put({
      type: actions.CANCEL_SCHEDULE_HANDOUT_SUCCESS,
      data: getCurrentHandoutData(data, included),
      toast: buildToast(
        `O agendamento do comunicado ${handout.title} foi cancelado e este se encontra na lista de pendentes.`,
        ToastTypes.info,
        toastCss(ToastTypes.info)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        `Ops, houve um erro ao tentar cancelar o agendamento do comunicado ${handout.title}. Tente novamente! `,
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* scheduleHandoutRequest({ handout }: ActionsHandoutSagaProps) {
  const { id, title, dateSchedule, timeSchedule } = handout;

  try {
    const { dataArea } = yield select((state) => state.root);

    const { data, included } = yield call(
      putApi,
      `/${dataArea}/handouts/${id}/schedule.json?include=versions`,
      { date: dateSchedule, hour: timeSchedule }
    );

    yield put({
      type: actions.SCHEDULE_HANDOUT_SUCCESS,
      data: getCurrentHandoutData(data, included),
      toast: buildToast(
        `O envio do comunicado ${title} foi agendado para ${moment(
          dateSchedule
        ).format('DD/MM/YYYY')}, às ${timeSchedule}.`,
        ToastTypes.info,
        toastCss(ToastTypes.info)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Ops, não é permitido agendar um comunicado para uma data anterior a data de hoje.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* deleteHandoutRequest({ handout }: ActionsHandoutSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    yield call(deleteApi, `/${dataArea}/handouts/${handout.id}.json`);

    yield put(push(`/${dataArea}/handouts`));

    yield put({
      type: actions.DELETE_HANDOUT_SUCCESS,
      toast: buildToast(
        `O comunicado "${handout.title}" foi excluído com sucesso. `,
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        `Ops, houve um erro ao excluir o comunicado "${handout.title}". Tente novamente! `,
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* deleteHandoutTemplateRequest({
  handoutTemplateId,
}: DeleteHandoutTemplateSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);
    yield call(
      deleteApi,
      `/${dataArea}/handouts/templates/${handoutTemplateId}`
    );
    yield put({
      type: actions.DELETE_HANDOUT_TEMPLATE_SUCCESS,
      toast: buildToast(
        i18n.t('handouts:templates.toast.success_delete'),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('handouts:templates.toast.error_delete'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* resendHandoutRequest({ handout }: ActionsHandoutSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    yield call(putApi, `/${dataArea}/handouts/${handout.id}/resend.json`);

    yield put({
      type: actions.RESEND_HANDOUT_SUCCESS,
      toast: buildToast(
        `O comunicado ${handout.title} foi reenviado com sucesso.`,
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        `Ops, houve um erro ao reenviar o comunicado ${handout.title}. Tente novamente! `,
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* createNewCategoryRequest({
  name,
  isForm,
}: CreateNewCategorySagaProps) {
  try {
    const { data } = yield call(postApi, `/schools/handout_categories.json`, {
      name,
    });

    if (isForm) {
      yield put(push(`/schools/handout_categories`));
    }

    yield put({
      type: actions.CREATE_NEW_CATEGORY_SUCCESS,
      newCategoryCreated: data,
      toast: buildToast(
        `A categoria "${name}" foi criada com sucesso.`,
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        `Ops, houve um erro ao criar a categoria "${name}": ${error.data.errors[0][1][0]}. Tente novamente!`,
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* fetchCategoryDetailsRequest({ id }: EditHandoutSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data } = yield call(
      fetchApi,
      `/${dataArea}/handout_categories/${id}.json`
    );

    yield put({
      type: actions.FETCH_CATEGORY_DETAILS_SUCCESS,
      category: data,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        `Ops, houve um erro ao obter os detalhes da categoria. Tente novamente!`,
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* applyHandoutTemplateRequest({
  handoutTemplateId,
}: ApplyHandoutTemplateSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);
    const { data, included } = yield call(
      fetchApi,
      `/${dataArea}/handouts/templates/${handoutTemplateId}`
    );

    const handoutTemplate = fetchHandoutTemplatesNormalize([data], included)[0];

    const coverImage = yield getCoverFileImage(
      handoutTemplate.attributes.coverImage,
      false
    );

    yield put({
      type: actions.APPLY_HANDOUT_TEMPLATE_SUCCESS,
      data: {
        ...handoutTemplate,
        attributes: {
          ...handoutTemplate.attributes,
          coverImage,
        },
      },
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('handouts:templates.toast.error_new_handout'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* editCategoryRequest({
  category: { id, label },
}: DeleteCategorySagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data } = yield call(
      patchApi,
      `/${dataArea}/handout_categories/${id}`,
      { name: label }
    );

    yield put(push(`/schools/handout_categories`));

    yield put({
      type: actions.EDIT_CATEGORY_SUCCESS,
      category: data,
      toast: buildToast(
        `A categoria "${label}" foi alterada com sucesso.`,
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        `Ops, houve um erro ao atualizar categoria. Tente novamente!`,
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* fetchCategoriesRequest() {
  try {
    const { data, meta } = yield call(
      fetchApi,
      `/schools/handout_categories.json?page[size]=500`
    );

    yield put({
      type: actions.FETCH_CATEGORIES_SUCCESS,
      categories: data,
      totalPages: meta.totalPages,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        `Ocorreu um erro ao obter a listagem de categorias. Tente novamente!`,
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* fetchCurrentHandoutTemplateRequest({
  handoutTemplateId,
}: FetchCurrentHandoutTemplateSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data, included } = yield call(
      fetchApi,
      `/${dataArea}/handouts/templates/${handoutTemplateId}`
    );

    const handoutTemplate = fetchHandoutTemplatesNormalize([data], included)[0];

    const coverImage = yield getCoverFileImage(
      handoutTemplate.attributes.coverImage,
      true
    );

    yield put({
      type: actions.FETCH_CURRENT_HANDOUT_TEMPLATE_SUCCESS,
      data: {
        ...handoutTemplate,
        attributes: {
          ...handoutTemplate.attributes,
          coverImage,
        },
      },
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('handouts:templates.toast.error_current_template'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* fetchMoreCategoriesRequest() {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { itemsPerPage, page } = yield select(
      (state) => state.handouts.pagination
    );

    const { data, meta } = yield call(
      fetchApi,
      `/${dataArea}/handout_categories.json?page[number]=${page}&page[size]=${itemsPerPage}`
    );

    yield put({
      type: actions.FETCH_MORE_CATEGORIES_SUCCESS,
      categories: data,
      totalPages: meta.totalPages,
      page,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        `Ops, houve um erro ao listar as categorias. Tente novamente!`,
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* fetchHandoutsRequest({ params }: FetchHandoutsSagaProps) {
  try {
    const {
      page,
      startDate,
      endDate,
      situation,
      title,
      headquarterId,
      educationalStageId,
      classroomId,
      categoryId,
      activeSchoolTermId,
    } = params;

    const filters = queryUrlFiltered(params);

    const { dataArea } = yield select((state) => state.root);

    const { data, included, meta } = yield call(
      fetchApi,
      `/${dataArea}/handouts.json?fields[category]=name&fields[classroom]=name&fields[handout]=createdFor,status,title,userPermissions,createdAt,updatedAt,sendAt,deletedAt,classrooms,category,student_profiles&fields[student_profile]=name&${filters}&include=category,classrooms,student_profiles&page[number]=${page}&page[size]=10`
    );

    const handouts = getHandoutsData(data, included, meta);

    yield put({
      type: actions.FETCH_HANDOUTS_SUCCESS,
      handouts,
      pagination: { page, totalPages: meta.totalPages },
      filters: {
        startDate,
        endDate,
        situation,
        title,
        headquarterId,
        educationalStageId,
        classroomId,
        categoryId,
        activeSchoolTermId,
      },
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        `Ops, houve um erro ao obter a listagem de comunicados. Tente novamente!`,
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* fetchMoreHandoutsRequest({ params }: FetchHandoutsSagaProps) {
  try {
    const {
      page,
      startDate,
      endDate,
      situation,
      title,
      headquarterId,
      educationalStageId,
      classroomId,
      categoryId,
      activeSchoolTermId,
    } = params;

    const { dataArea } = yield select((state) => state.root);

    const filters = queryUrlFiltered(params);

    const { data, included, meta } = yield call(
      fetchApi,
      `/${dataArea}/handouts.json?fields[category]=name&fields[classroom]=name&fields[handout]=createdFor,status,title,userPermissions,createdAt,updatedAt,sendAt,classrooms,category,student_profiles&fields[student_profile]=name&${filters}&include=category,classrooms,student_profiles&page[number]=${page}&page[size]=10`
    );

    const handouts = getHandoutsData(data, included, meta);

    yield put({
      type: actions.FETCH_MORE_HANDOUTS_SUCCESS,
      handouts,
      pagination: { page },
      filters: {
        startDate,
        endDate,
        situation,
        title,
        headquarterId,
        educationalStageId,
        classroomId,
        categoryId,
        activeSchoolTermId,
      },
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        `Ops, houve um erro ao obter a listagem de comunicados. Tente novamente!`,
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* fetchCurrentHandoutRequest({ params }: FetchHandoutSagaProps) {
  const { id, duplicate } = params;
  try {
    const { dataArea } = yield select((state) => state.root);
    let data;

    const {
      data: handoutData,
      included,
      meta,
    } = yield call(
      fetchApi,
      `/${dataArea}/handouts/${id}.json?include=classrooms,versions,student_profiles&fields[classroom]=name&fields[student_profile]=avatarColor,name,photoUrl,shouldUseNameInitialsAvatar,nameInitials,role`
    );

    const coverImage = handoutData?.attributes.coverImage;

    if (duplicate && coverImage) {
      const file = yield getCoverFileImage(coverImage);

      data = getCurrentHandoutData(
        {
          ...handoutData,
          attributes: {
            ...handoutData.attributes,
            coverImage: file,
          },
        },
        included
      );
    } else {
      data = getCurrentHandoutData(handoutData, included);
    }

    if (duplicate && data?.attributes.attachments.length) {
      yield put({
        type: actions.DUPLICATE_HANDOUT_ATTACHMENTS_REQUEST,
        attachments: data.attributes.attachments,
        toast: buildToast(
          `os anexos estão sendo carregados, por favor aguarde mais um pouco`,
          ToastTypes.info,
          toastCss(ToastTypes.info)
        ),
      });
    }

    yield put({
      type: actions.FETCH_HANDOUT_SUCCESS,
      data,
      meta,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        `Ops, houve um erro ao obter os dados do comunicado. Tente novamente!`,
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* fetchHandoutEngagementRequest({
  params,
}: FetchHandoutEngagementSagaProps) {
  try {
    const {
      current: { id, sendTo },
      page,
      profile = 'responsibles',
      status,
      classroomId,
    } = params;

    const { filters, profileType } = getEngagementFilters({
      profile,
      status,
      classroomId,
      sendTo,
    });

    const { dataArea } = yield select((state) => state.root);

    const {
      data,
      included,
      meta: { totalPages },
    } = yield call(
      fetchApi,
      `/${dataArea}/handouts/${id}/engagement.json?include=student_profile,responsible_profile,classroom&fields[student_profile]=avatarColor,name,photoUrl,shouldUseNameInitialsAvatar,nameInitials,role&fields[responsible_profile]=avatarColor,name,photoUrl,shouldUseNameInitialsAvatar,nameInitials,role${filters}&page[number]=${page}&page[size]=10`
    );

    yield put({
      type: actions.FETCH_HANDOUT_ENGAGEMENT_SUCCESS,
      data: getEngagementsData(data, included, profileType),
      pagination: { page, totalPages },
      filters: { profile: profileType, status, classroomId },
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

function* fetchHandoutTemplatesRequest({
  params,
}: FetchHandoutTemplatesSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { page: currentPage } = params;
    const {
      data,
      included,
      meta: { page, next },
    } = yield call(
      fetchApi,
      `/${dataArea}/handouts/templates.json?page[size]=20&page[number]=${currentPage}`
    );

    const templates = fetchHandoutTemplatesNormalize(data, included);

    yield put({
      type: actions.FETCH_HANDOUT_TEMPLATES_SUCCESS,
      data: templates,
      page,
      next,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

function* fetchMoreHandoutEngagementRequest({
  params,
}: FetchHandoutEngagementSagaProps) {
  try {
    const {
      current: { id, sendTo },
      page,
      profile = 'responsibles',
      status,
      classroomId,
    } = params;

    const { filters, profileType } = getEngagementFilters({
      profile,
      status,
      classroomId,
      sendTo,
    });

    const { dataArea } = yield select((state) => state.root);

    const {
      data,
      included,
      meta: { totalPages },
    } = yield call(
      fetchApi,
      `/${dataArea}/handouts/${id}/engagement.json?include=student_profile,responsible_profile,classroom&fields[student_profile]=avatarColor,name,photoUrl,shouldUseNameInitialsAvatar,nameInitials,role&fields[responsible_profile]=avatarColor,name,photoUrl,shouldUseNameInitialsAvatar,nameInitials,role${filters}&page[number]=${page}&page[size]=10`
    );

    yield put({
      type: actions.FETCH_MORE_HANDOUT_ENGAGEMENT_SUCCESS,
      data: getEngagementsData(data, included, profileType),
      pagination: { page, totalPages },
      filters: { profile: profileType, status, classroomId },
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

function* editHandoutRequest({ id, params }: EditHandoutSagaProps) {
  const { title, notify, status } = params;

  try {
    const { dataArea } = yield select((state) => state.root);

    const payload = normalizeParams(params);

    const { data: editedHandout } = yield call(
      patchApi,
      `/${dataArea}/handouts/${id}.json`,
      payload
    );

    yield put(push(`/${dataArea}/handouts/${editedHandout.id}`));

    if (status === 'sent') {
      if (notify) {
        yield put({
          type: actions.EDIT_HANDOUT_SUCCESS,
          data: editedHandout,
          toast: buildToast(
            `O comunicado "${title}" foi atualizado e os remetentes serão notificados da atualização.`,
            ToastTypes.info,
            toastCss(ToastTypes.info)
          ),
        });
      } else {
        yield put({
          type: actions.EDIT_HANDOUT_SUCCESS,
          data: editedHandout,
          toast: buildToast(
            `O comunicado "${title}" foi atualizado, mas os remetentes não serão notificados da atualização.`,
            ToastTypes.warning,
            toastCss(ToastTypes.warning)
          ),
        });
      }
    } else {
      yield put({
        type: actions.EDIT_HANDOUT_SUCCESS,
        data: editedHandout,
        toast: buildToast(
          `O comunicado ${title} foi atualizado, mas ainda está pendente de aprovação.`,
          ToastTypes.info,
          toastCss(ToastTypes.info)
        ),
      });
    }
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        `Ops, houve um erro ao editar o comunicado "${title}". Tente novamente!`,
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* editHandoutTemplateRequest({
  handoutTemplate,
  id,
}: EditHandoutSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const payload = editHandoutTemplateNormalizeParams(handoutTemplate);

    yield call(patchApi, `/${dataArea}/handouts/templates/${id}`, payload);

    yield put(push(`/${dataArea}/handouts/templates`));

    yield put({
      type: actions.EDIT_HANDOUT_TEMPLATE_SUCCESS,
      toast: buildToast(
        i18n.t('handouts:templates.toast.success_edit'),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('handouts:templates.toast.error_edit'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}
function* fetchClassroomsRequest() {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { history } = yield call(
      fetchApi,
      `/${dataArea}/student_profiles/classrooms.json`
    );

    yield put({
      type: actions.FETCH_CLASSROOMS_SUCCESS,
      classrooms: history,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        `Ops, houve um erro ao obter a listagem de turmas. Tente novamente!`,
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* deleteCategory({ category: { id, label } }: DeleteCategorySagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    yield call(deleteApi, `/${dataArea}/handout_categories/${id}`);

    yield put({
      type: actions.DELETE_CATEGORY_SUCCESS,
      toast: buildToast(
        `A categoria ${label} foi excluída com sucesso. `,
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });

    yield put({
      type: actions.FETCH_MORE_CATEGORIES_REQUEST,
      page: 1,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        `Ops, houve um erro ao excluir a categoria ${label}. Tente novamente!`,
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* resendMassHandoutsRequest() {
  try {
    yield call(putApi, `/schools/handouts/mass_resend.json`);

    yield put({
      type: actions.RESEND_MASS_SUCCESS,
      toast: buildToast(
        `Os comunicados foram reenviados para aqueles que ainda não receberam.`,
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        `Ops, houve um erro ao tentar reenviar os comunicados.`,
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* handoutsSagas() {
  yield all([
    takeLatest(actions.CREATE_NEW_CATEGORY_REQUEST, createNewCategoryRequest),
    takeLatest(actions.CREATE_NEW_HANDOUT_REQUEST, createNewHandoutRequest),
    takeLatest(
      actions.CREATE_NEW_HANDOUT_TEMPLATE_REQUEST,
      createNewHandoutTemplateRequest
    ),
    takeLatest(
      actions.DUPLICATE_HANDOUT_ATTACHMENTS_REQUEST,
      duplicateHandoutAttachmentsRequest
    ),
    takeLatest(actions.APPROVE_HANDOUT_REQUEST, approveHandoutRequest),
    takeLatest(
      actions.APPROVE_SCHEDULE_HANDOUT_REQUEST,
      approveScheduleHandoutRequest
    ),
    takeLatest(
      actions.CANCEL_SCHEDULE_HANDOUT_REQUEST,
      cancelScheduleHandoutRequest
    ),
    takeLatest(actions.SCHEDULE_HANDOUT_REQUEST, scheduleHandoutRequest),
    takeLatest(actions.DELETE_HANDOUT_REQUEST, deleteHandoutRequest),
    takeLatest(
      actions.DELETE_HANDOUT_TEMPLATE_REQUEST,
      deleteHandoutTemplateRequest
    ),
    takeLatest(actions.RESEND_HANDOUT_REQUEST, resendHandoutRequest),
    takeLatest(actions.FETCH_CATEGORIES_REQUEST, fetchCategoriesRequest),
    takeLatest(
      actions.FETCH_CURRENT_HANDOUT_TEMPLATE_REQUEST,
      fetchCurrentHandoutTemplateRequest
    ),
    takeLatest(
      actions.FETCH_CATEGORY_DETAILS_REQUEST,
      fetchCategoryDetailsRequest
    ),
    takeLatest(
      actions.APPLY_HANDOUT_TEMPLATE_REQUEST,
      applyHandoutTemplateRequest
    ),
    takeLatest(actions.EDIT_CATEGORY_REQUEST, editCategoryRequest),
    takeLatest(
      actions.FETCH_MORE_CATEGORIES_REQUEST,
      fetchMoreCategoriesRequest
    ),
    takeLatest(actions.FETCH_HANDOUTS_REQUEST, fetchHandoutsRequest),
    takeLatest(actions.FETCH_MORE_HANDOUTS_REQUEST, fetchMoreHandoutsRequest),
    takeLatest(actions.FETCH_HANDOUT_REQUEST, fetchCurrentHandoutRequest),
    takeLatest(
      actions.FETCH_HANDOUT_ENGAGEMENT_REQUEST,
      fetchHandoutEngagementRequest
    ),
    takeLatest(
      actions.FETCH_HANDOUT_TEMPLATES_REQUEST,
      fetchHandoutTemplatesRequest
    ),
    takeLatest(
      actions.FETCH_MORE_HANDOUT_ENGAGEMENT_REQUEST,
      fetchMoreHandoutEngagementRequest
    ),
    takeLatest(actions.EDIT_HANDOUT_REQUEST, editHandoutRequest),
    takeLatest(
      actions.EDIT_HANDOUT_TEMPLATE_REQUEST,
      editHandoutTemplateRequest
    ),
    takeLatest(actions.FETCH_CLASSROOMS_REQUEST, fetchClassroomsRequest),
    takeLatest(actions.DELETE_CATEGORY_REQUEST, deleteCategory),
    takeLatest(actions.RESEND_MASS_REQUEST, resendMassHandoutsRequest),
  ]);
}

export default handoutsSagas;
