import React, { memo, useEffect, useMemo, useState } from 'react';
import { Props } from './types';
import {
  Box,
  Button,
  DefaultThemeProps,
  Icon,
  Text,
} from '@agendaedu/ae-web-components';
import formatBytes from 'core/utils/formatBytes';
import * as S from './styles';
import { UploadStatus } from 'core/contexts/UploadFiles/types';
import { useTheme } from 'styled-components';

const FileItemComponent = ({ file, uploadFile, destroyFile }: Props) => {
  const isAlreadyUploaded = file.signedId;

  const isValidFile = !file.invalidReasons?.length;
  const { colors, space } = useTheme() as DefaultThemeProps;
  const initialUploadStatus = isAlreadyUploaded
    ? 'uploaded'
    : !isValidFile
    ? 'invalid'
    : 'uploading';
  const [uploadStatus, setUploadStatus] =
    useState<UploadStatus>(initialUploadStatus);

  const imageUrl = useMemo(() => {
    if (file.origin === 'local')
      return URL.createObjectURL(file as unknown as MediaSource);
    if (file.origin === 'google_drive')
      return `https://drive.google.com/thumbnail?sz=w32&id=${file.id}`;

    return file.url;
  }, [file]);

  const handleUpload = () => {
    if (isValidFile && !file.signedId) {
      setUploadStatus('uploading');

      uploadFile({
        file,
        onSuccess: () => setUploadStatus('success'),
        onFailed: () => setUploadStatus('failed'),
      });
    }
  };

  const handleDelete = () => {
    setUploadStatus('deleting');

    destroyFile({ fileId: file.fileId, signedId: file.signedId });
  };

  const variantStatus = {
    uploading: {
      color: colors.neutral.gray1,
      statusComponent: <S.LoadSpinner />,
    },
    success: {
      color: colors.neutral.gray1,
      statusComponent: (
        <Icon name="check" size="sm" color={colors.context.success.default} />
      ),
    },
    failed: {
      color: colors.context.warning.default,
      statusComponent: (
        <Button
          isOnlyIcon
          icon="cycle"
          variant="secondary"
          size="sm"
          contextVariant="warning"
          onClick={handleUpload}
        />
      ),
    },
    deleting: {
      color: colors.neutral.gray1,
      statusComponent: <S.LoadSpinner uploadStatus={uploadStatus} />,
    },
    invalid: {
      color: colors.neutral.gray1,
      statusComponent: file.invalidReasons?.map((reason) => (
        <Box key={reason}>
          <Text
            color={colors.context.alert.default}
            variant="subtitle-medium-12"
            m={0}
            mt="xs2"
          >
            - {reason}
          </Text>
        </Box>
      )),
    },
    uploaded: {
      color: colors.neutral.gray1,
    },
  }[uploadStatus];

  useEffect(() => {
    handleUpload();
  }, []);

  return (
    <Box display="flex" justifyContent="space-between">
      <Box display="flex" alignItems="center">
        <Box height="100%">
          {!file.type.includes('image') ? (
            <Box size="32px" mr="sm" display="flex">
              <Icon name="file-presentation" color={colors.neutral.gray2} />
            </Box>
          ) : (
            <S.CoverWrapper>
              <S.CoverImage src={imageUrl} alt={file.name} loading="lazy" />
            </S.CoverWrapper>
          )}
        </Box>

        <Box display="flex" flexDirection="column" justifyContent="center">
          <Text
            flex={1}
            color={variantStatus.color}
            variant="subtitle-medium-14"
            m={0}
          >
            {file.name}
          </Text>
          {uploadStatus === 'invalid' && variantStatus.statusComponent}
        </Box>
      </Box>

      <Box display="flex" alignItems="center">
        <Text
          m={0}
          mx="sm"
          color={variantStatus.color}
          variant="subtitle-medium-14"
          whiteSpace="nowrap"
        >
          {formatBytes(file.size)}
        </Text>

        {uploadStatus !== 'invalid' && variantStatus.statusComponent && (
          <Box display="flex" justifyContent="center" minWidth={space.xl}>
            {variantStatus.statusComponent}
          </Box>
        )}

        <Button
          id="delete-file-button"
          data-testid="delete-file"
          ml="sm"
          isOnlyIcon
          icon="trash-bin"
          variant="secondary"
          size="sm"
          isNegativeAction
          disabled={uploadStatus === 'deleting'}
          onClick={handleDelete}
        />
      </Box>
    </Box>
  );
};

export const FileItem = memo(
  FileItemComponent,
  (prev, next) => prev.file.status === next.file.status
);
