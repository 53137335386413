import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Icon,
  Text,
  Button,
  theme,
  DefaultThemeProps,
} from '@agendaedu/ae-web-components';

import { WalletsState } from 'components/Payments/WalletInformation/types';
import Alert from 'components/Alert';
import Address from 'components/Address';
import Loader from 'components/Loader';

import withAppContext from 'core/hoc/withAppContext';
import tabify from 'core/hoc/tabify';
import walletsActions from 'store/edupay/wallets/actions';

import * as S from './styles';

const SchoolBillingInformationsTab = ({ appContext }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['payments']);
  const defaultTheme: DefaultThemeProps = theme;

  const { colors } = defaultTheme;

  const { currentWallet, bankAccount } = useSelector(
    (state: WalletsState) => state.wallets
  );

  const { fetchBankAccountRequest } = walletsActions;

  const getAddress = () => {
    const address = {
      street: currentWallet.attributes.street,
      street_number: currentWallet.attributes.street_number,
      state: currentWallet.attributes.state,
      city: currentWallet.attributes.city,
      neighborhood: currentWallet.attributes.neighborhood,
      cep: currentWallet.attributes.cep,
      complement: currentWallet.attributes.complement,
    };

    return address;
  };

  const loadingSchoolInformation = () => {
    if (appContext?.policies?.edupay_provider === 'bemobi') {
      return !currentWallet;
    } else {
      return !currentWallet || !bankAccount;
    }
  };

  useEffect(() => {
    if (appContext?.policies?.edupay_provider !== 'bemobi')
      dispatch(fetchBankAccountRequest());
  }, [dispatch, fetchBankAccountRequest]);

  const bankAccountAgency = () => {
    let agency = bankAccount.attributes.agency;
    const dv = bankAccount.attributes.agency_dv;

    if (!!dv) agency += `-${dv}`;

    return agency;
  };

  return (
    <S.SchoolBillingInformationWrapper>
      {appContext?.policies?.edupay_provider !== 'bemobi' && (
        <Alert variation="info">
          <S.WrapperIcon>
            <Icon
              name="headphone-2"
              color={colors.context.info.default}
              size="md"
            />
          </S.WrapperIcon>
          <Text variant="subtitle-medium-14" ml="sm" mb={0} lineHeight="lg">
            {t(`form_section.wallet_configuration_message_for_bemobi_schools`)}
          </Text>
        </Alert>
      )}

      <S.AllInformationWrapper>
        <S.SchoolWrapper>
          <S.InformationsTitle variant="title-bold-20">
            {t('wallet.tab_account_details.school_informations.title')}
          </S.InformationsTitle>

          {loadingSchoolInformation() ? (
            <Loader variation="centered" dataTestId="school-loader" />
          ) : (
            <>
              <S.InformationsSubtitle variant="title-bold-16">
                {currentWallet.attributes.school_name}
              </S.InformationsSubtitle>

              <S.InformationWrapper>
                <S.WrapperIcon>
                  <Icon name="phone" color={colors.neutral.gray1} />
                </S.WrapperIcon>
                <S.InformationsText
                  variant="title-bold-16"
                  mr="xs3"
                  ml="xs"
                  mb={0}
                >
                  {t('wallet.tab_account_details.school_informations.phone')}
                </S.InformationsText>
                <Text
                  variant="subtitle-medium-16"
                  mb={0}
                  color={colors.neutral.gray1}
                  lineHeight="lg"
                >
                  {currentWallet.attributes.phone}
                </Text>
              </S.InformationWrapper>

              <S.InformationWrapper>
                <S.WrapperIcon>
                  <Icon name="user-profile" color={colors.neutral.gray1} />
                </S.WrapperIcon>
                <S.InformationsText
                  variant="title-bold-16"
                  mr="xs3"
                  ml="xs"
                  mb={0}
                >
                  {t('wallet.tab_account_details.school_informations.admin')}
                </S.InformationsText>
                <Text
                  variant="subtitle-medium-16"
                  mb={0}
                  color={colors.neutral.gray1}
                  lineHeight="lg"
                >
                  {currentWallet.attributes.legal_name}
                </Text>
              </S.InformationWrapper>

              <S.InformationWrapper>
                <S.WrapperIcon>
                  <Icon name="calendar" color={colors.neutral.gray1} />
                </S.WrapperIcon>
                <S.InformationsText
                  variant="title-bold-16"
                  mr="xs3"
                  ml="xs"
                  mb={0}
                >
                  {t(
                    'wallet.tab_account_details.school_informations.date_of_bith'
                  )}
                </S.InformationsText>
                <Text
                  variant="subtitle-medium-16"
                  mb={0}
                  color={colors.neutral.gray1}
                  lineHeight="lg"
                >
                  {currentWallet.attributes.date_of_birth}
                </Text>
              </S.InformationWrapper>

              <S.InformationWrapper>
                <S.WrapperIcon>
                  <Icon name="place" color={colors.neutral.gray1} />
                </S.WrapperIcon>
                <S.InformationsText
                  variant="title-bold-16"
                  mr="xs3"
                  ml="xs"
                  mb={0}
                >
                  {t('wallet.tab_account_details.school_informations.address')}
                </S.InformationsText>

                <Text
                  variant="subtitle-medium-16"
                  mb={0}
                  color={colors.neutral.gray1}
                  lineHeight="lg"
                >
                  <Address address={getAddress()} />
                </Text>
              </S.InformationWrapper>
            </>
          )}
        </S.SchoolWrapper>

        {appContext?.policies?.edupay_provider !== 'bemobi' && (
          <S.BankWrapper>
            <S.InformationsTitle variant="title-bold-20">
              {t('wallet.tab_account_details.bank_informations.title')}
            </S.InformationsTitle>

            <div className="bank-informations">
              {!bankAccount ? (
                <Loader
                  loading={bankAccount}
                  variation="centered"
                  dataTestId="bank-loader"
                />
              ) : (
                <>
                  <S.BankTitle>
                    {bankAccount.attributes.bank_icon && (
                      <img src={bankAccount.attributes.bank_icon} />
                    )}
                    <S.InformationsSubtitle variant="title-bold-16" mb={0}>
                      {`${bankAccount.attributes.code} - ${bankAccount.attributes.bank_name}`}
                    </S.InformationsSubtitle>
                  </S.BankTitle>

                  <S.InformationWrapper>
                    <S.WrapperIcon>
                      <Icon name="tag" color={colors.neutral.gray1} />
                    </S.WrapperIcon>
                    <S.InformationsText
                      variant="title-bold-16"
                      mr="xs3"
                      ml="xs"
                      mb={0}
                    >
                      {t('wallet.tab_account_details.bank_informations.type')}
                    </S.InformationsText>
                    <Text
                      variant="subtitle-medium-16"
                      mb={0}
                      color={colors.neutral.gray1}
                      lineHeight="lg"
                    >
                      {bankAccount.attributes.type}
                    </Text>
                  </S.InformationWrapper>

                  <S.InformationWrapper>
                    <S.WrapperIcon>
                      <Icon name="home" color={colors.neutral.gray1} />
                    </S.WrapperIcon>
                    <S.InformationsText
                      variant="title-bold-16"
                      mr="xs3"
                      ml="xs"
                      mb={0}
                    >
                      {t('wallet.tab_account_details.bank_informations.agency')}
                    </S.InformationsText>
                    <Text
                      variant="subtitle-medium-16"
                      mb={0}
                      color={colors.neutral.gray1}
                      lineHeight="lg"
                    >
                      {bankAccountAgency()}
                    </Text>
                  </S.InformationWrapper>

                  <S.InformationWrapper>
                    <S.WrapperIcon>
                      <Icon name="wallet" color={colors.neutral.gray1} />
                    </S.WrapperIcon>
                    <S.InformationsText
                      variant="title-bold-16"
                      mr="xs3"
                      ml="xs"
                      mb={0}
                    >
                      {t(
                        'wallet.tab_account_details.bank_informations.account'
                      )}
                    </S.InformationsText>
                    <Text
                      variant="subtitle-medium-16"
                      mb={0}
                      color={colors.neutral.gray1}
                      lineHeight="lg"
                    >
                      {bankAccount.attributes.account}-
                      {bankAccount.attributes.account_dv}
                    </Text>
                  </S.InformationWrapper>
                </>
              )}
            </div>
          </S.BankWrapper>
        )}
      </S.AllInformationWrapper>

      {appContext?.policies?.can_config_wallet && (
        <>
          {appContext?.policies?.edupay_provider === 'bemobi' ? (
            <a
              href="https://atendimento.agendaedu.com/hc/pt-br/requests/new"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Button variant="primary">
                {t('wallet.tab_account_details.button_wallet_config')}
              </Button>
            </a>
          ) : (
            <Link to="/schools/wallets/edit">
              <Button variant="primary">
                {t('wallet.tab_account_details.button_wallet_config')}
              </Button>
            </Link>
          )}
        </>
      )}
    </S.SchoolBillingInformationWrapper>
  );
};

export default tabify({
  title: 'CONTA BANCÁRIA',
  icon: 'bank',
})(withAppContext(SchoolBillingInformationsTab));
