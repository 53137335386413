import styled from 'styled-components';

export const Paragraph = styled.p`
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 400;
`;

export const InfoWrapper = styled.div`
  .ImagePreview {
    max-height: 617px;
  }
`;
