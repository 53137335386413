import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import { Redirect } from 'react-router-dom';

import FormContext from 'core/contexts/Form';
import withAppContext from 'core/hoc/withAppContext';
import withToastMessage from 'core/hoc/withToastMessage';
import arrayFlatten from 'core/utils/arrayFlatten';
import ClassroomsService from 'core/services/Classrooms';
import SchoolTermService from 'core/services/SchoolTerm';

import PageContainer from 'components/PageContainer';
import PageSubTitle from 'components/PageSubTitle';
import FormContainer from 'components/Form/FormContainer';
import Button from 'components/Button';
import SchoolTermsProgressionForm from 'components/SchoolTerms/ProgressionForm';
import Loader from 'components/Loader';
import Breadcrumb from 'components/Breadcrumb';
import ModalContainer from 'components/Modal/ModalContainer';
import ModalTitle from 'components/Modal/ModalTitle';

import './style.scss';

class SchoolTermsEdit extends Component {
  static propTypes = {
    isSubmitting: PropTypes.bool,
    match: PropTypes.object,
  };

  state = {
    classrooms: [],
    schoolTerm: { attributes: {} },
    isLoading: true,
    isModalOpen: true,
    isRedirectingToList: false,
  };

  async componentDidMount() {
    const {
      appContext: { dataArea },
      match: {
        params: { schoolTermId },
      },
    } = this.props;
    const classroomsService = new ClassroomsService('schools');
    const schoolTermService = new SchoolTermService(dataArea);

    try {
      const { schoolTerm } = await schoolTermService.fetchSchoolTerm(
        schoolTermId
      );
      let classrooms;

      if (schoolTerm.attributes.status) {
        classrooms =
          await classroomsService.getClassroomsFromLastSchoolTermActive();
      } else {
        classrooms = await classroomsService.getClassrooms();
      }

      const selectedClassrooms = arrayFlatten(
        classrooms.map((group) => group.options)
      )
        .filter((classroom) => classroom.attributes.progressed)
        .map((classroom) => classroom.id);

      this.setState({
        classrooms,
        selectedClassrooms,
        schoolTerm,
        isLoading: false,
      });
    } catch (error) {
      this.setState({ error });
    }
  }

  @autobind
  async onSubmit(formState) {
    const {
      appContext: { dataArea },
      match: {
        params: { schoolTermId },
      },
      toastActions: { showToast },
    } = this.props;
    const schoolTermService = new SchoolTermService(dataArea);

    this.setState({ isLoading: true });

    try {
      await schoolTermService.update({ schoolTermId, formState });
      this.setState({ isRedirectingToList: true });
    } catch (error) {
      showToast(
        'Ocorreu um erro, verifique se o nome do período letivo já está em uso. Se o erro persistir, aguarde um instante e tente novamente.'
      );
      this.setState({ error });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  @autobind
  footer({ form, hasErrorOnAttribute }) {
    const { isSubmitting } = this.state;
    return (
      <Button
        onClick={() => this.onSubmit(form)}
        disabled={
          isSubmitting || hasErrorOnAttribute('name') || !form.name.length
        }
      >
        Salvar
      </Button>
    );
  }

  @autobind
  closeModal() {
    this.setState({ isModalOpen: false });
  }

  render() {
    const {
      classrooms,
      isLoading,
      selectedClassrooms,
      isModalOpen,
      schoolTerm,
      isRedirectingToList,
    } = this.state;

    if (isRedirectingToList) {
      return (
        <Redirect
          to={{
            pathname: '/schools/school_terms',
            state: { edited: true },
          }}
        />
      );
    }

    return (
      <div className="SchoolTermEdit">
        <Loader isLoading={isLoading}>
          <FormContainer
            action="edit"
            formMeta={{ classrooms, selectedClassrooms }}
            form={{
              classroom_ids: selectedClassrooms,
              name: schoolTerm.attributes.name,
            }}
          >
            <FormContext.Consumer>
              {({ form, hasErrorOnAttribute }) => (
                <PageContainer
                  title="Editar período letivo"
                  breadcrumb={
                    <Breadcrumb
                      title="Períodos letivos"
                      path="/schools/school_terms"
                    />
                  }
                  footer={this.footer({ form, hasErrorOnAttribute })}
                  variation="centered"
                >
                  <PageSubTitle>Informações do novo período</PageSubTitle>
                  <span className="info">
                    Determine um nome e selecione quais turmas irão permanecer
                    no novo período letivo.
                  </span>
                  <SchoolTermsProgressionForm onSubmit={this.onSubmit} />
                </PageContainer>
              )}
            </FormContext.Consumer>
          </FormContainer>
          <ModalContainer
            isOpen={isModalOpen}
            toggleModal={this.closeModal}
            className="EditSchoolTermModal"
          >
            <ModalTitle>É importante lembrar!</ModalTitle>
            <p>
              Após ativação do período, todas as turmas selecionadas para o novo
              período estarão prontas para receber os alunos progredidos e novos
              alunos cadastrados.
            </p>
            <p>
              Os profissionais continuam vinculados às mesmas turmas, para
              alterar acesse o perfil do profissional.
            </p>
            <div className="actions">
              <Button onClick={this.closeModal}>Entendi</Button>
            </div>
          </ModalContainer>
        </Loader>
      </div>
    );
  }
}

export default withAppContext(withToastMessage(SchoolTermsEdit));
