import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';
import GooglePicker from 'components/GooglePicker';
import AgendaIcon from 'components/AgendaIcon';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import HelpTooltip from 'components/Tooltip/variations/help';
import withAppContext from 'core/hoc/withAppContext';
import { toastCss } from 'core/hoc/toastMessageProvider';
import DirectUpload from 'core/services/Uploader/DirectUpload';
import AcceptedContentTypes from './acceptedContentTypes';
import FilePreview from './FilePreview';

import * as S from './styles';

const FileAttachment = ({
  className,
  useGoogleDrive,
  googleClientId,
  disabled,
  onChangeFiles,
  onUploadFile,
  onDeleteFile,
  attachments,
  useDirectUpload,
  onlyListAttachment,
  customStyle,
  onlyDownloadAttachment,
  customIcon,
  isMore,
  hideTooltip,
  buttonText,
  customLabel,
  multiple,
  acceptTypes,
  maxSize,
}) => {
  const currentSchool = useSelector((state) => state.root.currentSchool);

  const MAP_ERRORS = {
    'file-too-large': `O arquivo é maior que ${currentSchool.plan.formatted_upload_limit}.`,
    'files-too-large': `Os arquivos são maiores que ${currentSchool.plan.formatted_upload_limit}.`,
    'file-invalid-type': 'O tipo do arquivo não é suportado.',
    'file-invalid-types': 'Os tipos dos arquivos não são suportados.',
  };

  const [files, setFiles] = useState(attachments);
  const [progress, setProgress] = useState([]);
  const [forceClose, setForceClose] = useState(false);
  const directUpload = new DirectUpload('schools');

  useEffect(() => {
    onChangeFiles(files);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const setSuccessUploaded = (file, signed_id, url) => {
    const newFile = Object.assign(file, {
      loading: false,
      signed_id,
      url,
    });

    setFiles((prevState) => {
      const newArr = [...prevState, newFile];

      return [...new Set(newArr)];
    });
  };

  const setErrorUpload = (error) => {
    toast(`Erro ao carregar o arquivo: ${error.message}`, toastCss('error'));
  };

  const removeFile = (file) => {
    setFiles((prevState) => prevState.filter((prevFile) => prevFile !== file));
  };

  const toastErrors = (rejections) => {
    const errors = rejections
      .map((rejected) => rejected.errors)
      .flat()
      .map((error) => error.code);

    const hasMultipleFileErrors = errors.some(
      (error, index) => errors.indexOf(error) !== index
    );

    const errorsText = new Set(
      errors.map((error) => {
        if (hasMultipleFileErrors && error === 'file-invalid-type') {
          return MAP_ERRORS['file-invalid-types'];
        }

        if (hasMultipleFileErrors && error === 'file-too-large') {
          return MAP_ERRORS['files-too-large'];
        }

        return MAP_ERRORS[error];
      })
    );

    if (rejections.length === 1) {
      toast(
        `Erro ao carregar arquivo:
        ${[...errorsText].join('\n')}`,
        toastCss('error')
      );
    } else {
      toast(
        `Erro ao carregar arquivos:
         ${[...errorsText].join('\n')}`,
        toastCss('error')
      );
    }
  };

  const handleUploadProgress = (file) => (progressEvent) => {
    if (file.type.includes('video')) {
      const percentage = parseInt(
        Math.round((progressEvent.loaded * 100) / progressEvent.total)
      );

      setProgress((prevState) => {
        return { ...prevState, [file.id]: percentage };
      });
    }
  };

  const uploadFile = async (file) => {
    const cancelSource = axios.CancelToken.source();
    const newFile = Object.assign(file, { cancelSource });

    try {
      const { signed_id, url } = await directUpload.upload(
        newFile,
        handleUploadProgress
      );
      setSuccessUploaded(file, signed_id, url);
    } catch (error) {
      if (!axios.isCancel(error)) {
        removeFile(file);
        setErrorUpload(error);
      }
    }
  };

  const uploadFromDriveFile = async (oauthToken, file) => {
    try {
      const { signed_id, url } = await directUpload.googleDriveUpload(
        oauthToken,
        file
      );

      setSuccessUploaded(file, signed_id, url);
    } catch (error) {
      const { status } = error.response;

      removeFile(file);
      if (file.isShared && status === 400) {
        setDriveErrorUpload(file);
      } else {
        setErrorUpload(error);
      }
    }
  };

  const setDriveErrorUpload = (file) => {
    const ERROR_TEXT = `Ops, ocorreu um erro e o arquivo ${truncateFileName(
      file.name
    )} não pode ser anexado. Tente novamente com outro arquivo.`;

    toast(ERROR_TEXT, { ...toastCss('error'), autoClose: 9000 });
  };

  const truncateFileName = (name) => {
    return name.length > 20 ? `"${name.substring(0, 20)}..."` : name;
  };

  const destroyFile = async (file) => {
    try {
      if (file.attachment_type === 'paperclip') {
        await directUpload.destroyAttachment(file.signed_id);
      } else {
        await directUpload.destroyFile(file.signed_id);
      }

      removeFile(file);
    } catch (error) {
      setErrorUpload(error);
    }
  };

  const cancelVideoUpload = (index) => {
    try {
      const file = files[index];
      file.cancelSource.cancel();
      removeFile(file);
    } catch (error) {
      setErrorUpload(error);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    ...disabled,
    noDrag: true,
    accept: acceptTypes || AcceptedContentTypes.join(','),
    maxSize: maxSize || currentSchool.plan.upload_limit_in_bytes,
    multiple,
    onDropAccepted: (acceptedFiles) => {
      acceptedFiles.forEach((file, index) =>
        Object.assign(file, {
          attached: false,
          loading: useDirectUpload,
          signed_id: new Date().getTime(),
          id: `${new Date().getTime()}-${index}`,
        })
      );

      setFiles((allAcceptedFiles) => allAcceptedFiles.concat(acceptedFiles));
      setForceClose(true);

      if (useDirectUpload) {
        acceptedFiles.forEach((file) => uploadFile(file));
      }

      if (onUploadFile) {
        acceptedFiles.forEach((file) =>
          onUploadFile(file, { removeFile, destroyFile, setSuccessUploaded })
        );
      }
    },
    onDropRejected: (fileRejections) => {
      toastErrors(fileRejections);
      setForceClose(true);
    },
  });

  const onDriveChange = (oauthToken, acceptedFiles) => {
    acceptedFiles.forEach((file) =>
      Object.assign(file, {
        size: file.sizeBytes,
        type: file.mimeType,
        attached: false,
        loading: useDirectUpload,
        fromGoogleDrive: true,
      })
    );

    setFiles((allFiles) => allFiles.concat(acceptedFiles));

    setForceClose(true);

    if (useDirectUpload) {
      acceptedFiles.forEach((file) => uploadFromDriveFile(oauthToken, file));
    }
  };

  const deleteFile = useCallback(
    (index) => {
      const file = files[index];
      const newFile = Object.assign(file, { loading: true, isDeleting: true });

      setFiles((prevState) => {
        const newArr = [...prevState, newFile];

        return [...new Set(newArr)];
      });

      if (useDirectUpload || onUploadFile) {
        destroyFile(file);
      } else {
        removeFile(file);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [files]
  );

  const onToggle = (isMenuOpen) => {
    if (!isMenuOpen) {
      setForceClose(false);
    }
  };

  const disableAttachment = !multiple ? files.length : false;

  const buttonTrigger = () => (
    <Button className="ButtonTrigger" outline>
      <S.LeftIcon>
        <AgendaIcon name="attachment" />
      </S.LeftIcon>

      <span className="TextContainer">{buttonText}</span>
    </Button>
  );

  const helpContent = (
    <p>
      Você pode incluir documentos, imagens
      <br /> e vídeos (Computador MP4, WebM e OGV <br /> e Drive: MP4 e WebM){' '}
      <br />
      de até {currentSchool.plan.formatted_upload_limit}.{' '}
      {isMore && (
        <a
          href="https://agendaedu.zendesk.com/hc/pt-br/articles/4412204194075-Anexos"
          target="_blank"
          rel="noopener noreferrer"
        >
          Saiba mais
        </a>
      )}
    </p>
  );

  const fileAttachmentLabel = (
    <>
      {!acceptTypes && (
        <span style={{ color: customStyle?.titleColor }}>Anexos</span>
      )}
      {!onlyListAttachment && !hideTooltip && !acceptTypes && (
        <HelpTooltip text={helpContent} size="small" />
      )}
    </>
  );

  return (
    <S.FileAttachment className={className}>
      <S.LabelContainer>
        {customLabel ? customLabel : fileAttachmentLabel}
      </S.LabelContainer>

      {!onlyListAttachment && (
        <Dropdown
          trigger={buttonTrigger()}
          forceClose={forceClose}
          onToggle={onToggle}
          disabled={disableAttachment}
        >
          <button
            {...getRootProps({ className: 'dropzone AttachmentInput' })}
            type="button"
          >
            <input data-testid="file-input" {...getInputProps()} />
            Inserir do seu computador
          </button>

          {useGoogleDrive && googleClientId && (
            <GooglePicker
              clientId={googleClientId}
              scope={['https://www.googleapis.com/auth/drive.readonly']}
              onChange={onDriveChange}
              multiselect
            >
              <button
                type="button"
                className="AttachmentInput GoogleDriveButton"
              >
                Inserir do Google Drive
              </button>
            </GooglePicker>
          )}
        </Dropdown>
      )}

      <S.FilesList
        aria-label="list of files"
        style={{ maxWidth: customStyle?.maxWidthPreview }}
      >
        {files.map((file, index) => (
          <FilePreview
            key={`${file.name} - ${index}`}
            fileIndex={index}
            file={file}
            deleteFile={
              onDeleteFile !== undefined
                ? () => onDeleteFile(files, { removeFile, destroyFile })
                : deleteFile
            }
            disabledDeleteButton={onlyListAttachment}
            cancelVideoUpload={cancelVideoUpload}
            useDirectUpload={useDirectUpload}
            progress={progress[file.id]}
            onlyDownloadAttachment={onlyDownloadAttachment}
            customIcon={customIcon}
            onUploadFile={onUploadFile !== undefined}
          />
        ))}
      </S.FilesList>
    </S.FileAttachment>
  );
};

FileAttachment.defaultProps = {
  className: '',
  useGoogleDrive: false,
  googleClientId: '',
  disabled: false,
  useDirectUpload: true,
  attachments: [],
  onChangeFiles: () => null,
  onlyListAttachment: false,
  onlyDownloadAttachment: false,
  customIcon: false,
  isMore: false,
  hideTooltip: false,
  buttonText: 'Anexar arquivos',
  multiple: true,
};

FileAttachment.propTypes = {
  className: PropTypes.string,
  useGoogleDrive: PropTypes.bool,
  googleClientId: PropTypes.string,
  disabled: PropTypes.bool,
  useDirectUpload: PropTypes.bool,
  attachments: PropTypes.arrayOf(PropTypes.object),
  onChangeFiles: PropTypes.func,
  onUploadFile: PropTypes.func,
  onDeleteFile: PropTypes.func,
  maxFileSize: PropTypes.string,
  onlyListAttachment: PropTypes.bool,
  onlyDownloadAttachment: PropTypes.bool,
  customStyle: PropTypes.shape({
    titleColor: PropTypes.string,
    maxWidthPreview: PropTypes.string,
  }),
  customIcon: PropTypes.bool,
  isMore: PropTypes.bool,
  hideTooltip: PropTypes.bool,
  buttonText: PropTypes.string,
  customLabel: PropTypes.node,
  multiple: PropTypes.bool,
  acceptTypes: PropTypes.string,
  maxSize: PropTypes.number,
};

export default withAppContext(FileAttachment);
