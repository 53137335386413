import createModuleReducer from '../../storeConfig/createModuleReducer';
import actions from './actions';

export const INITIAL_STATE = {
  filters: {
    status: 'active',
    payments_search_type: null,
    payment_title_or_student_name: null,
    headquarter_id: null,
    educational_stage_id: null,
    classroom_id: null,
    plan_kind: null,
  },
  paginate: {
    activePage: 1,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
  },
  balance: {
    billet_paid: 0,
    credit_card_paid: 0,
    manually_paid: 0,
    paid: 0,
    pix_paid: 0,
    refunded: 0,
    total: 0,
  },
  history: {
    activePage: 1,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    historyData: null,
  },
  classroomsStudents: null,
  classrooms: null,
  activeFiltersCount: 0,
  newSchoolProduct: null,
  cloneSchoolProduct: null,
  editSchoolProduct: null,
  currentTaxAmount: null,
  currentOrder: null,
  currentSchoolProduct: null,
  currentStudent: null,
  currentResponsibles: null,
  schoolProducts: [],
  orders: [],
  hasSchoolProducts: false,
  sending: false,
  error: null,
  errorMessage: null,
  isLoading: true,
  isLoadingTaxAmount: true,
  isLoadingHistory: true,
  isLoadingCancelOrder: false,
  isLoadingCheckout: false,
  isLoadingManuallyPaidOrder: false,
  isLoadingRefundOrder: false,
  isSubmitting: false,
  showManualDealModal: false,
  showModalCancelOrder: false,
  showModalCheckout: false,
  showModalCheckoutLink: false,
  showModalManuallyPaid: false,
  showModalRefundOrder: false,
  showModalRefundOrderSuccess: false,
  showModalScholProductInformation: false,
  setHasBackendValidationError: false,
  setRedirectToSchoolProductFormError: null,
  currentTab: null,
};

export const handleError = (state, action) => ({
  ...state,
  error: action.error,
  showModalScholProductInformation: false,
  isLoading: false,
  isSubmitting: false,
});

export const setCheckoutError = (state, action) => {
  const {
    error: {
      data: { errors },
    },
  } = action;

  return {
    ...state,
    errorMessage: errors,
    showModalScholProductInformation: false,
    isLoadingCheckout: false,
    isSubmitting: false,
  };
};

export const fetchSchoolProductsListRequest = (state) => ({
  ...state,
  isLoading: true,
});

export const fetchSchoolProductsListSuccess = (state, action) => {
  let amount = 0;

  const sideFilter = [
    action.filters.headquarter_id,
    action.filters.educational_stage_id,
    action.filters.classroom_id,
    action.filters.plan_kind,
  ];

  sideFilter.forEach((filter) => {
    if (filter) amount += 1;
  });

  return {
    ...state,
    filters: {
      ...action.filters,
      status: action?.filters?.status || null,
    },
    activeFiltersCount: amount,
    paginate: action.paginate,
    schoolProducts: action.schoolProducts,
    hasSchoolProducts: action.hasSchoolProducts,
    isLoading: false,
  };
};

export const fetchSchoolProductRequest = (state) => ({
  ...state,
  isLoading: true,
});

export const fetchSchoolProductSuccess = (state, action) => ({
  ...state,
  currentSchoolProduct: action.schoolProduct,
  isLoading: false,
});

export const fetchOrdersRequest = (state) => ({
  ...state,
  isLoading: true,
});

export const fetchOrdersSuccess = (state, action) => ({
  ...state,
  orders: action.orders,
  filters: action.filters,
  paginate: action.paginate,
  visualizations: action.visualizations,
  isLoading: false,
});

export const fetchBalanceRequest = (state) => ({
  ...state,
  isLoading: true,
});

export const fetchBalanceSuccess = (state, action) => ({
  ...state,
  balance: action.balance,
  isLoading: false,
});

export const fetchHistoryRequest = (state) => ({
  ...state,
  history: {
    activePage: 1,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    historyData: null,
  },
  isLoadingHistory: true,
});

export const fetchHistorySuccess = (state, action) => ({
  ...state,
  history: action.history,
  isLoadingHistory: false,
});

export const fetchClassroomsSuccess = (state, action) => ({
  ...state,
  classrooms: action.classrooms,
});

export const fetchClassroomsStudentsSuccess = (state, action) => ({
  ...state,
  classroomsStudents: action.classroomsStudents,
});

export const fetchNewSchoolProductFormMeta = (state) => ({
  ...state,
  newSchoolProduct: null,
  isSubmitting: false,
  isLoading: true,
  currentTaxAmount: null,
});

export const fetchNewSchoolProductFormMetaSuccess = (state, action) => ({
  ...state,
  newSchoolProduct: action,
  isLoading: false,
});

export const fetchTaxAmountRequest = (state) => ({
  ...state,
  isLoadingTaxAmount: true,
});

export const fetchTaxAmountSuccess = (state, action) => ({
  ...state,
  currentTaxAmount: action.taxAmount,
  isLoadingTaxAmount: false,
});

export const setNewSchoolProduct = (state) => ({
  ...state,
  isSubmitting: true,
});

export const setNewSchoolProductSuccess = (state) => ({
  ...state,
  isSubmitting: false,
});

export const setCancelOrderRequest = (state) => ({
  ...state,
  isLoadingCancelOrder: true,
});

export const setCancelOrderSuccess = (state, action) => {
  const orders = state.orders;

  const ordersUpdatedWithCanceledOrder = orders.map((order) => {
    if (order.id === action.orderCanceled.id) {
      order.attributes = action.orderCanceled.attributes;
    }
    return order;
  });

  return {
    ...state,
    orders: ordersUpdatedWithCanceledOrder,
    currentOrder: null,
    currentStudent: null,
    currentResponsibles: null,
    showModalCancelOrder: false,
    isLoadingCancelOrder: false,
  };
};

export const setCancelOrderSuccessLD = (state, action) => {
  const orders = state.orders;

  const ordersUpdatedWithCanceledOrder = orders.map((order) => {
    if (order.id === action.orderCanceled.id) {
      order.attributes = action.orderCanceled.attributes;
    }
    return order;
  });

  return {
    ...state,
    orders: ordersUpdatedWithCanceledOrder,
    currentOrder: null,
    currentStudent: null,
    currentResponsibles: null,
    showManualDealModal: false,
    isLoadingCancelOrder: false,
  };
};

export const setCancelSchoolProductRequest = (state) => {
  return {
    ...state,
    showModalScholProductInformation: false,
    currentSchoolProduct: null,
    isLoading: true,
  };
};

export const setCancelSchoolProductSuccess = (state, action) => {
  return {
    ...state,
    currentSchoolProduct: action.schoolProduct,
    isLoading: false,
  };
};

export const setCloseRefundOrderSuccessModal = (state) => ({
  ...state,
  currentOrder: null,
  currentStudent: null,
  currentResponsibles: null,
  showModalRefundOrderSuccess: false,
});

export const setCheckoutRequest = (state) => ({
  ...state,
  isLoadingCheckout: true,
});

export const setCheckoutSuccess = (state, action) => {
  const orders = state.orders;

  const { id, attributes } = action.data;

  const ordersUpdated = orders.map((order) => {
    if (order.id === id) {
      order.attributes = attributes;
    }
    return order;
  });

  return {
    ...state,
    orders: ordersUpdated,
    showModalCheckout: false,
    isLoadingCheckout: false,
    showModalCheckoutLink: true,
  };
};

export const setInformationToPaid = (state, action) => {
  let currentOrder = state.currentOrder;
  let currentStudent = state.currentStudent;
  let currentResponsibles = state.currentResponsibles;

  currentOrder = action.orders.find(
    (order) => order.attributes.id === action.orderId
  );

  if (currentOrder) {
    currentStudent = currentOrder.attributes.student_profile.data;
    currentResponsibles = currentOrder.attributes.responsible_profiles.data;
  }

  return {
    ...state,
    currentOrder,
    currentStudent,
    currentResponsibles,
  };
};

export const setManuallyPaidOrderRequest = (state) => ({
  ...state,
  isLoadingManuallyPaidOrder: true,
});

export const setManuallyPaidOrderSuccess = (state, action) => {
  const orders = state.orders;

  const ordersUpdatedWithManuallyPaidOrder = orders.map((order) => {
    if (order.id === action.orderPaid.id) {
      order.attributes = action.orderPaid.attributes;
    }
    return order;
  });

  return {
    ...state,
    orders: ordersUpdatedWithManuallyPaidOrder,
    currentOrder: null,
    currentStudent: null,
    currentResponsibles: null,
    showModalManuallyPaid: false,
    isLoadingManuallyPaidOrder: false,
  };
};

export const setPaidAtSchoolOrderSuccess = (state, action) => {
  const orders = state.orders;

  const ordersUpdatedWithManuallyPaidOrder = orders.map((order) => {
    if (order.id === action.orderPaid.id) {
      order.attributes = action.orderPaid.attributes;
    }
    return order;
  });

  return {
    ...state,
    orders: ordersUpdatedWithManuallyPaidOrder,
    currentOrder: null,
    currentStudent: null,
    currentResponsibles: null,
    showManualDealModal: false,
    isLoadingManuallyPaidOrder: false,
  };
};

export const setRefundOrderRequest = (state) => ({
  ...state,
  isLoadingRefundOrder: true,
});

export const fetchCloneSchoolProductRequest = (state) => ({
  ...state,
  isLoading: true,
});

export const fetchCloneSchoolProductSuccess = (state, action) => {
  return {
    ...state,
    cloneSchoolProduct: action.schoolProduct,
    isLoading: false,
    isSubmitting: false,
  };
};

export const fetchEditSchoolProductRequest = (state) => ({
  ...state,
  isLoading: true,
});

export const fetchEditSchoolProductSuccess = (state, action) => ({
  ...state,
  editSchoolProduct: action.schoolProduct,
  isLoading: false,
  isSubmitting: false,
});

export const setRefundOrderSuccess = (state, action) => {
  const orders = state.orders;

  const ordersUpdatedWithRefundOrder = orders.map((order) => {
    if (order.id === action.orderRefunded.id) {
      order.attributes = action.orderRefunded.attributes;
    }
    return order;
  });

  const showModalRefundOrderSuccess =
    !action.orderRefunded.attributes.manually_paid;

  return {
    ...state,
    orders: ordersUpdatedWithRefundOrder,
    isLoadingRefundOrder: false,
    showModalRefundOrder: false,
    showModalRefundOrderSuccess,
  };
};

export const setApproveRequest = (state) => ({
  ...state,
  currentSchoolProduct: null,
  isLoading: true,
});

export const setCloneSchoolProductRequest = (state) => ({
  ...state,
  isSubmitting: true,
});

export const setEditSchoolProductRequest = (state) => ({
  ...state,
  isSubmitting: true,
});

export const setEditSchoolProductSuccess = (state) => ({
  ...state,
});

export const setCloneSchoolProductSuccess = (state) => ({
  ...state,
  cloneSchoolProduct: null,
});

export const setDeleteSchoolProductRequest = (state) => ({
  ...state,
  showModalScholProductInformation: false,
  isLoading: true,
});

export const setDeleteSchoolProductSuccess = (state) => ({
  ...state,
  currentSchoolProduct: null,
  isLoading: false,
});

export const setApproveSuccess = (state, action) => ({
  ...state,
  currentSchoolProduct: action.schoolProduct,
  isLoading: false,
  showModalScholProductInformation: false,
});

export const setRejectSchoolProductSuccess = (state) => ({
  ...state,
  setHasBackendValidationError: false,
  setRedirectToSchoolProductFormError: null,
});

export const setRejectSchoolProductError = (state, action) => ({
  ...state,
  setHasBackendValidationError: action.hasBackendValidationError,
  setRedirectToSchoolProductFormError: action.error,
});

export const toggleCancelOrderModal = (state) => ({
  ...state,
  showModalCancelOrder: !state.showModalCancelOrder,
});

export const toggleCancelOrderModalLD = (state) => ({
  ...state,
  showManualDealModal: !state.showManualDealModal,
});

export const toggleCheckoutModal = (state) => ({
  ...state,
  errorMessage: null,
  showModalCheckout: !state.showModalCheckout,
});

export const toggleCheckoutLinkModal = (state) => ({
  ...state,
  showModalCheckoutLink: !state.showModalCheckoutLink,
});

export const toggleManuallyPaidModal = (state) => ({
  ...state,
  showModalManuallyPaid: !state.showModalManuallyPaid,
});

export const toggleManualDealModal = (state) => ({
  ...state,
  showManualDealModal: !state.showManualDealModal,
});

export const toggleRefundOrderModal = (state) => ({
  ...state,
  showModalRefundOrder: !state.showModalRefundOrder,
});

export const toggleSchoolProductInformationModal = (state) => ({
  ...state,
  showModalScholProductInformation: !state.showModalScholProductInformation,
});

export const toggleCurrentTab = (state, action) => ({
  ...state,
  currentTab: action.currentTab,
});

const HANDLERS = {
  [actions.ERROR]: handleError,
  [actions.FETCH_SCHOOLPRODUCTS_LIST_REQUEST]: fetchSchoolProductsListRequest,
  [actions.FETCH_SCHOOLPRODUCTS_LIST_SUCCESS]: fetchSchoolProductsListSuccess,
  [actions.FETCH_SCHOOLPRODUCT_REQUEST]: fetchSchoolProductRequest,
  [actions.FETCH_SCHOOLPRODUCT_SUCCESS]: fetchSchoolProductSuccess,
  [actions.FETCH_ORDERS_REQUEST]: fetchOrdersRequest,
  [actions.FETCH_ORDERS_SUCCESS]: fetchOrdersSuccess,
  [actions.FETCH_BALANCE_REQUEST]: fetchBalanceRequest,
  [actions.FETCH_BALANCE_SUCCESS]: fetchBalanceSuccess,
  [actions.FETCH_HISTORY_REQUEST]: fetchHistoryRequest,
  [actions.FETCH_HISTORY_SUCCESS]: fetchHistorySuccess,
  [actions.FETCH_CLASSROOMS_SUCCESS]: fetchClassroomsSuccess,
  [actions.FETCH_CLASSROOMS_STUDENTS_SUCCESS]: fetchClassroomsStudentsSuccess,
  [actions.FETCH_NEW_SCHOOL_PRODUCT_FORM_META_REQUEST]:
    fetchNewSchoolProductFormMeta,
  [actions.FETCH_NEW_SCHOOL_PRODUCT_FORM_META_SUCCESS]:
    fetchNewSchoolProductFormMetaSuccess,
  [actions.FETCH_CLONE_SCHOOL_PRODUCT_SUCCESS]: fetchCloneSchoolProductSuccess,
  [actions.FETCH_CLONE_SCHOOL_PRODUCT_REQUEST]: fetchCloneSchoolProductRequest,
  [actions.FETCH_EDIT_SCHOOL_PRODUCT_REQUEST]: fetchEditSchoolProductRequest,
  [actions.FETCH_EDIT_SCHOOL_PRODUCT_SUCCESS]: fetchEditSchoolProductSuccess,
  [actions.FETCH_CURRENT_TAX_AMOUNT_REQUEST]: fetchTaxAmountRequest,
  [actions.FETCH_CURRENT_TAX_AMOUNT_SUCCESS]: fetchTaxAmountSuccess,
  [actions.SET_NEW_SCHOOL_PRODUCT_REQUEST]: setNewSchoolProduct,
  [actions.SET_NEW_SCHOOL_PRODUCT_SUCCESS]: setNewSchoolProductSuccess,
  [actions.SET_INFORMATION_TO_PAID]: setInformationToPaid,
  [actions.SET_CANCEL_ORDER_REQUEST]: setCancelOrderRequest,
  [actions.SET_CANCEL_ORDER_SUCCESS]: setCancelOrderSuccess,
  [actions.SET_CANCEL_ORDER_REQUEST_LD]: setCancelOrderRequest,
  [actions.SET_CANCEL_ORDER_SUCCESS_LD]: setCancelOrderSuccessLD,
  [actions.SET_ClONE_SCHOOL_PRODUCT_SUCCESS]: setCloneSchoolProductSuccess,
  [actions.SET_ClONE_SCHOOL_PRODUCT_REQUEST]: setCloneSchoolProductRequest,
  [actions.SET_CLOSE_REFUND_ORDER_SUCCESS_MODAL]:
    setCloseRefundOrderSuccessModal,
  [actions.SET_CHECKOUT_REQUEST]: setCheckoutRequest,
  [actions.SET_CHECKOUT_SUCCESS]: setCheckoutSuccess,
  [actions.SET_CHECKOUT_ERROR]: setCheckoutError,
  [actions.SET_MANUALLY_PAID_ORDER_REQUEST]: setManuallyPaidOrderRequest,
  [actions.SET_MANUALLY_PAID_ORDER_SUCCESS]: setManuallyPaidOrderSuccess,
  [actions.SET_PAID_AT_SCHOOL_ORDER_SUCCESS]: setPaidAtSchoolOrderSuccess,
  [actions.SET_REFUND_ORDER_REQUEST]: setRefundOrderRequest,
  [actions.SET_REFUND_ORDER_SUCCESS]: setRefundOrderSuccess,
  [actions.SET_APPROVE_SCHOOL_PRODUCT_REQUEST]: setApproveRequest,
  [actions.SET_APPROVE_SCHOOL_PRODUCT_SUCCESS]: setApproveSuccess,
  [actions.SET_DELETE_SCHOOL_PRODUCT_REQUEST]: setDeleteSchoolProductRequest,
  [actions.SET_DELETE_SCHOOL_PRODUCT_SUCCESS]: setDeleteSchoolProductSuccess,
  [actions.SET_EDIT_SCHOOL_PRODUCT_REQUEST]: setEditSchoolProductRequest,
  [actions.SET_EDIT_SCHOOL_PRODUCT_SUCCESS]: setEditSchoolProductSuccess,
  [actions.SET_CANCEL_SCHOOL_PRODUCT_REQUEST]: setCancelSchoolProductRequest,
  [actions.SET_CANCEL_SCHOOL_PRODUCT_SUCCESS]: setCancelSchoolProductSuccess,
  [actions.SET_REJECT_SCHOOL_PRODUCT_SUCCESS]: setRejectSchoolProductSuccess,
  [actions.SET_REJECT_SCHOOL_PRODUCT_ERROR]: setRejectSchoolProductError,
  [actions.TOGGLE_CANCEL_ORDER_MODAL]: toggleCancelOrderModal,
  [actions.TOGGLE_CHECKOUT_MODAL]: toggleCheckoutModal,
  [actions.TOGGLE_CHECKOUT_LINK_MODAL]: toggleCheckoutLinkModal,
  [actions.TOGGLE_MANUALLY_PAID_MODAL]: toggleManuallyPaidModal,
  [actions.TOGGLE_MANUAL_DEAL_MODAL]: toggleManualDealModal,
  [actions.TOGGLE_REFUND_ORDER_MODAL]: toggleRefundOrderModal,
  [actions.TOGGLE_CURRENT_TAB]: toggleCurrentTab,
  [actions.TOGGLE_SCHOOL_PRODUCT_INFORMATION_MODAL]:
    toggleSchoolProductInformationModal,
};

const schoolProducts = createModuleReducer(INITIAL_STATE, HANDLERS);

export default schoolProducts;
