import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AgendaIcon from 'components/AgendaIcon';

import messagesActions from 'store/messages/actions';

import { MemberProps, MessageState } from 'store/messages/types';
import { GroupCardProps } from './types';

import * as S from './styles';

const GroupCard = ({ group }: GroupCardProps) => {
  const dispatch = useDispatch();

  const { currentGroup: current } = useSelector(
    (state: MessageState) => state.messages
  );

  const { setCurrentGroupRequest } = messagesActions;

  const {
    id,
    attributes: {
      name,
      last_message: lastMessage,
      enable_sound: enableSound,
      unread_message_count: unreadMessageCount,
    },
    relationships: {
      members: { data },
    },
  } = group;

  const hasUnreadMessage = unreadMessageCount > 0;

  const renderGroupMembers = () =>
    `Grupo criado com
  ${data.map((member: MemberProps) => member.attributes?.name).join(', ')}
    `;

  const handleSetCurrentGroup = useCallback(() => {
    if (current?.id === id) return;

    dispatch(setCurrentGroupRequest(group));
  }, [current, dispatch, group, id, setCurrentGroupRequest]);

  return (
    <S.GroupCardWrapper
      onClick={handleSetCurrentGroup}
      isSelected={current?.id === id}
      data-testid="group-card-wrapper"
    >
      <S.GroupCardHeader>
        <S.GroupCardWrapperTitle>
          <S.GroupCardTitle title={name}>{name}</S.GroupCardTitle>

          {!enableSound && <AgendaIcon name="megaphone-mute" size="small" />}
        </S.GroupCardWrapperTitle>

        {lastMessage?.created_at && (
          <S.GroupCardTimestamp>{lastMessage.created_at}</S.GroupCardTimestamp>
        )}
      </S.GroupCardHeader>

      <S.NotificationWrapper hasUnreadMessage={hasUnreadMessage}>
        <S.LastMessagePreview>
          {lastMessage?.content ?? renderGroupMembers()}
        </S.LastMessagePreview>

        {hasUnreadMessage && (
          <S.CountWrapper data-testid="count-wrapper">
            {unreadMessageCount}
          </S.CountWrapper>
        )}
      </S.NotificationWrapper>
    </S.GroupCardWrapper>
  );
};

export default memo(GroupCard);
