import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';

import withAppContext from 'core/hoc/withAppContext';
import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';
import colorWithAlpha from 'core/utils/colorWithAlpha';

import AgendaIcon from 'components/AgendaIcon';
import AllStudentsRemovalModal from 'components/Form/StudentsSelector/AllStudentsRemovalModal';
import OutlineBox from 'components/OutlineBox';
import FormValidationErrors from 'components/Form/ValidationErrors';

import ClassroomsService from 'core/services/Classrooms';
import ClassroomAndStudentSelector from './ClassroomAndStudentSelector';
import FormStudentsSelectorList from './List';

import './style.scss';

class FormStudentsSelector extends React.Component {
  state = {
    isConfirmAllStudentsRemovalModalOpen: false,
    isSelectedSearching: false,
    isHoveringAllSelect: false,
    isHoveringAllRemove: false,
  };

  headquartersAndClassroomFormKey = 'classroom_id';

  /**
   * Load students directly into selected students if there are any students
   * set. This is used for the edit action.
   */
  componentDidMount() {
    const { formContext, attributeName } = this.props;
    const { formMeta, updateFormContext } = formContext;
    const { student_profiles } = formMeta;

    if (!student_profiles || formMeta[`${attributeName}_SelectedStudents`])
      return;

    updateFormContext(({ prevFormState, buildFormState, buildFormMeta }) =>
      this.addStudentsToSelectedStudents(student_profiles, {
        prevFormState,
        buildFormState,
        buildFormMeta,
      })
    );
  }

  isDisabled() {
    return this.props.disabled;
  }

  @autobind
  onClassroomChange(classroomId, changeAttribute, updateAttribute) {
    this.fetchClassroomStudents(classroomId);
    this.clearStudents();
    changeAttribute(event);
    updateAttribute('selectedClassroom', classroomId);
  }

  /**
   * Filters current classroom students by name.
   * @param {string} studentName - The name to filter
   */
  @autobind
  filterClassroomStudents(studentName) {
    if (typeof studentName === 'string' && studentName.length !== 0) {
      const lowerCaseStudentName = studentName.toLocaleLowerCase();
      const classroomStudents = this.getClassroomStudentsFromFormMeta();
      const filteredClassroomStudents = classroomStudents.filter((student) =>
        student.attributes.name.toLowerCase().includes(lowerCaseStudentName)
      );

      this.setState({ filteredClassroomStudents });
    } else {
      this.setState({ filteredClassroomStudents: null });
    }
  }

  @autobind
  onStudentNameChange(_changeAttribute) {
    const studentName = _changeAttribute;
    this.filterClassroomStudents(studentName);
  }

  filterClassroomStudentsSelected = (studentName) => {
    if (typeof studentName === 'string' && studentName.length !== 0) {
      const isSelectedSearching = !!studentName.length;
      const selectedStudents = this.getSelectedStudentsFromFormMeta();
      const lowerCaseStudentName = studentName.toLocaleLowerCase();
      const filteredClassroomStudentsSelected = selectedStudents.filter(
        (student) =>
          student.attributes.name.toLowerCase().includes(lowerCaseStudentName)
      );

      this.setState({ isSelectedSearching, filteredClassroomStudentsSelected });
    } else {
      this.setState({
        isSelectedSearching: false,
        filteredClassroomStudentsSelected: null,
      });
    }
  };

  onStudentNameChangeSelected = (_changeAttribute) => {
    const studentName = _changeAttribute;
    this.filterClassroomStudentsSelected(studentName);
  };

  /**
   * Reset filter on classroom change
   */
  @autobind
  clearStudents() {
    if (this.isDisabled()) return;

    this.setState((prevState) => ({
      ...prevState,
      filteredStudents: null,
    }));
  }

  getHeadQuartersOptionsFromFormMeta() {
    const { selectPlaceholder, formContext } = this.props;
    const { getOptionsForSelect } = formContext;

    return getOptionsForSelect(this.headquartersAndClassroomFormKey) || [];
  }

  async fetchClassroomStudents(classroomId) {
    const {
      appContext: { dataArea },
      formContext: { changeMeta },
      studentsWithResponsibles,
    } = this.props;

    const classroomsService = new ClassroomsService(dataArea);

    this.setState({ isFetchingClassroomStudents: true });

    try {
      const students = await classroomsService.getStudents(
        classroomId,
        studentsWithResponsibles
      );

      changeMeta(this.getClassroomStudentsFormMetaKey(), students);
    } catch (error) {
      this.setState({ errorFetchingClassroomStudents: true });
    } finally {
      this.setState({ isFetchingClassroomStudents: false });
    }
  }

  /**
   * @param {object} formMeta the object formMeta that comes from FormContext.
   * @returns {object[]} the students array from the form context.
   */
  getClassroomStudentsFromFormMeta(formMeta = this.props.formContext.formMeta) {
    return formMeta[this.getClassroomStudentsFormMetaKey()] || [];
  }

  getClassroomStudentsFormMetaKey() {
    const { attributeName } = this.props;
    return `${attributeName}_ClassroomStudents`;
  }

  /**
   * @param {object} formMeta the object formmeta that comes from FormContext.
   * @returns {object[]} the students array from the form context.
   */
  getSelectedStudentsFromFormMeta(formMeta = this.props.formContext.formMeta) {
    const selectedStudents =
      formMeta[this.getSelectedStudentsFormMetaKey()] || [];
    return selectedStudents;
  }

  getSelectedStudentsFormMetaKey() {
    const { attributeName } = this.props;
    return `${attributeName}_SelectedStudents`;
  }

  /**
   * @param {object[]} arrayOfObjectsWithId
   * @returns {number[]} the ids extracted from the input array.
   */
  extractIds(arrayOfObjectsWithId) {
    return arrayOfObjectsWithId.map(({ id }) => id);
  }

  /**
   * @returns {Function} the function to change the form's state.
   */
  changeAttribute(event) {
    if (this.isDisabled()) return;
    const { attributeName, formContext } = this.props;
    const { changeAttribute } = formContext;
    return changeAttribute(attributeName)(event);
  }

  @autobind
  onStudentClick(student) {
    return (_event) => {
      if (this.isStudentSelected(student)) {
        this.removeStudentFromSelectedStudents(student);
      } else {
        this.addStudentToSelectedStudents(student);
      }
    };
  }

  @autobind
  onAddAllStudentsFromClassroomToSelectedStudents(_event) {
    this.addAllStudentsFromClassroomToSelectedStudents();
  }

  @autobind
  onRemoveAllSelectedStudents(_event) {
    if (this.isDisabled()) return;
    this.setState({ isConfirmAllStudentsRemovalModalOpen: true });
  }

  /**
   * Save the selected students' id in the form state and updates the form meta
   * with the new student.
   * @param {object} student the student object to be selected.
   */
  addStudentToSelectedStudents(student) {
    const {
      formContext: { updateFormContext },
    } = this.props;

    if (this.isDisabled()) return;

    updateFormContext(({ prevFormState, buildFormState, buildFormMeta }) =>
      this.addStudentsToSelectedStudents([student], {
        prevFormState,
        buildFormState,
        buildFormMeta,
      })
    );
  }

  removeStudentFromSelectedStudents(student) {
    const { attributeName, formContext } = this.props;
    const { updateFormContext } = formContext;

    if (this.isDisabled()) return;

    updateFormContext(({ prevFormState, buildFormState, buildFormMeta }) => {
      const { formMeta: prevFormMeta } = prevFormState;
      const students = this.getSelectedStudentsFromFormMeta(prevFormMeta);
      const studentsWithoutStudent = students.filter(
        ({ id }) => id !== student.id
      );

      const classroom_with_student_profile_ids =
        prevFormState.form.classroom_with_student_profile_ids;
      Object.keys(classroom_with_student_profile_ids).forEach((key) => {
        classroom_with_student_profile_ids[key] =
          classroom_with_student_profile_ids[key].filter(
            (student_id) => student_id !== student.id
          );
      });

      const formState = buildFormState(prevFormState, {
        [attributeName]: this.extractIds(studentsWithoutStudent),
        classroom_with_student_profile_ids,
      });

      const formMeta = buildFormMeta(
        prevFormState,
        this.getSelectedStudentsFormMetaKey(),
        studentsWithoutStudent
      );

      return { ...formState, ...formMeta };
    });
  }

  @autobind
  addAllStudentsFromClassroomToSelectedStudents() {
    const {
      formContext: { updateFormContext },
    } = this.props;
    const { filteredClassroomStudents } = this.state;
    const allStudentsFromClassoom =
      filteredClassroomStudents || this.getClassroomStudentsFromFormMeta();

    if (this.isDisabled()) return;
    if (allStudentsFromClassoom.length == 0) return;

    updateFormContext(({ prevFormState, buildFormState, buildFormMeta }) => {
      const allStudentsFromClassroomNotYetAdded =
        allStudentsFromClassoom.filter((s) => !this.isStudentSelected(s));

      return this.addStudentsToSelectedStudents(
        allStudentsFromClassroomNotYetAdded,
        {
          prevFormState,
          buildFormState,
          buildFormMeta,
        }
      );
    });
  }

  /**
   * @params {Array} students
   * @returns the new state to be set in the form.
   */
  addStudentsToSelectedStudents(
    students,
    { prevFormState, buildFormState, buildFormMeta }
  ) {
    const {
      formMeta: prevFormMeta,
      form: { selectedClassroom, classroom_with_student_profile_ids },
    } = prevFormState;

    const selectedStudents = this.getSelectedStudentsFromFormMeta(prevFormMeta);

    const studentsWithNewSelectedStudents = [...selectedStudents, ...students];

    let studentsWithNewSelectedStudentsIds = this.extractIds(students);

    if (
      classroom_with_student_profile_ids &&
      classroom_with_student_profile_ids[selectedClassroom]
    ) {
      studentsWithNewSelectedStudentsIds = classroom_with_student_profile_ids[
        selectedClassroom
      ].concat(studentsWithNewSelectedStudentsIds);
    }

    const classrooms = {
      classroom_with_student_profile_ids: {
        ...classroom_with_student_profile_ids,
      },
    };

    if (selectedClassroom) {
      classrooms.classroom_with_student_profile_ids[selectedClassroom] =
        studentsWithNewSelectedStudentsIds;
    }

    const formState = buildFormState(prevFormState, classrooms);

    const formMeta = buildFormMeta(
      prevFormState,
      this.getSelectedStudentsFormMetaKey(),
      studentsWithNewSelectedStudents
    );

    return { ...formState, ...formMeta };
  }

  @autobind
  removeAllSelectedStudents() {
    const { attributeName, formContext } = this.props;
    const { updateFormContext } = formContext;

    if (!this.isDisabled()) {
      updateFormContext(({ prevFormState, buildFormState, buildFormMeta }) => {
        const emptyStudents = [];

        const classroom_with_student_profile_ids =
          prevFormState.form.classroom_with_student_profile_ids;

        Object.keys(classroom_with_student_profile_ids).forEach((key) => {
          classroom_with_student_profile_ids[key] = [];
        });

        const formState = buildFormState(prevFormState, {
          classroom_with_student_profile_ids,
        });

        const formMeta = buildFormMeta(
          prevFormState,
          this.getSelectedStudentsFormMetaKey(),
          emptyStudents
        );

        return { ...formState, ...formMeta };
      });
    }

    this.setState({ isConfirmAllStudentsRemovalModalOpen: false });
  }

  @autobind
  isStudentSelected(student) {
    const {
      formContext: { form },
    } = this.props;
    return (
      form.classroom_with_student_profile_ids &&
      Object.keys(form.classroom_with_student_profile_ids).some(
        (classroom_id) =>
          form.classroom_with_student_profile_ids[classroom_id].includes(
            student.id
          )
      )
    );
  }

  confirmRemovalButtons() {
    return [
      {
        text: 'Cancelar',
        variation: 'secondary',
        onClick: this.toggleConfirmAllStudentsRemovalModalOpen,
        path: '#',
      },
      {
        text: 'Remover todos',
        onClick: this.removeAllSelectedStudents,
        path: '#',
      },
    ];
  }

  @autobind
  toggleConfirmAllStudentsRemovalModalOpen() {
    this.setState((prevState) => {
      const { isConfirmAllStudentsRemovalModalOpen } = prevState;

      return {
        isConfirmAllStudentsRemovalModalOpen:
          !isConfirmAllStudentsRemovalModalOpen,
      };
    });
  }

  toggleHover = (classButton) => {
    classButton === 'AllSelect'
      ? this.setState((prevState) => ({
          isHoveringAllSelect: !prevState.isHoveringAllSelect,
        }))
      : this.setState((prevState) => ({
          isHoveringAllRemove: !prevState.isHoveringAllRemove,
        }));
  };

  render() {
    const {
      attributeName,
      formContext,
      disabled,
      appContext: { primaryColor },
    } = this.props;

    const { form, hasErrorOnAttribute } = formContext;

    const { isHoveringAllSelect, isHoveringAllRemove } = this.state;
    const styleButtonAllSelect = {
      backgroundColor: isHoveringAllSelect
        ? colorWithAlpha(primaryColor, 0.1)
        : '#FFF',
      color: isHoveringAllSelect ? primaryColor : '#666',
      borderColor: isHoveringAllSelect
        ? colorWithAlpha(primaryColor, 0.1)
        : '#999',
    };

    const styleButtonAllRemove = {
      backgroundColor: isHoveringAllRemove
        ? colorWithAlpha(primaryColor, 0.1)
        : '#FFF',
      color: isHoveringAllRemove ? primaryColor : '#666',
      borderColor: isHoveringAllRemove
        ? colorWithAlpha(primaryColor, 0.1)
        : '#999',
    };

    const {
      filteredClassroomStudents,
      filteredClassroomStudentsSelected,
      isSelectedSearching,
      isConfirmAllStudentsRemovalModalOpen,
      isFetchingClassroomStudents,
    } = this.state;

    const headQuartersOptions = this.getHeadQuartersOptionsFromFormMeta();
    const classroomStudents =
      filteredClassroomStudents || this.getClassroomStudentsFromFormMeta();
    const selectedStudents = this.getSelectedStudentsFromFormMeta();

    const hasError = hasErrorOnAttribute(attributeName);

    return (
      <div data-testid="students-selector" className="FormStudentsSelector">
        {hasError && <FormValidationErrors attributeName={attributeName} />}
        <ClassroomAndStudentSelector
          formContext={formContext}
          classroom_id={form.classroom_id}
          headQuartersOptions={headQuartersOptions}
          classroomIdKey={this.headquartersAndClassroomFormKey}
          studentsMetaKey={this.getClassroomStudentsFormMetaKey()}
          onClassroomChange={this.onClassroomChange}
          disabled={disabled}
        />
        <div className="list-wrapper">
          <div className="heading">
            <strong className="students-count">
              {`${classroomStudents.length}`} aluno(s)
            </strong>
            <OutlineBox
              onClick={this.onAddAllStudentsFromClassroomToSelectedStudents}
              style={styleButtonAllSelect}
              onMouseEnter={() => this.toggleHover('AllSelect')}
              onMouseLeave={() => this.toggleHover('AllSelect')}
            >
              Selecionar todos
            </OutlineBox>
          </div>
          <FormStudentsSelectorList
            students={classroomStudents}
            isStudentSelected={this.isStudentSelected}
            onStudentClick={this.onStudentClick}
            emptyIconName="user-group"
            emptyMessage="Selecione uma turma para visualizar os alunos"
            disabled={this.isDisabled()}
            onStudentNameChange={this.onStudentNameChange}
            isLoading={isFetchingClassroomStudents}
            attributeName={'searchTerm'}
          />
        </div>
        <div className="icon">
          <AgendaIcon name="swap-horizontal" />
        </div>
        <div className="list-wrapper">
          <div className="heading">
            <strong className="students-count">
              {`${selectedStudents.length}`} selecionado(s)
            </strong>
            <OutlineBox
              onClick={this.onRemoveAllSelectedStudents}
              style={styleButtonAllRemove}
              onMouseEnter={() => this.toggleHover('AllRemove')}
              onMouseLeave={() => this.toggleHover('AllRemove')}
            >
              Remover todos
            </OutlineBox>
          </div>
          <FormStudentsSelectorList
            students={
              isSelectedSearching
                ? filteredClassroomStudentsSelected
                : selectedStudents
            }
            isStudentSelected={this.isStudentSelected}
            onStudentClick={this.onStudentClick}
            ignoreSelectedVisualAid
            showEmptyIcon={false}
            disabled={this.isDisabled()}
            onStudentNameChange={this.onStudentNameChangeSelected}
            attributeName={'searchTermSelected'}
          />
        </div>
        <AllStudentsRemovalModal
          isOpen={isConfirmAllStudentsRemovalModalOpen}
          toggleModal={this.toggleConfirmAllStudentsRemovalModalOpen}
          handleSubmit={this.confirmRemovalButtons()}
          title="Deseja remover todos os alunos selecionados?"
          description="Ao remover todos os alunos, você perderá as seleções realizadas."
        />
      </div>
    );
  }
}

FormStudentsSelector.defaultProps = {
  studentsWithResponsibles: true,
  selectPlaceholder: '',
};

FormStudentsSelector.propTypes = {
  attributeName: PropTypes.string.isRequired,
  selectPlaceholder: PropTypes.string,
  studentsWithResponsibles: PropTypes.bool,
  ...formPropTypes,
};

export default withAppContext(withFormContext(FormStudentsSelector));
